<template>
  <div class="w-full h-full px-[1rem] py-[1rem] bg-white font-NotoSansKR" id="BIYONG_view">
    <div v-if="type == 'info'" class="border-b-[1px] flex flex-row justify-between items-center w-full">
        <div class="flex flex-row items-center h-[2.6rem] mt-[1rem] gap-[1rem] mb-[1.6rem]">
            <p class="text-[1.3rem] text-[#92959B] h-full leading-[2.6rem]">조회연도</p>
            <select class="w-[11.6rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                <option v-for="y in yearList" :key="y">{{ y }}</option>
            </select>
            <p class="text-[1.3rem] text-[#92959B] h-full leading-[2.6rem] ml-[2rem]">부서</p>
            <select class="w-[17.6rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="selBonbu">
                <option v-for="bonbu in bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
            </select>
        </div>
        <div @click="init" class="select-none cursor-pointer w-auto h-full mr-[3rem] border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </div>
    </div>
    <table v-if="type == 'info'" class="table mt-[1.6rem] border-[1px] w-full h-auto">
        <tr class="table-row border-[1px] h-[2rem] text-[#62686A]">
            <td class="table-cell border-[1px] p-[1.6rem]" colspan="2">구분</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ index+1 }}월</td>
        </tr>
        <tr class="table-row border-[1px]">
            <td class="table-cell border-[1px] p-[1rem] w-[6.6rem] bg-[#E1F2F1]" rowspan="5">직접비</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem]">
            <td class="table-cell border-[1px] p-[1.6rem] w-[13.6rem]">인건비</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ getInfoValue(index+1,'INGEONBI_AM') }}</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem]">
            <td class="table-cell border-[1px] p-[1.6rem] w-[13.6rem]">외주비</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{getInfoValue(index+1,'OEJUBI_AM')}}</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem]">
            <td class="table-cell border-[1px] p-[1.6rem] w-[13.6rem]">경비</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ getInfoValue(index+1,'GYEONGBI_AM') }}</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem] bg-[#E1F2F1]">
            <td class="table-cell border-[1px] p-[1.6rem] w-[13.6rem]">합계</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ getInfoJikjeop(index+1) }}</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem] bg-[#F9FBEC]">
            <td class="table-cell border-[1px] p-[1.6rem]" colspan="2">부서공통비</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ getInfoValue(index+1,'BUSEO_GONGTONGBI_AM') }}</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem] bg-[#EFFAEB]">
            <td class="table-cell border-[1px] p-[1.6rem]" colspan="2">전사간접비</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ getInfoValue(index+1,'JEONSA_GANJEOPBI_AM') }}</td>
        </tr>
        <tr class="table-row border-[1px] h-[2rem]">
            <td class="table-cell border-[1px] p-[1.6rem]" colspan="2">합계</td>
            <td v-for="(item, index) in 12" :key="index" class="table-cell border-[1px] p-[1.6rem] text-end">{{ getInfoTotal(index+1) }}</td>
        </tr>
    </table>
    <div v-if="type != 'info'" class="flex flex-row justify-between items-center w-full mb-[1rem] min-w-[1700px]">
        <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">{{ type == 'info' ? '비용현황' : '비용관리' }}</p>
        <div v-if="type != 'info' && selBonbu != 'PCM사업부'" class="flex flex-row justify-end">
            <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">
                저장
            </div>
        </div>
    </div>
    <div v-if="type != 'info'" class="flex flex-row pb-5">
        <select class="select-none w-96 h-[3.7rem] border-[1px] text-left pl-[1.2rem] rounded-lg border-[#C7C7C7" v-model="year">
            <option v-for="y in yearList" :key="y">{{ y }}</option>
        </select>
        <select class="select-none w-96 h-[3.7rem] border-[1px] text-left pl-[1.2rem] rounded-lg border-[#C7C7C7 mx-5" v-model="selBonbu">
            <option v-for="bonbu in bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
        </select>
        <select v-if="type != 'info' && selBonbu != 'PCM사업부'" v-model="month" class="select-none w-96 border-[1px] h-[3.7rem] text-left pl-[1.2rem] rounded-lg border-[#C7C7C7]">
            <option :value="0">---- 결제 마감일 선택 ----</option>
            <option v-for="i in 12" :key="i" :value="i">{{ i }}월</option>
        </select>
    </div>
    <div v-if="type != 'info'" class="flex flex-row h-[calc(100%-10rem)]">
        <div class="flex flex-col"> <!-- 240325 수정-->
            <div class="flex flex-row justify-between pb-2 text-lg">
                <div class="w-auto border-red-500 flex flex-row items-center">
                    <div class="flex flex-row items-center">
                        <div class="w-[1.2rem] h-[1.2rem] bg-[#FFF3C7] mr-[.5rem]  border-[1px] border-[#787878]"></div>
                            <p class="text-[1.1rem]">비용현황</p>
                    </div>
                    <div class="flex flex-row items-center ml-[.8rem]">
                        <div class="w-[1.2rem] h-[1.2rem] border-[1px] border-[#787878] mr-[.5rem]"></div>
                        <p class="text-[1.1rem]">비용예상</p>
                    </div>
                    <p v-if="!isValid" class="ml-[2rem] text-red-500">마감월이 다른 본부가 존재합니다.</p>
                </div>
                <div class="flex flex-row gap-[1rem]">
                    <div v-if="type!='info'" @click="onClickExcelDown(selBonbu)" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg px-[2rem] py-[0.4rem]">양식 다운로드</div>
                    <input ref="excelInput" class="hidden" type="file" @input="onInputExcelLoad" accept=".xlsx, .xlsm, .xltx, .xltm, .xls, .xlt">
                    <div v-if="type!='info'" @click="onClickExcelLoad" class="ml-[0rem] cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg px-[2rem] py-[0.4rem]">양식 불러오기</div>
                    <!-- <div class="ml-[1rem] cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg px-[2rem] py-[0.4rem]">비용현황</div>
                    <div class="ml-[1rem] cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg px-[2rem] py-[0.4rem]">비용예상</div> -->
                    <!-- <div @click="historySidebar.open()" class="ml-[0rem] cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg px-[2rem] py-[0.4rem]">변경 조회</div> -->
                    <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
                </div>
            </div>
            <DxDataGrid ref="dataGrid" class="h-[calc(100%-4rem)] border-2 min-w-[1700px] overflow-hidden w-full" data-row-template="dataRowTemplate" :data-source="dataSource" key-expr="BIYONG_SQ" :show-borders="true" :focused-row-enabled="false">
                <DxPaging :enabled="false"/>
                <DxSelection mode="single"/>
                <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                <DxHeaderFilter :visible="true"/>
                <!-- <DxScrolling mode="'virtual"/> -->
                <DxLoadPanel :enabled="true"/>
                <DxEditing mode="cell" :allow-updating="true" :allow-adding="false" :allow-deleting="false"></DxEditing>
                <template #dataRowTemplate="{ data: rowInfo }">
                    <tr :class="`select-none border-1 text-center`" :style="{background: `#${( rowInfo.data.BIYONG_MONTH_CN<=month)?'FFF3C7' : 'fff'}`}">
                        <td>
                            {{ getMonthName(rowInfo.data.BIYONG_MONTH_CN) }}
                        </td>
                        <td>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.INGEONBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.INGEONBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN<=month">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.INGEONBI_AM" />
                                </template>
                                <DxNumberBox step="0" @value-changed="onChangeValue($event,rowInfo.data,'INGEONBI_AM')" format="###,##0" v-if="rowInfo.data.BIYONG_MONTH_CN>month" :value="rowInfo.data.INGEONBI_AM" class="bg-white text-[#111111]"/>
                            </template>
                        </td>
                        <td>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.OEJUBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.OEJUBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN<=month">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.OEJUBI_AM"/>
                                </template>
                                <DxNumberBox step="0" :read-only="type=='info'" @value-changed="onChangeValue($event,rowInfo.data,'OEJUBI_AM')" format="###,##0" v-if="rowInfo.data.BIYONG_MONTH_CN>month" :value="rowInfo.data.OEJUBI_AM" class="bg-white text-[#111111]"/>
                            </template>
                        </td>
                        <td>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.GYEONGBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.GYEONGBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN<=month">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.GYEONGBI_AM "/>
                                </template>
                                <DxNumberBox step="0" :read-only="type=='info'" @value-changed="onChangeValue($event,rowInfo.data,'GYEONGBI_AM')" format="###,##0" v-if="rowInfo.data.BIYONG_MONTH_CN>month" :value="rowInfo.data.GYEONGBI_AM" class="bg-white text-[#111111]"/>
                            </template>
                        </td>
                        <td class="bg-[#E7F2FF] text-[#111111]">
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.INGEONBI_AM+crr.OEJUBI_AM+crr.GYEONGBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.INGEONBI_AM+crr.OEJUBI_AM+crr.GYEONGBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.INGEONBI_AM + rowInfo.data.OEJUBI_AM + rowInfo.data.GYEONGBI_AM"/>
                            </template>
                        </td>
                        <td>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.BUSEO_GONGTONGBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.BUSEO_GONGTONGBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN<=month">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.BUSEO_GONGTONGBI_AM"/>
                                </template>
                                <DxNumberBox step="0" :read-only="type=='info'" @value-changed="onChangeValue($event,rowInfo.data,'BUSEO_GONGTONGBI_AM')" format="###,##0" v-if="rowInfo.data.BIYONG_MONTH_CN>month" :value="rowInfo.data.BUSEO_GONGTONGBI_AM" class="bg-white text-[#111111]"/>
                            </template>
                        </td>
                        <td>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="bg-white text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.JEONSA_GANJEOPBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="bg-white text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.JEONSA_GANJEOPBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN<=month">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.JEONSA_GANJEOPBI_AM"/>
                                </template>
                                <DxNumberBox step="0" :read-only="type=='info'" @value-changed="onChangeValue($event,rowInfo.data,'JEONSA_GANJEOPBI_AM')" format="###,##0" v-if="rowInfo.data.BIYONG_MONTH_CN>month" :value="rowInfo.data.JEONSA_GANJEOPBI_AM" class="bg-white text-[#111111]"/>
                            </template>
                        </td>
                        <td class="bg-[#E7F2FF]">
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN > 12">
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 13">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.filter(x=>(x.BIYONG_MONTH_CN<month+1)).reduce((arr,crr)=>{ return arr+crr.INGEONBI_AM+crr.OEJUBI_AM+crr.GYEONGBI_AM+crr.BUSEO_GONGTONGBI_AM+crr.JEONSA_GANJEOPBI_AM },0)"/>
                                </template>
                                <template v-if="rowInfo.data.BIYONG_MONTH_CN === 14">
                                    <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="this.dataSource.reduce((arr,crr)=>{ return arr+crr.INGEONBI_AM+crr.OEJUBI_AM+crr.GYEONGBI_AM+crr.BUSEO_GONGTONGBI_AM+crr.JEONSA_GANJEOPBI_AM },0)"/>
                                </template>
                            </template>
                            <template v-if="rowInfo.data.BIYONG_MONTH_CN <= 12">
                                <DxNumberBox step="0" format="###,##0" read-only="true" class="text-[#111111]"
                                        :value="rowInfo.data.INGEONBI_AM + rowInfo.data.OEJUBI_AM + rowInfo.data.GYEONGBI_AM + rowInfo.data.BUSEO_GONGTONGBI_AM + rowInfo.data.JEONSA_GANJEOPBI_AM"/>
                            </template>
                        </td>
                    </tr>
                </template>
                <DxColumn caption="월" :allow-filtering="false" width="auto" data-field="BIYONG_MONTH_CN"/>
                <DxColumn :caption="`${selBonbu} - ${year}년`" width="auto" alignment="center">
                    <DxColumn format="###,##0" caption="인건비" width="25%" data-field="INGEONBI_AM" alignment="center"/>
                    <DxColumn format="###,##0" caption="외주비" width="25%" data-field="OEJUBI_AM" alignment="center"/>
                    <DxColumn format="###,##0" caption="경비" width="25%" data-field="GYEONGBI_AM" alignment="center"/>
                    <DxColumn format="###,##0" caption="직접비 소계" width="25%" alignment="center"/>
                    <DxColumn format="###,##0" caption="부서 공통비" width="25%" data-field="BUSEO_GONGTONGBI_AM" alignment="center"/>
                    <DxColumn format="###,##0" caption="전사 간접비" width="25%" data-field="JEONSA_GANJEOPBI_AM" alignment="center"/>
                    <DxColumn format="###,##0" caption="합계" width="25%" alignment="center"/>
                </DxColumn>
                </DxDataGrid>
        </div>
    </div>
    <HistorySidebar ref="historySidebar" menuName="C0030"/>
  </div>
</template>
 
<script>
import {
DxDataGrid,
DxColumn,
DxPaging,
DxEditing,
DxSearchPanel,
DxHeaderFilter,
//DxScrolling,
DxSelection,
DxLoadPanel
} from 'devextreme-vue/data-grid';
import { DxNumberBox } from "devextreme-vue/number-box";
import RestHelper from '../Helpers/RestHelper';
import DataHelper from '../Helpers/DataHelper';
import LogHelper from '../Helpers/LogHelper';
import BIYONGModel from '../Models/BIYONGModel'
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import sf from 'sf';
import HistorySidebar from '../Controls/HistorySidebar.vue';

export default {
    components :{
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxSearchPanel,
        DxHeaderFilter,
        //DxScrolling,
        DxSelection,
        DxLoadPanel,
        DxNumberBox,HistorySidebar
    },
    props:{
        type : String,
    },
    computed:{
        dataGrid()
        {
            return this.$refs.dataGrid.instance;
        },
        bonbuColors()
        {
            return ['F7E39A','FECE79','F8A555','F4806F','F38093'
                   ,'F390BC','E4B7D4','8A8BC3','94CAE4','9FD9DA'
                   ,'97D1A9','CAE189'];
        },
        historySidebar()
        {
            return this.$refs.historySidebar;
        }
    },
    watch:{
        year()
        {
            const tempBonbu = this.selBonbu;
            this.getBonbu();
            if(this.selBonbu === tempBonbu)
            {
                this.getCostList();
            }
        },
        month()
        {
            const target = this.dataSource.find(x=>x.BIYONG_MONTH_CN.toString() == this.month.toString());
            const targetList = this.dataSource.filter(x=>Number(x.BIYONG_MONTH_CN)>Number(this.month));
            if(target === undefined) return;
            for(const o of targetList)
            {
                o.BUSEO_GONGTONGBI_AM = target.BUSEO_GONGTONGBI_AM;
                o.GYEONGBI_AM = target.GYEONGBI_AM;
                o.INGEONBI_AM = target.INGEONBI_AM;
                o.JEONSA_GANJEOPBI_AM = target.JEONSA_GANJEOPBI_AM;
                o.OEJUBI_AM = target.OEJUBI_AM;
            }
        },
        selBonbu()
        {
            this.getCostList();
        },
    },
    data(){
        return{
            yearList : [],
            year : null,
            bonbuList : [],
            selBonbu : '',
            dataSource : [],
            month : 0,
            isValid : true,
        }
    },
    async mounted(){
        await this.getYears();
    },
    methods:{
        async init()
        {
            const tempYear = this.year;
            this.year = null;
            this.$nextTick(()=>{
                this.year = tempYear;
            })
        },
        getToday(y = 0 ,m = 0 ,d = 0)
        {
            const date = new Date();
            const year = date.getFullYear() + y;
            const month = ("0" + (1 + date.getMonth() + m)).slice(-2);
            const day = ("0" + (date.getDate() + d)).slice(-2);
 
            return year + "-" + month + "-" + day;
        },
        getMonthName(i)
        {
            if(i == 13)
            {
                return '소계'
            }
            else if(i == 14)
            {
                return '합계'
            }
            return i+'월'
        },
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_SUGEUM','INIT',[{}]);
            LogHelper.logWrite('비용관리 연도 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {  
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear();
                })
            }
        },
        async getBonbu()
        {
            const res = await RestHelper.RestSend("USP_BUSEO","LIST",[{
                STARTYEAR : '0',
                ENDYEAR : this.getToday(),
                USE_YN : 'Y'
            }]);
            LogHelper.logWrite('비용관리 부서 요청',[res],this.logUse)
           
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data;
                for(const o of data)
                {
                    o.BUSEO_COLOR_NM = o.BUSEO_COLOR_NM ?? this.bonbuColors[0];
                }
                for(const o of data)
                {
                    o.BUSEO_SIJAK_DT = DataHelper.SetDtData(o.BUSEO_SIJAK_DT);
                    o.BUSEO_MAGAM_DT = DataHelper.SetDtData(o.BUSEO_MAGAM_DT);
                }
                this.bonbuList = [];
                this.bonbuList.push({ BUSEOMYEONG_NM : 'PCM사업부'})
                this.bonbuList = this.bonbuList.concat(data.filter(x=>x.DEPT_LEVEL == 4).filter(x=>x.BUSEOMYEONG_NM != '품질안전실'));
                if(DataHelper.StringNullorEmpty(this.selBonbu))
                {
                    this.selBonbu = '';
                    this.$nextTick(()=>{
                        this.selBonbu = this.bonbuList[0].BUSEOMYEONG_NM;
                    })
                }
            }
        },
        async getCostList()
        {
            if(this.selBonbu == '') return;
            const BUSEO_SQ = this.bonbuList.find(x=>x.BUSEOMYEONG_NM == this.selBonbu).BUSEO_SQ;
            const res = await RestHelper.RestSend("USP_BIYONG","LIST",[{
                BIYONG_YEAR_CN : this.year,
                SANGTAE_NM : '0',
                BONBU_SQ : BUSEO_SQ
            }]);
            LogHelper.logWrite('비용관리 리스트 요청',[res],this.logUse);
            this.month = 0;
            this.dataSource = [];
            for(let i = 1; i<15; i++)
            {
                this.dataSource.push(new BIYONGModel(i,BUSEO_SQ,this.year));
            }
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data;
                for(const o of this.dataSource)
                {
                    for(const t of data)
                    {
                        if(o.BIYONG_MONTH_CN == t.BIYONG_MONTH_CN)
                        {
                            for(const key of Object.keys(t))
                            {
                                o[key] = t[key];
                            }
                        }
                    }
                }
                for(const d of data)
                {
                    if(!isNaN(Number(d.SANGTAE_NM)) && Number(d.SANGTAE_NM) > 0)
                    {
                        this.month = Number(d.BIYONG_MONTH_CN);
                    }
                }
                const isAllSame = (arr) => arr.every(num => num === arr[0]);
                this.isValid = isAllSame(data.filter(x=>!isNaN(Number(x.SANGTAE_NM))).filter(x=>Number(x.SANGTAE_NM)>0).map(x=>Number(x.SANGTAE_NM)));
            }
        },
        async onClickSave()
        {
            for(const o of this.dataSource)
            {
                if(Number(o.BIYONG_MONTH_CN) <= this.month)
                    o.SANGTAE_NM = 1;
                else
                    o.SANGTAE_NM = 0;
 
                // o.BIYONG_MONTH_CN = o.BIYONG_MONTH_CN.toString();
                // o.BIYONG_YEAR_CN = o.BIYONG_YEAR_CN.toString();
            }
            const targets = this.dataSource.slice(0,12);
            const res = await RestHelper.RestSend('USP_BIYONG','INSERT',targets);
            LogHelper.logWrite('비용관리 저장 요청',[res,targets],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                alert('저장')
            }
        },
        onChangeValue(e,data,propName)
        {
            data[propName] = e.value;
        },
        onClickExcelLoad() {
            this.$refs.excelInput.value = ""
            this.$refs.excelInput.click();
        },
        async onInputExcelLoad(e) {
            const file = e.target.files[0];
            const reader = new FileReader();

            const setMonthValue = (data, month, value) => {
                let s1 = data.BIYONG_MONTH_CN[`BIYONG_MONTH${month}_CN`];
                if (isNaN(s1) || s1 == 0 || s1 == null) {
                    data[`BIYONG_MONTH${month}_CN`] = value;
                }
            };

            reader.onload = async (readerEvent) => {
                const fileContent = readerEvent.target.result;
                const workbook = new ExcelJS.Workbook();
                // 파일 데이터를 직접 사용하여 엑셀 워크북으로 읽기
                await workbook.xlsx.load(fileContent);
                const worksheet = workbook.getWorksheet(1);
                let s1 = 0;
                let s2 = 0;
                let s3 = 0;
                let s4 = 0;
                let s5 = 0;
                let newValueIngeonbi;
                let newValueOeju;
                let newValueGyeongbi;
                let newValueBuseoGongtong;
                let newValueJeonsa;

                for (let i = 3; i < worksheet.rowCount + 1; i++) {
                    const data = this.dataSource[i - 3];
                    if (data) {
                        if (i != 15) {
                            newValueIngeonbi = worksheet.getCell(`B${i}`).value;
                            newValueOeju = worksheet.getCell(`C${i}`).value;
                            newValueGyeongbi = worksheet.getCell(`D${i}`).value;
                            newValueBuseoGongtong = worksheet.getCell(`F${i}`).value;
                            newValueJeonsa = worksheet.getCell(`G${i}`).value;
                        } else {
                            newValueIngeonbi = s1;
                            newValueOeju = s2;
                            newValueGyeongbi = s3;
                            newValueBuseoGongtong = s4;
                            newValueJeonsa = s5;
                        }

                        // 수정된 값이 object인 경우 result 값을 사용
                        if (newValueIngeonbi !== null) {
                            data.INGEONBI_AM = newValueIngeonbi.result !== undefined ? newValueIngeonbi.result : newValueIngeonbi;
                        }
                        if (newValueOeju !== null) {
                            data.OEJUBI_AM = newValueOeju.result !== undefined ? newValueOeju.result : newValueOeju;
                        }
                        if (newValueGyeongbi !== null) {
                            data.GYEONGBI_AM = newValueGyeongbi.result !== undefined ? newValueGyeongbi.result : newValueGyeongbi;
                        }
                        if (newValueBuseoGongtong !== null) {
                            data.BUSEO_GONGTONGBI_AM = newValueBuseoGongtong.result !== undefined ? newValueBuseoGongtong.result : newValueBuseoGongtong;
                        }
                        if (newValueJeonsa !== null) {
                            data.JEONSA_GANJEOPBI_AM = newValueJeonsa.result !== undefined ? newValueJeonsa.result : newValueJeonsa;
                        }

                        if (i < 15) {
                            s1 += data.INGEONBI_AM;
                            s2 += data.OEJUBI_AM;
                            s3 += data.GYEONGBI_AM;
                            s4 += data.BUSEO_GONGTONGBI_AM;
                            s5 += data.JEONSA_GANJEOPBI_AM;
                        }
                    }
                }

                // 데이터 그리드 갱신
                this.$nextTick(() => {
                    this.dataGrid.refresh();
                });
            };
            reader.readAsArrayBuffer(file);
        },

        async onClickExcelDown(selBonbu) {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet(`${selBonbu}`);

            const numColumns = 8;
            const header = ['부서명', '인건비', '외주비', '경비', '직접비 소계', '부서 공통비', '전사 간접비', '합계'];
            const headerWidths = [5, 11, 9, 11, 11, 11, 11, 12.5];

            const buseomyeongRow = sheet.addRow([selBonbu, '', '', '', '', '', '', '']);
            buseomyeongRow.height = 16.5;
            buseomyeongRow.eachCell((cell) => {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffebebeb" },
                };
                cell.border = {
                    bottom: { style: "dashed", color: { argb: "ff000000" } },
                    right: { style: "dashed", color: { argb: "ff000000" } }
                };
                cell.font = {
                    name: "Arial",
                    size: 12,
                    bold: true,
                    color: { argb: "ff252525" }
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });

            const headerRow = sheet.addRow(header);
            headerRow.height = 16.5;

            headerRow.eachCell((cell, colNumber) => {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffebebeb" },
                };
                cell.border = {
                    bottom: { style: "dashed", color: { argb: "ff000000" } },
                    right: { style: "dashed", color: { argb: "ff000000" } }
                };
                cell.font = {
                    name: "Arial",
                    size: 12,
                    bold: true,
                    color: { argb: "ff252525" }
                };
                sheet.getColumn(colNumber).alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
                sheet.getColumn(colNumber).width = headerWidths[colNumber - 1];
            });
            sheet.mergeCells('A1:A2');
            sheet.getCell('A1').value = '부서명';
            sheet.getCell('A1').alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            sheet.mergeCells('B1:H1');
            sheet.getCell('B1').value = selBonbu; 
            sheet.getCell('B1').alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };

            let rIdx = 3;

            for (const data of this.dataSource) {
                let rowdata;

                if (data.BIYONG_MONTH_CN == 15) {
                    rowdata = [
                        this.getMonthName(data.BIYONG_MONTH_CN),
                        { formula: 'SUM(B3:B14)' },
                        { formula: 'SUM(C3:C14)' },
                        { formula: 'SUM(D3:D14)' },
                        { formula: 'SUM(E3:E14)' },
                        { formula: 'SUM(F3:F14)' },
                        { formula: 'SUM(G3:G14)' },
                        { formula: 'SUM(H3:H14)' }
                    ];
                } else {
                    rowdata = [
                        this.getMonthName(data.BIYONG_MONTH_CN),
                        data.INGEONBI_AM,
                        data.OEJUBI_AM,
                        data.GYEONGBI_AM,
                        { formula: `SUM(B${rIdx}:D${rIdx})` },
                        data.BUSEO_GONGTONGBI_AM,
                        data.JEONSA_GANJEOPBI_AM,
                        { formula: `SUM(E${rIdx}:G${rIdx})` }
                    ];
                }

                const appendRow = sheet.addRow(rowdata);
                appendRow.eachCell((cell, colNum) => {
                    cell.border = {
                        bottom: { style: "dashed", color: { argb: "ff000000" } },
                        right: { style: "dashed", color: { argb: "ff000000" } },
                    };

                    if (colNum === 5) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "fff2cc" } 
                        };
                        appendRow.height =16.5;
                    }

                    if (colNum === 8) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ffe699" } 
                        };
                        appendRow.height =16.5;
                    }

                    // 월, 직접비 소계, 합계 행 readonly 처리
                    if (colNum == 1 || colNum == 5 || colNum == 8) {
                        cell.protection = {
                            locked: true,
                            hidden: false,
                        };
                    } else {
                        cell.protection = {
                            locked: false,
                            hidden: false,
                        };
                    }
                });
                rIdx++;
            }
            sheet.spliceRows(sheet.rowCount, 1) // 합계 row 삭제~,,
            sheet.autoFilter = 'A2:H2';
            await sheet.protect('the-password', { selectLockedCells: true });

            const fileData = await workbook.xlsx.writeBuffer();
            const blob = new Blob([fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = objectUrl;
            link.download = `${selBonbu}.xlsx`;
            link.click();
        },
        getInfoValue(month,key)
        {
            if(this.dataSource.length < 1) return 0;
            if(this.dataSource[0][key] == undefined) return 0;
            try
            {
                const value = this.dataSource.find(x=>x.BIYONG_MONTH_CN == month)[key];
                return sf("{0:###,###,###,###,###,##0}",Number(value));
            }
            catch(e)
            {
                return 0;
            }
        },
        getInfoJikjeop(month)
        {
            if(this.dataSource.length < 1) return 0;
            try
            {
                const target = this.dataSource.find(x=>x.BIYONG_MONTH_CN == month);
                const value = Number(target.INGEONBI_AM) + Number(target.OEJUBI_AM) + Number(target.GYEONGBI_AM);
                return sf("{0:###,###,###,###,###,##0}",Number(value));
            }
            catch(e)
            {
                return 0;
            }
        },
        getInfoTotal(month)
        {
            if(this.dataSource.length < 1) return 0;
            try
            {
                const target = this.dataSource.find(x=>x.BIYONG_MONTH_CN == month);
                const value = Number(target.INGEONBI_AM) + Number(target.OEJUBI_AM) + Number(target.GYEONGBI_AM) + Number(target.BUSEO_GONGTONGBI_AM) + Number(target.JEONSA_GANJEOPBI_AM);
                return sf("{0:###,###,###,###,###,##0}",Number(value));
            }
            catch(e)
            {
                return 0;
            }
        },
    } 
}
</script>

<style>
#BIYONG_view {
    width: 100%;
    height: 100%;
    overflow: auto;
}
#BIYONG_view .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    border: 1px solid #e0e0e0;
    vertical-align: middle !important;
    font-size: 1.25rem;
    font-weight: 500;
    height: 36px;
}
#BIYONG_view .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
#BIYONG_view .dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
    margin:0
}
#BIYONG_view .dx-scrollable-content tr td,
#BIYONG_view .dx-datagrid-rowsview tr td {
    text-align: left;
    padding: 0 5px !important;
    vertical-align: middle;
    color:#111111;
}
#BIYONG_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    width: 100%;
    margin:0 75px;
    max-width: 100%;
    color: #818181;
    font-size: 1.3rem;
    height: 36px;
}

#BIYONG_view .dx-scrollable-content tr td,
#BIYONG_view .dx-datagrid-rowsview tr td {
    text-align: right;
    vertical-align: middle;
    color:#111111;
}
#BIYONG_view .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
    padding: 0;
    text-align: center;
}
#BIYONG_view .dx-widget input {
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    padding:9.25px!important;
}
#BIYONG_view .dx-texteditor.dx-editor-filled.dx-state-readonly,
#BIYONG_view .dx-texteditor.dx-editor-filled{
    background: 0;
}
#BIYONG_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td:first-child {
    vertical-align: middle;
}
#BIYONG_view .dx-texteditor.dx-editor-filled::after {
    border:0;
}
#BIYONG_view .dx-freespace-row {
    display:none
}
</style>