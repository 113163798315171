<template>
    <div class="w-full h-auto overflow-auto pb-[2rem]">
        <div class="flex flex-row gap-[16px] w-full min-w-[1700px]">
        <div class="flex flex-col border-[1px] p-[2.4rem] rounded-[1.4rem] shadow-lg font-NotoSansKR w-full h-[45.6rem] ">
            <div class="w-full flex flex-row justify-between items-center">
                    <div class="flex flex-row justify-start items-center">
                        <div :class="`bg-[#E2E2E2] border-[1px] w-[2rem] h-[1rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem]`"></div>
                        <span class="mr-[1rem]">경영계획</span>
                        <div :class="`bg-[#5BA9E1] border-[1px] w-[2rem] h-[1rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem]`"></div>
                        <span>실적</span>
                    </div>
                    <span>(단위 : 억원)</span>
                </div>
            <table class="table w-full h-full border-2 border-white text-center bg-[#F2F9FD]">
                <tr class="table-row text-[13px] border-2 border-white h-[3.2rem] py-[8px] bg-[#E1EFF9]">
                    <td class="table-cell border-2 border-white">구분</td>
                    <td v-for="(item, index) in 12" :key="index" class="table-cell border-2 border-white w-[7.7%]">{{index+1}}월</td>
                </tr>
                <tr class="table-row text-[13px] border-2 border-white h-[3.2rem] py-[8px]">
                    <td class="table-cell border-2 border-white">연간달성율</td>
                    <td v-for="(item, index) in 12" :key="index" class="table-cell border-2 border-white">{{getRate(index)}}%</td>
                </tr>
                <tr class="table-row border-2 border-white">
                    <td class="table-cell border-2 border-white">연간<br>(누적)</td>
                    <td class="table-cell border-2 bg-white border-white" colspan="12">
                        <Line height="164px" :options="lineChartOptionsObj" :data="lineChartDataObj" :plugins="[ChartJSPluginDatalabels]"/>
                    </td>
                </tr>
                <tr class="table-row border-2 border-white">
                    <td class="table-cell border-2 border-white">월간</td>
                    <td class="table-cell border-2 bg-white border-white" colspan="12">
                        <Bar class="pr-[0rem] z-50" height="164px" :options="barChartOptionsObj" :data="barChartDataObj" :plugins="[ChartJSPluginDatalabels]"/>
                    </td>
                </tr>
            </table>
        </div>
        <div class="border-[1px] p-[2.4rem] rounded-[1.4rem] shadow-lg font-NotoSansKR w-full h-[45.6rem] max-w-[512px]">
            <div class="flex flex-row w-full justify-end">
                <span>(단위 : 억원)</span>
            </div>
            <table class="table w-full h-full border-2 text-center bg-[#F2F9FD]">
                <tr class="table-row text-[13px] border-2 h-[3.2rem] py-[8px] bg-[#E1EFF9]">
                    <td class="table-cell border-2 w-[10%]">구분</td>
                    <td class="table-cell border-2 w-[30%]">완료</td>
                    <td class="table-cell border-2 w-[30%]">예정</td>
                    <td class="table-cell border-2 w-[30%]">실적예상</td>
                </tr>
                <tr v-for="(item, index) in tableData" :key="index" class="table-row text-[13px] border-2 py-[8px]">
                    <td class="table-cell border-2" id="buseo_text">{{item.BUSEOMYEONG_NM}}</td>
                    <td class="table-cell border-2 bg-white">{{setUnit(item.H_S_AM)}}</td>
                    <td class="table-cell border-2 bg-[#FAFAFA]">{{setUnit(item.SUM - item.H_S_AM)}}</td>
                    <td class="table-cell border-2 bg-white">{{setUnit(item.SUM)}}</td>
                </tr>
            </table>
        </div>
        </div>
    </div>
</template>

<script>
import { Bar,Line, getDatasetAtEvent } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement,LineElement } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import { defineComponent } from 'vue';
import RestHelper from '../../Helpers/RestHelper'
import sf from 'sf'
import moment from 'moment';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default defineComponent({
    components:{
        Bar,Line,
    },
    async mounted()
    {
        await this.getData();
    },
    data() {
        return {
            tableData : [],
            barChartDataObj: {
                labels: ['', '', '', '', '', '', '', '', '', '', '', ''],
                datasets:
                [ 
                    { 
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] ,
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    },
                    { 
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] ,
                        backgroundColor: '#5BA9E1',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    } 
                ],
            },
            barChartOptionsObj: {
                responsive: true,
                maintainAspectRatio : false,
                borderRadius: 8,
                layout: {
                    autoPadding : false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                        max: 80,
                        ticks: {
                            callback: (value) => this.setUnit(value),
                        }
                    },
                    x:{
                        display: false,
                    }
                },
                plugins : {
                    legend : {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: (value) => this.setUnit(value),
                        // formatter: function(value, context) {
                        //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        // }
                    },
                }
            },
            lineChartDataObj: {
                labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                datasets:
                [ 
                    { 
                        data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,] ,
                        backgroundColor: '#AAAAAA',
                    },
                ],
            },
            lineChartOptionsObj: {
                responsive: true,
                maintainAspectRatio : false,
                borderRadius: 8,
                layout: {
                    autoPadding : false,
                    padding: {
                        top: 10,
                        left: 40,
                        right: 40,
                        bottom: 10,
                    }
                },
                scales: {
                    y: {
                        display: false,
                        max: 160,
                        ticks: {
                            callback: (value) => this.setUnit(value),
                        }
                    },
                    x:{
                        display: false,
                    }
                },
                plugins : {
                    legend : {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: (value) => this.setUnit(value),
                        // formatter: function(value, context) {
                        //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        // }
                    },
                }
            }
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.bar.chart;
        },
        chartData()
        {
            return this.chartDataObj;
        },
        chartOptions()
        {
            return this.chartOptionsObj;
        },
    },
    props: {
        year: Number,
    },
    watch: {
        async year()
        {
            await this.getData();
        }
    },
    methods: {
        setUnit(value)
        {
            const valueInBillions = Number(value) / 100000000; // 억 단위로 변환
            return sf("{0:#,###,##0.##}",Number(valueInBillions));
        },
        getRate(i)
        {
            try
            {
                const mData = this.lineChartDataObj?.datasets[0]?.data[i] ?? 0; 
                const tData = this.lineChartDataObj?.datasets[1]?.data[i] ?? 0;
                const value = (tData/mData*100).toFixed(2);
                return value == Infinity ? 0 : value;
            }
            catch(e)
            {
                return 0;
            }
        },
        async getData()
        {
            const res = await RestHelper.RestSend('USP_SUGEUM','BORD',[
                {SUGEUM_YEAR_NM: this.year}
            ]);
            const mData = [...res._data];
            const tData = [...res._data01];
            this.tableData = [...res._data02];

            const date = moment().format('YYYY-MM-DD');
            if(moment(date).year()==Number(this.year))
            {
                const month = moment(date).month()+1;
                tData.length = month;
            }

            const customOrder = ['pm본부', '전략사업본부', '엔지니어링본부', 'CM본부','합계'];
            this.tableData.sort((a, b) => {
                return customOrder.indexOf(a.BUSEOMYEONG_NM) - customOrder.indexOf(b.BUSEOMYEONG_NM);
            });
            this.$nextTick(() => {
                this.lineChartDataObj = {};
                this.lineChartDataObj = {
                    labels: ['', '', '', '', '', '', '', '', '', '', '', ''],
                    datasets:
                    [ 
                        { 
                            data: mData.map(x=>x.MOKPYO_SUM),
                            backgroundColor: '#AAAAAA',
                            borderColor: '#AAAAAA',
                        },
                        { 
                            data: tData.map(x=>x.SUGEUM_SUM),
                            backgroundColor: '#5BA9E1',
                            borderColor: '#5BA9E1',
                        },
                    ],
                };

                const tempOption = {...this.lineChartOptionsObj};
                tempOption.scales.y.max = Math.max(...this.lineChartDataObj.datasets.map(x=>Math.max(...x.data)))*1.3;
                this.lineChartOptionsObj = {};
                this.lineChartOptionsObj = {...tempOption};

                this.barChartDataObj = {};
                this.barChartDataObj = {
                    labels: ['', '', '', '', '', '', '', '', '', '', '', ''],
                    datasets:
                    [ 
                        { 
                            data: mData.map(x=>x.MOKPYO_MONTH),
                            backgroundColor: '#AAAAAA',
                            barPercentage: 0.6,
                            categoryPercentage: 0.7
                        },
                        { 
                            data: tData.map(x=>x.SUGEUM_MONTH),
                            backgroundColor: '#5BA9E1',
                            barPercentage: 0.6,
                            categoryPercentage: 0.7
                        },
                    ],
                };
                // 차트 유동적으로 조정~..
                const tempOption2 = {...this.barChartOptionsObj};
                tempOption2.scales.y.max = Math.max(...this.barChartDataObj.datasets.map(x=>Math.max(...x.data)))*1.3;
                this.barChartOptionsObj = {};
                this.barChartOptionsObj = {...tempOption2};
            });
        }
    },
});
</script>

<style scoped>
#OutputYearChart canvas{
    width: 100% !important;
    height: 100% !important;
    max-width: 600px !important;
    max-height: 200px !important;
}
#buseo_text {
    word-break: keep-all;
    letter-spacing: -0.5px;
}
</style>