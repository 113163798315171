<template>
  <table class="table border-2 text-center text-[1.2rem] w-full h-full ">
        <tr :class="`table-row h-[2rem] border-[1px] bg-[#fff]`">
            <td class="table-cell border-[1px] w-[2rem] h-[94px] text-[#2B3133]" colspan="2" rowspan="2">부서</td>
            <td class="table-cell border-[1px] text-[#2B3133] w-[50px]" v-for="c of tableCols" :key="c" id="textColor">{{ c }}</td>
            <td class="table-cell border-[1px] text-[#2B3133]" colspan="2">2023년 평균</td>
            <td class="table-cell border-[1px] w-[200px] text-[#2B3133]" colspan="2" rowspan="2">비고</td>
        </tr>
        <tr :class="`table-row h-[2rem] border-[1px] bg-[#fff]`">
            <template v-for="i in 12" :key="i">
                <td class="w-[50px] table-cell border-[1px] text-[#787878] text-center">평균</td>
                <td class="w-[50px] table-cell border-[1px] text-[#787878] text-center">월말</td>
            </template>
            <td class="table-cell border-[1px]">평균기준</td>
            <td class="table-cell border-[1px]">월말기준</td>
        </tr>
        <!-- 본부 템플릿 -->
        <template v-for="bonbu in bonbuList" :key="bonbu">
            <tr :class="`table-row h-[${tdHeight}rem]`">
                <td :colspan="bonbu === 'PCM지원실'? 2 : 1" class="table-cell w-[10rem] border-[1px] text-[#2B3133] text-center" :rowspan="buseoMap.get(bonbu).length">{{ bonbu }}</td>
                <td v-if="bonbu != 'PCM지원실'" class="table-cell w-[10rem] border-[1px] text-[#2B3133] text-center">{{ buseoMap.get(bonbu)[0].BUSEOMYEONG_NM }}</td>
                <template v-for="i in 12" :key="i">
                    <td :class="`h-[${tdHeight}rem] w-[auto] text-center`" class="border-[1px]">
                        <!-- 240325 수정 (input에 borer 속성 모두 삭제)-->
                        <input v-if="type != 'info'" :style="{background : getColor(i)}" class="table-cell w-full h-full text-[#787878] text-center" type="number" v-model="buseoMap.get(bonbu)[0][`PYEONGGYUN${i}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                        <input v-if="type == 'info'" readonly :style="{background : getColor(i)}" class="table-cell w-full h-full text-[#787878] text-center" type="number" v-model="buseoMap.get(bonbu)[0][`PYEONGGYUN${i}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </td>
                    <td :class="`h-[${tdHeight}rem] w-[auto] text-center`" class="border-[1px]">
                        <input v-if="type != 'info'" :style="{background : getColor(i)}" class="table-cell w-full h-full text-[#787878] text-center" type="number" v-model="buseoMap.get(bonbu)[0][`WOLMAL${i}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                        <input v-if="type == 'info'" readonly :style="{background : getColor(i)}" class="table-cell w-full h-full text-[#787878] text-center" type="number" v-model="buseoMap.get(bonbu)[0][`WOLMAL${i}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </td>
                </template>
                <td :class="`h-[${tdHeight}rem] border-[1px] w-[auto] text-[#787878] text-center`" class="border-[1px]">
                    {{ getAverage("PYEONGGYUN",buseoMap.get(bonbu)[0]) }} <!--평균기준-->
                </td>
                <td :class="`h-[${tdHeight}rem] w-[auto] text-[#787878] text-center`" class="border-[1px]">
                    {{ getAverage("WOLMAL",buseoMap.get(bonbu)[0]) }} <!--월말기준-->
                </td>
                <td :class="`h-[${tdHeight}rem] w-[auto] text-[#787878] text-center`" class="border-[1px]">
                    <input v-if="type != 'info'" class="table-cell w-full h-full text-[#2B3133]" type="text" /> <!--비고-->
                    <input v-if="type == 'info'" readonly class="table-cell w-full h-full text-[#2B3133]" type="text" /> <!--비고-->
                </td>
            </tr>
            <tr v-for="i in buseoMap.get(bonbu).length - 1" :key="i" :class="`h-[${tdHeight}rem] border-[1px]`">
                <td class="border-[1px] text-[#2B3133]">{{ buseoMap.get(bonbu)[i]?.BUSEOMYEONG_NM }}</td>
                <template v-for="j in 12" :key="j">
                    <td :class="`h-[${tdHeight}rem] w-[auto] text-center`" class="border-[1px]">
                        <input v-if="type != 'info'" :style="{background : getColor(j)}" class="table-cell w-full h-full text-[#787878] text-center" type="number" v-model="buseoMap.get(bonbu)[i][`PYEONGGYUN${j}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                        <input v-if="type == 'info'" readonly :style="{background : getColor(j)}" class="table-cell w-full h-full text-[#787878] text-center" type="number" v-model="buseoMap.get(bonbu)[i][`PYEONGGYUN${j}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </td>
                    <td :class="`h-[${tdHeight}rem] w-[auto] text-center`" class="border-[1px]">
                        <input v-if="type != 'info'" :style="{background : getColor(j)}" class="table-cell w-full h-full text-[#787878] text-center " type="number" v-model="buseoMap.get(bonbu)[i][`WOLMAL${j}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                        <input v-if="type == 'info'" readonly :style="{background : getColor(j)}" class="table-cell w-full h-full text-[#787878] text-center " type="number" v-model="buseoMap.get(bonbu)[i][`WOLMAL${j}_AM`]" maxlength="5" size="50" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </td>
                </template>
                <td :class="`h-[${tdHeight}rem] table-cell w-[10rem] text-[#787878] text-center`" class="border-[1px]">
                    {{ getAverage("PYEONGGYUN",buseoMap.get(bonbu)[i]) }}<!--평균기준-->
                </td>
                <td :class="`h-[${tdHeight}rem] table-cell w-[10rem] text-[#787878] text-center`" class="border-[1px]">
                    {{ getAverage("WOLMAL",buseoMap.get(bonbu)[i]) }}<!--월말기준-->
                </td>
                <td :class="`h-[${tdHeight}rem] table-cell w-[10rem] border-[1px] text-center`" class="border-[1px]">
                    <input v-if="type != 'info'" class="table-cell w-full h-full text-[#787878] text-center" type="text" v-model="buseoMap.get(bonbu)[i].INWON_IPRYEOK_BIGO_NM"/> <!--비고-->
                    <input v-if="type == 'info'" readonly class="table-cell w-full h-full text-[#787878] text-center" type="text" v-model="buseoMap.get(bonbu)[i].INWON_IPRYEOK_BIGO_NM"/> <!--비고-->
                </td>
            </tr>
            <tr :class="`border-b-2 h-[${tdHeight}rem] border-[1px] text-center`" >
                <td class="border-[1px] font-semibold text-[#787878]" colspan="2">소계</td>
                <template v-for="i in 12" :key="i">
                    <td :style="{background : getColor(i)}" class="border-[1px] text-center text-[#787878]">{{buseoMap.get(bonbu).reduce((accumulator, currentValue) => {return !isNaN(currentValue[`PYEONGGYUN${i}_AM`]) ? accumulator + currentValue[`PYEONGGYUN${i}_AM`] : accumulator}, 0)}}</td>
                    <td :style="{background : getColor(i)}" class="border-[1px] text-center text-[#787878]">{{buseoMap.get(bonbu).reduce((accumulator, currentValue) => {return !isNaN(currentValue[`WOLMAL${i}_AM`]) ? accumulator + currentValue[`WOLMAL${i}_AM`] : accumulator}, 0)}}</td>
                </template>
                <td class="border-[1px] text-[#787878]">{{ getAverageBonbu("PYEONGGYUN",bonbu) }}</td>
                <td class="border-[1px] text-[#787878]">{{ getAverageBonbu("WOLMAL",bonbu) }}</td>
                <td class="border-[1px] text-center text-[#787878]"></td>
            </tr>
        </template>
        <!-- <tr :class="`h-[${tdHeight}rem] border-[1px]`"  class="h-[4.9rem]">
            <td class="border-[1px] text-[#787878]" colspan="2">비상근</td>
            <template v-for="i in 12" :key="i">
                <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{0}}</td>
                <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{0}}</td>
            </template>
            <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{ getColSum('평균기준') }}</td>
            <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{ getColSum('월말기준') }}</td>
            <td class="border-[1px]"></td>
        </tr>
        <tr :class="`h-[${tdHeight}rem] border-[1px] text-[#787878]`"  class="h-[4.9rem]">
            <td class="border-[1px] text-[#787878]" colspan="2">합계(비상근 제외)</td>
            <template v-for="i in 12" :key="i">
                <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{getColSum(`PYEONGGYUN${i}_AM`)}}</td>
                <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{getColSum(`WOLMAL${i}_AM`)}}</td>
            </template>
            <td class="border-[1px] text-[#787878]">{{ getColSum('평균기준') }}</td>
            <td class="border-[1px] text-[#787878]">{{ getColSum('월말기준') }}</td>
            <td class="border-[1px] text-[#787878]"></td>
        </tr>
        <tr :class="`h-[${tdHeight}rem] border-[1px] text-[#787878]`"  class="h-[4.9rem]">
            <td class="border-[1px] text-[#787878]" colspan="2">전월대비 인원증감</td>
            <template v-for="i in 12" :key="i">
                <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{getColSum(`PYEONGGYUN${i}_AM`)}}</td>
                <td :style="{background : getColor(i)}" class="border-[1px] text-[#787878]">{{getColSum(`WOLMAL${i}_AM`)}}</td>
            </template>
            <td class="border-[1px] text-[#787878]">{{ getColSum('평균기준') }}</td>
            <td class="border-[1px] text-[#787878]">{{ getColSum('월말기준') }}</td>
            <td class="border-[1px] text-[#787878]"></td>
        </tr> -->
        <!-- <tr></tr> -->
    </table>
</template>

<script>
export default {
    computed : {
        tableCols()
        {
            return ['1월','31','2월','28','3월','31','4월','30','5월','31','6월','30'
                    ,'7월','31','8월','31','9월','30','10월','31','11월','30','12월','31']
        },
        tdHeight()
        {
            return 3.4;
        }
    },
    props:{
        bonbuList : [],
        month : [],
        datasource : [],
        buseoMap : Map,
        type : String
    },
    methods:{
        getAverage(prop,buseo)
        {
            let ret = 0;
            for(let i = 1; i<13; i++)
            {
                const value = Number(buseo[`${prop}${i}_AM`]);
                if(!isNaN(value))
                {
                    ret+=value;
                }
            }
            return Math.round((ret/12)*100)/100;
        },
        getAverageBonbu(prop,bonbu)
        {
            let ret = 0;
            const buseoList = this.buseoMap.get(bonbu);
            for(const o of buseoList)
            {
                ret += this.getAverage(prop,o);
            }
            return Math.round((ret)*100)/100;
        },
        getColSum(prop)
        {
            if(prop == '평균기준')
            {
                let ret = 0;
                for(const bonbu of this.bonbuList)
                {
                    ret += this.getAverageBonbu("PYEONGGYUN",bonbu);
                }
                return (Math.round(ret*100)/100);
            }
            else if(prop == '월말기준')
            {
                let ret = 0;
                for(const bonbu of this.bonbuList)
                {
                    ret += this.getAverageBonbu("WOLMAL",bonbu);
                }
                return (Math.round(ret*100)/100);
            }
            const ret = this.datasource?.reduce((arr,curr)=>{
                const value = Number(curr[prop]);
                return !isNaN(value) ? arr+value : arr;
            },0);
            return ret;
        },
        getColor(index)
        {
            const i = Number(this.month.replace(/[^0-9]/g, ""));
            if(index <= i) return '#FDFBEA'
            return '#fff'
        },
    }
}
</script>

<style>

</style>