<template>
    <div class="w-auto h-full pt-[1rem] bg-white font-NotoSansKR" id="BUNBAE_view">
        <div v-if="type!='info'" class="flex flex-row justify-between items-center w-full mb-[1rem] px-[1rem] ">
            <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">{{ type=='info' ? '배분현황' : '배분관리' }}</p>
            <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">일괄저장</div>
        </div>
        <SelectButton v-if="type=='info'" v-model="viewType" :options="[{ value: '본부별 배분' },{ value: 'PJ별 배분' },{ value: '주관부서별 배분' }]" class="flex text-center mb-[2rem] justify-around items-center w-[41.2rem] h-[4rem] bg-[#f8f8f8] rounded-lg p-[4px] ml-[1rem]" optionLabel="value" dataKey="value" aria-labelledby="custom">
            <template #option="slotProps">
                <p class="cursor-pointer select-none text-[1.1rem] rounded-lg w-[13.2rem] text-center py-[0.9rem] h-[3.2rem]]">{{ slotProps.option.value }}</p>
            </template>
        </SelectButton>
        <!-- <div class="h-8"></div> -->
        <div v-if="type=='info'" class="flex flex-row items-center justify-between mb-[1rem] mx-[1rem] ">
            <div class="flex flex-row items-center">
                <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]">조회연도</div>
                <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                    <option v-for="y in yearList" :key="y">{{ y }}</option>
                </select>
                <div v-if="viewType.value == 'PJ별 배분'" class="text-[#92959B] mx-[1.5rem] text-[1.3rem]">조회월</div>
                <select v-if="viewType.value == 'PJ별 배분'" class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="month">
                    <option v-for="m in monthList" :key="m">{{ m }}</option>
                </select>
                <div v-if="viewType.value == 'PJ별 배분'" class="text-[#92959B] mx-[1.5rem] text-[1.3rem]">부서</div>
                <select v-if="viewType.value == 'PJ별 배분'" class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="selBonbu">
                    <option v-for="bonbu in bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
                </select>
            </div>
            <div class="flex flex-row items-center gap-[1rem]">
                <div @click="onClickExcelDown" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">
                <p class="w-full">엑셀저장</p>
                </div>
                <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
            </div>
        </div>
        <div v-if="type!='info'" class="flex flex-row items-center mt-[0rem] px-[1rem] pb-0">
            <!-- <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]">조회연도</div> -->
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                <option v-for="y in yearList" :key="y">{{ y }}</option>
            </select>
            <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]"></div>
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="month">
                <option v-for="m in monthList" :key="m">{{ m }}</option>
            </select>
            <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]"></div>
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="selBonbu">
                <option v-for="bonbu in bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
            </select>
            <div class="flex flex-row items-center ml-[2rem]">
                <input @keydown="searchKeyDown" type="text" v-model="keyword" placeholder="PJ코드, PJ명 검색" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[25rem]">
                <div @click="searchCallback" class="flex flex-row items-center justify-center ml-[2rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                    <p class="text-white">검색</p>
                </div>
            </div>
            <!-- <DxSelectBox class="w-[48rem] h-[3.7rem] border-2 mx-[1.5rem] text-[1.4rem]" :data-source="rowFilterDataList" v-model="selProj" display-expr="PJ_NM" :search-expr="['PJ_NM','PJCODE_ID']" :search-enabled="true" placeholder="프로젝트 검색 or 선택"/> -->
        </div>
        <div v-if="type!='info'" class="flex flex-row justify-end w-full my-[1rem] px-[1rem]">
            <div @click="historySidebar.open()" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">변경 조회</div> 
            <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
        <!-- Manage list -->
        <div v-if="type!='info' || viewType.value == 'PJ별 배분'" class="flex w-full justify-end bg-[#EDEFF2]">
            <div @click="setOpenList()" class="flex flex-row w-[10rem] items-center justify-start border-2 bg-white mr-[1rem] mt-[1rem] px-[0.5rem] py-[0.7rem] rounded-lg text-[1.1rem] text-[#787878] cursor-pointer select-none hover:bg-gray-100">
                <svg v-if="getListOpen()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#575757" class="w-[2rem] h-6 mr-[0.4rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg>
                <svg v-if="!getListOpen()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#575757" class="w-[2rem] h-6 mr-[0.4rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
                <p v-if="getListOpen()">일괄 접기</p>
                <p v-if="!getListOpen()">일괄 펼치기</p>
            </div>
        </div>
        <div class="w-full h-full overflow-auto bg-[#EDEFF2]" :class="hiddenGrid">
            <div v-if="type!='info' || viewType.value == 'PJ별 배분'" class="h-[calc(100%-17rem)] w-full overflow-auto px-[1.5rem] py-[1.5rem] ">
            <div v-for="row in rowFilterDataList" :key="row" class="flex flex-col bg-white py-[2rem] px-[2rem] overflow-hidden text-ellipsis whitespace-nowrap rounded-lg mb-[2.5rem] ">
                <div class="flex flex-row justify-between w-full border-b-2 pb-[1rem] select-none">
                    <div class="font-bold text-[1.6rem]">{{ row.PJ_NM }}</div>
                    <svg v-if="row.isOpen" @click="()=>{row.isOpen = !row.isOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#575757" class="w-[2rem] h-[2rem] mr-[0.4rem] cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                    <svg v-if="!row.isOpen" @click="()=>{row.isOpen = !row.isOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#575757" class="w-[2rem] h-[2rem] mr-[0.4rem] cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
                <div v-if="row.isOpen" class="flex flex-row items-center justify-between border-b-2 border-gray-300 h-12">
                    <div class="w-full flex flex-row items-center justify-end">
                    <!-- <div class="border-r-2 border-gray-300 px-4 mr-4 font-bold text-gray-600">PJ NAME</div> -->
                        <div class="flex flex-row mx-4 text-lg items-center">
                            <!-- <div class="w-40 border-2 text-center cursor-pointer hover:bg-slate-100">btn1</div>
                            <div class="w-40 border-2 text-center cursor-pointer hover:bg-slate-100">btn1</div> -->
                            <!-- <div class="w-40 border-2 text-center text-lg cursor-pointer hover:bg-slate-100">저장</div> -->
                                <input :name="'type'+row.SUJU_SQ+row.PJCODE_ID" v-model="row.VIEWTYPE" type="radio" value="1"/>
                                <div class="mx-2">월별수금배분</div>
                                <input :name="'type'+row.SUJU_SQ+row.PJCODE_ID" v-model="row.VIEWTYPE" type="radio" value="2"/>
                                <div class="mx-2">수금배분계획</div>
                        </div>
                    </div> 
                </div>
                <div v-if="row.isOpen" class="flex flex-row gap-[1rem] px-[2rem] py-[1rem] items-center justify-content-center h-[3.6rem] bg-[#FAFAFA] w-full ">
                    <div class="w-full h-full flex flex-row items-center justify-items-start">
                        <div class="w-auto h-full text-[#787878]">프로젝트 코드</div>
                        <div class="pl-[1.2rem] w-[12rem] h-full font-bold text-[#2B3133]">{{ row.PJCODE_ID }}</div>
                    </div>

                    <div class="w-full h-full flex flex-row items-center justify-items-start">
                        <div class="w-auto h-full text-[#787878]">주관부서</div>
                        <div class="pl-[1.2rem] w-[12rem] h-full font-bold text-[#2B3133]">{{ row.SUGEUM_BONBU }}</div>
                    </div>

                    <div class="w-full h-full flex flex-row items-center justify-items-start">
                        <div class="w-auto h-full text-[#787878]">수주분류</div>
                        <div class="pl-[1.2rem] w-[12rem] h-full font-bold text-[#2B3133]">{{ row.BUNRYU_CD }}
                        </div>
                    </div>

                    <div class="w-full h-full flex flex-row items-center justify-items-start">
                        <div class="w-auto h-full text-[#787878]">업무분류</div>
                        <div class="pl-[1.2rem] w-[12rem] h-full font-bold text-[#2B3133]">{{ row.EOPMU_CD }}
                        </div>
                    </div>

                    <div class="w-full h-full flex flex-row items-center justify-items-start">
                        <div class="w-auto h-full pr-[1.2rem] text-[#787878]">계약금액</div>
                        <div class="w-[20rem] h-full font-bold text-[#2B3133]">
                            <p>{{ formatNumberWithCommas(row.GYEYAK_GEUMAEK_AM) }}</p>
                        </div>
                    </div>

                    <div class="w-full h-full flex flex-row items-center justify-items-star">
                        <div class="w-auto h-full pr-[1.2rem] text-[#787878]">해안지분금액</div>
                        <div class="w-auto h-full font-bold text-[#2B3133]">
                            <p>{{ formatNumberWithCommas(row.GIJUN_GEUMAEK_AM) }}</p>
                        </div>
                    </div>

                    <div class="h-full flex flex-row items-center justify-items-start gap-[1rem]">
                        <div class="w-full h-full text-[#787878] flex flex-row items-center justify-center">공동이행금액</div>
                    <div class="w-full h-full font-bold text-[#111111] flex flex-row items-center justify-center">
                        <p>{{ formatNumberWithCommas(row.GONGDONG) }}</p>    
                    </div>
                    </div>
                </div>
                <table v-if="row.VIEWTYPE==='1' && row.isOpen" class="table w-full text-center border-2 border-gray-300 mb-[1rem]">
                    <tr class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-[20rem]">수금계획</td>
                        <td class="border-x-2 table-cell w-[10rem]">전체 합계</td>
                        <td class="border-x-2 table-cell w-[10rem]">연 합계</td>
                        <td class="border-x-2 table-cell w-[10rem]" v-for="m in 12" :key="m">
                            <div class="flex flex-row w-full h-full items-center justify-center">
                                {{ m }}월
                            </div>
                        </td>
                    </tr>
                    <tr v-for="bonbu in [datasource.find(x=>x.SUJU_SQ === row.SUJU_SQ)]" :key="bonbu" class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-[20rem]">
                            <div class="flex flex-row ml-2">
                                <div></div>
                            </div>
                        </td>
                        <td class="border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu?.S_TOTAL) }}</td>
                        <td class="border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu?.S_YEAR) }}</td>
                        <td @click="onCellClick($event)" :class="`border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem] ${bonbu?.getSugeumStyle(m)}`" v-for="m in 12" :key="m">
                            <p>{{ formatNumberWithCommas(bonbu?.getSugeum(m) ?? 0) }}</p>
                        </td>

                    </tr>
                </table>
                <table v-if="row.VIEWTYPE==='1' && row.isOpen" class="table w-full text-center border-2 border-gray-300 mb-[1rem]">
                    <tr class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-[20rem]">매출계획</td>
                        <td class="border-x-2 table-cell w-[10rem]">전체 합계</td>
                        <td class="border-x-2 table-cell w-[10rem]">연 합계</td>
                        <td class="border-x-2 table-cell w-[10rem]" v-for="m in 12" :key="m">
                            <div class="flex flex-row w-full h-full items-center justify-center">
                                {{ m }}월
                            </div>
                        </td>
                    </tr>
                    <tr v-for="bonbu in datasource.filter(x=>x.SUJU_SQ === row.SUJU_SQ)" :key="bonbu" class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-[20rem]">
                            <div class="flex flex-row ml-2">
                                <div class="rounded-lg w-[5px] h-[16px] mr-[1rem]" :style="{background : `#${bonbu.BUSEO_COLOR_NM}`}"></div>
                                <div>{{ bonbu.BONBU_NM }}</div>
                            </div>
                        </td>
                        <td class="border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu.M_TOTAL) }}</td>
                        <td class="border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu.M_YEAR) }}</td>
                        <td @click="onCellClick($event)" class="border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]" v-for="m in 12" :key="m">
                            <p>{{ formatNumberWithCommas(bonbu[`MAECHUL_M${String(m).padStart(2,'0')}`])}}</p>
                        </td>
                    </tr>
                </table>
                <table v-if="row.VIEWTYPE==='1' && row.isOpen" class="table w-full text-center ">
                    <tr class="table-row">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <button @click="onClickShowAuto(row)" class="w-full py-1 bg-[#edeff2] rounded-t-lg border-[1px] mr-[1rem] color-[#787878] text-[1.2rem]">자동입력</button>
                        </td>
                    </tr>
                    <tr class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-[20rem]">배분계획</td>
                        <td class="border-x-2 table-cell w-[10rem]">전체 합계</td>
                        <td class="border-x-2 table-cell w-[10rem]">연 합계</td>
                        <td class="border-x-2 table-cell w-[10rem]" v-for="m in 12" :key="m">
                            <div class="flex flex-row w-full h-full items-center justify-center pl-5">
                                {{ m }}월
                                <img v-if="type!='info'" @click="showPopup(row.SUJU_SQ,m)" class="px-2 h-[1rem] cursor-pointer" src="../../assets/icon/colEdit.png" />
                            </div>
                        </td>
                    </tr>
                    <tr v-for="bonbu in datasource.filter(x=>x.SUJU_SQ === row.SUJU_SQ)" :key="bonbu.SUJU_SQ" class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-[20rem]">
                            <div class="flex flex-row ml-2">
                                <div class="rounded-lg w-[5px] h-[16px] mr-[1rem]" :style="{background : `#${bonbu.BUSEO_COLOR_NM}`}"></div>
                                <div>{{ bonbu.BONBU_NM }}</div>
                            </div>
                        </td>
                        <td class="w-[10rem] border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu?.B_TOTAL ?? 0) }}</td>
                        <td class="w-[10rem] border-x-2 table-cell text-[#AEAEAE] text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu?.B_YEAR ?? 0) }}</td>
                        <td @click="onCellClick($event)" class="w-[10rem] border-x-2 table-cell" v-for="m in 12" :key="m">
                            <InputNumber class="p-0 m-0 w-[8rem] h-full text-right" v-model="bonbu[`BUNBAE_MONTH${m}_CN`]" :maxFractionDigits="3"/>
                            <!-- <input class="w-full h-full text-right pr-[0.8rem]" :class="bonbu.getColumStatus(m)" :readonly="bonbu.getColumStatus(m) == 'target' || type=='info'" type="text" :value="formatNumberWithCommas(bonbu[`BUNBAE_MONTH${m}_CN`])"/> -->
                        </td>
                    </tr>
                </table>
                <table v-if="row.VIEWTYPE==='2' && row.isOpen" class="table w-full text-center border-2 border-gray-300">
                    <tr class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-60">{{ year }}</td>
                        <td class="border-x-2 table-cell w-[30%]">수금배분계획</td>
                        <td class="border-x-2 table-cell w-[30%]">합계</td>
                        <td class="border-x-2 table-cell w-[30%]">잔금</td>
                    </tr>
                    <tr v-for="(bonbu,i) in datasource.filter(x=>x.SUJU_SQ === row.SUJU_SQ)" :key="i" class="table-row border-y-2 h-12">
                        <td class="border-x-2 table-cell w-60" :style="{background : `#${bonbu.BUSEO_COLOR_NM}`}">{{ bonbu.BONBU_NM }}</td>
                        <td class="border-x-2 table-cell text-right pr-[0.8rem]">{{ formatNumberWithCommas(bonbu?.B_TOTAL ?? 0) }}</td>
                        <td v-if="i==0" :rowspan="datasource.filter(x=>x.SUJU_SQ === row.SUJU_SQ).length" class="border-x-2 table-cell text-right pr-[0.8rem]">{{ formatNumberWithCommas(getB_Total_Sum(row.SUJU_SQ)) }}</td>
                        <td v-if="i==0" :rowspan="datasource.filter(x=>x.SUJU_SQ === row.SUJU_SQ).length" class="border-x-2 table-cell text-right pr-[0.8rem]">{{ formatNumberWithCommas(Number(row.GYEYAK_GEUMAEK_AM) - getB_Total_Sum(row.SUJU_SQ)) }}</td>
                    </tr>
                </table>
            </div>
            </div>

        </div>
        <!-- Info grid -->
        <!-- <DxPivotGrid v-if="type=='info'" 
        class="select-none border-2 mt-10 w-[1710px] mx-[1.6rem]" 
        height="calc(100% - 11rem)" :data-source="infosource"
        :allow-sorting-by-summary="false" :allow-sorting="false"
        :show-row-grand-totals="false" :show-row-totals="false"
        :allow-filtering="false" :allow-expand-all="true"
        >
            <DxFieldChooser :enabled="false"/>
        </DxPivotGrid> -->
        <!-- <div class="w-full border-b-2 mr-6 mb-[2.6rem]"></div> -->
        <DxDataGrid v-if="type=='info' && viewType.value === '본부별 배분'"  :show-borders="true" :data-source="infoDataSource" data-row-template="dataRowTemplate" class="mx-[1rem]" ref="dataGrid">
            <template #dataRowTemplate="{ data: rowInfo }">
                <tr>
                    <td>
                        <div class="flex flex-row ml-2">
                            <div class="rounded-lg w-[5px] h-[16px] mr-[1rem]" :style="{background : `#${rowInfo.data.BUSEO_COLOR_NM}`}"></div>
                            <div>{{ rowInfo.data.BUSEOMYEONG_NM }}</div>
                        </div>
                    </td>
                    <td v-for="m in 12" :key="m">{{ formatNumberWithCommas(rowInfo.data[`month${m}`]) }}</td>
                    <td>{{ formatNumberWithCommas(infoRowSum(rowInfo.data)) }}</td>
                </tr>
            </template>
            <DxPaging :enabled="false"/>
            <DxSelection mode="single"/>

            <DxColumn caption="부서" width="10%" data-field="BUSEOMYEONG_NM" alignment="center" class="border-[1px] border-[#d9d9d9]"/>
            <DxColumn v-for="m in 12" :key="m" :caption="`${m}월`" width="8%" :data-field="`month${m}`" alignment="right"/>
            <DxColumn caption="합계" width="8%" alignment="right"/>
        </DxDataGrid>

        <DxTreeList v-if="type=='info' && viewType.value === '주관부서별 배분'" height="calc(100% - 18rem)" :data-source="buseoBaebunList" parent-id-expr="P_NO" key-expr="NO" class="border-2 mx-[1rem]" :show-borders="true" ref="treeList">
            <TreeColumn
                data-field="CHILD_BBUSEO_NAME"
                caption="부서"
                alignment="center"
                cell-template="buseoCell"
                class="border-[1px] border-[#d9d9d9]"
                width="10%"
                
            />
            <TreeColumn v-for="month in 12" :key="month"
                :data-field="`MONTH${month}_AM`"
                :caption="`${month}월`"
                alignment="right"
                format="###,###,###,##0"
            />
            <template #buseoCell="{data : rowInfo}">
                <div class="flex flex-row items-center overflow-hidden w-full">
                    <div :style="{ background: '#'+rowInfo.data.CHILD_BBUSEO_COLOR }" :class="`w-[0.8rem] h-[1.4rem] rounded-lg mr-[0.4rem]`"></div>
                    <p class="overflow-hidden text-ellipsis">{{ rowInfo.data.CHILD_BBUSEO_NAME }}</p>
                </div>
            </template>
        </DxTreeList>
        <div v-if="type=='info' && viewType.value == '주관부서별 배분'" class="flex flex-row h-[6rem] border-2 mx-[1rem]">
            <div class="h-full w-[10.35%] border-r-[1px]"></div>
            <div v-for="month in 12" :key="month" class="flex items-center justify-center h-full w-[7.7%] border-r-[1px] text-center">{{ setUnit(buseoBaebunTotal[`MONTH${month}_AM`]) }}</div>
        </div>
        
        <DxPopup :title="formModel.PJ_NM" :visible="popupVisible" :width="500" class="max-h-[850px] overflow-y-scroll">
            <div class="grid grid-rows-auto w-full h-full px-4">
                <div class="grid grid-rows-auto gap-y-[1.5rem]">
                    <div class="flex flex-row justify-between items-start">
                    <div class="flex flex-col">
                        <div class="font-bold">예상 수금액</div>
                        <InputNumber class="border-2 bg-slate-200 w-80 text-[1.4rem] h-[2.6rem]" v-model="formModel.SUGEUM_WON_AM1" :maxFractionDigits="3" disabled/>
                        <!-- <input class="border-2 bg-slate-200 w-80" v-model="formModel.SUGEUM_WON_AM1" type="number" readonly/> -->
                    </div>
                    <div class="flex flex-col">
                        <div class="font-bold">확정 수금액</div>
                        <InputNumber class="border-2 bg-slate-200 w-80 text-[1.4rem] h-[2.6rem]" v-model="formModel.SUGEUM_WON_AM2" :maxFractionDigits="3" disabled/>
                        <!-- <input class="border-2 bg-slate-200 w-80" v-model="formModel.SUGEUM_WON_AM2" type="number" readonly/> -->
                    </div>
                </div>
                <div class="flex flex-row justify-between items-start">
                    <div class="flex flex-col">
                        <div class="font-bold">예상 수금일</div>
                        <input class="border-2 bg-slate-200 w-80" v-model="formModel.SUGEUM_DATE_DT1" type="date" readonly/>
                    </div>
                    <div class="flex flex-col">
                        <div class="font-bold">확정 수금일</div>
                        <input class="border-2 bg-slate-200 w-80" v-model="formModel.SUGEUM_DATE_DT2" type="date" readonly/>
                    </div>
                </div>
                <div v-for="bonbu in eidtBonbuList" :key="bonbu" class="flex flex-row justify-between items-start">
                    <div class="flex flex-col">
                        <div class="font-bold">{{ bonbu.BONBUMYEONG }} 예상 배분 금액</div>
                        <InputNumber class="border-2 bg-slate-200 w-80 text-[1.4rem] h-[2.6rem]" v-model="bonbu.BUNBAE_WON_AM1" :maxFractionDigits="3"/>
                        <!-- <input class="border-2 w-80" v-model="bonbu.BUNBAE_WON_AM1" type="number"/> -->
                    </div>
                    <div class="flex flex-col">
                        <div class="font-bold">{{ bonbu.BONBUMYEONG }} 확정 배분 금액</div>
                        <InputNumber class="border-2 bg-slate-200 w-80 text-[1.4rem] h-[2.6rem]" v-model="bonbu.BUNBAE_WON_AM2" :maxFractionDigits="3"/>
                        <!-- <input class="border-2 w-80" v-model="bonbu.BUNBAE_WON_AM2" type="number"/> -->
                    </div>
                </div>
                </div>


                <div class="flex flex-col">
                    <div class="font-bold mt-16">변경사유</div>
                    <textarea class="border-2 h-80" v-model="formModel.BIGO" id="BUNBAE_textarea"></textarea>
                </div>
                <div class="flex flex-row items-end justify-end pb-4">
                    <div @click="()=>{this.popupVisible = false}" class="border-2 py-2.5 px-10 mr-4 hover:bg-slate-100 cursor-pointer">닫기</div>
                    <div @click="onClickEditSave" class="border-2 py-2.5 px-10 bg-[#0E4296] text-white hover:bg-blue-900 cursor-pointer">저장</div>
                </div>
            </div>
        </DxPopup>
        <HistorySidebar ref="historySidebar" menuName="C0040"/>
    </div>
</template>
    
<script>
import {
DxDataGrid,
DxColumn,
DxPaging,
DxSelection,
DxLoadPanel
} from 'devextreme-vue/data-grid';
import DxTabPanel from 'devextreme-vue/tab-panel';
import { DxPivotGrid} from 'devextreme-vue/pivot-grid';
import { DxPopup } from 'devextreme-vue/popup';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import RestHelper from '../Helpers/RestHelper'
import DataHelper from '../Helpers/DataHelper'
import LogHelper from '../Helpers/LogHelper';
import BunbaeModel from '../Models/BunbaeModel';
import { DxSelectBox } from 'devextreme-vue/select-box';
import SelectButton from 'primevue/selectbutton';
import {
  DxTreeList, DxSorting, DxColumn as TreeColumn, DxLookup,DxSummary,DxTotalItem
} from 'devextreme-vue/tree-list';
import sf from 'sf';
import HistorySidebar from '../Controls/HistorySidebar.vue';
import InputNumber from 'primevue/inputnumber';
import ExcelJS from 'exceljs';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid as dxExportDataGrid } from 'devextreme/excel_exporter';
import { exportTreeList } from 'devextreme/excel_exporter';

export default {
    components :{
        DxPopup,DxTreeList, DxSorting,
        DxPivotGrid,TreeColumn, DxLookup,
        DxSelectBox,
        DxDataGrid,
        DxColumn,InputNumber,
        DxPaging,HistorySidebar,
        DxSelection,DxTotalItem,
        DxLoadPanel,DxSummary,
        DxTabPanel,SelectButton,

    },
    props:{
        type : String,
    },
    computed:{
        dataGrid()
        {
            return this.$refs.dataGrid.instance;
        },
        monthKeys()
        {
            return ['BUNBAE_MONTH1_CN',
                    'BUNBAE_MONTH2_CN',
                    'BUNBAE_MONTH3_CN',
                    'BUNBAE_MONTH4_CN',
                    'BUNBAE_MONTH5_CN',
                    'BUNBAE_MONTH6_CN',
                    'BUNBAE_MONTH7_CN',
                    'BUNBAE_MONTH8_CN',
                    'BUNBAE_MONTH9_CN',
                    'BUNBAE_MONTH10_CN',
                    'BUNBAE_MONTH11_CN',
                    'BUNBAE_MONTH12_CN']
        },
        infoCols()
        {
            const ret = [];
            for(let i = 1; i<13; i++)
                ret.push(`${i<10?' '+i : i}월`)
            return ret;
        },
        infoRows()
        {
            return ['기수금금액','미수금금액','총수금금액'];
        },
        monthList()
        {
            const ret = ['전체']
            for(let i = 1; i<13; i++)ret.push(i);
            return ret
        },
        historySidebar()
        {
            return this.$refs.historySidebar;
        },
        projectSearch()
        {
            return this.$refs.projectSearch;
        },
        hiddenGrid()
        {
            if(this.type!='info' || this.viewType.value == 'PJ별 배분')
            {
                return 'visible';
            }
            else
            {
                return 'hidden';
            }
        }, 
    },
    watch:{
        year()
        {
            if(this.year == null) return;
            this.getData();
        },
        month()
        {
            this.getFilterList();
        },
        selBonbu()
        {
            this.getFilterList();
        },
        selProj()
        {
            // this.getFilterList();
        },
        viewType(n,o)
        {
            if(n == null)
            {
                this.viewType = o;
            }
        }
    },
    data(){
        return{
            yearList : [],
            year : null,
            month : '전체',
            selBonbu : {},
            selProj : {},
            datasource : [],
            rowDataList : [],
            rowFilterDataList : [],
            popupVisible : false,
            bonbuList : [],
            bonbuInfoList : [],
            eidtBonbuList : [],
            editMonth : 1,
            formModel : {
                PJ_NM : '',
                BUNBAE_WON_AM1 : 0,
                BUNBAE_WON_AM2 : 0,
            },
            infoStore : [],
            infosource : new PivotGridDataSource(),
            infoDataSource : [],
            viewType : {value: '본부별 배분'},
            buseoBaebunList : [],
            buseoBaebunTotal : {},
            keyword : '',
            row: {
                GYEYAK_GEUMAEK_AM : null,
            },

        }
    },
    async created(){
        await this.getYears();
        await this.getBonbu();
    },
    mounted()
    {
    },
    methods:{
        async init()
        {
            const tempYear = this.year;
            this.year = null;
            this.$nextTick(()=>{
                this.year = tempYear;
            })
        },
        searchKeyDown(e)
        {
            if(e.code == 'Enter' || e.code == 'NumpadEnter')
            {
                this.getFilterList();
            }
        },
        searchCallback()
        {
            this.getFilterList();
        },
        setUnit(value)
        {
            return sf("{0:###,###,###,###,###,###,###,##0}",Number(value));
        },
        onChangeTab(e)
        {
            if(e.name === "selectedItems")
            {
                this.selectedItem = e.value[0]
            }
        },
        async getBonbu()
        {
            const res = await RestHelper.RestSend('USP_BUSEO','LIST',[{
                STARTYEAR : '0',
                ENDYEAR : DataHelper.getDate(),
                USE_YN : 'Y'
            }]);
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('부서리스트 호출',[res],this.logUse)
                this.bonbuList =[{BUSEOMYEONG_NM : '전체'}]
                this.bonbuList = this.bonbuList.concat(res._data.filter(x=>x.DEPT_LEVEL == 4));
                this.$nextTick(()=>{
                    this.selBonbu = this.bonbuList[0].BUSEOMYEONG_NM;
                })
            }
        },
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
            LogHelper.logWrite(`배분 연도 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                this.yearList = [];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear();
                })
            }
        },
        async getData() {
            this.datasource = [];
            const fnc = async (s, m) => {
                const res = await RestHelper.RestSend('USP_BUNBAE', 'LIST', [{
                    BUNBAE_YEAR_NM: this.year,
                    SANGTAE_NM: s
                }]);
                LogHelper.logWrite(`배분 리스트 요청 (상태코드 : 0,1)`, [s, res], this.logUse)
                if (res._result[0].RESULTCD == '1') {
                    const data = [...res._data]; // 배분 데이터
                    const data1 = [...res._data01]; // 수금 데이터
                    const data2 = [...res._data02]; // 매출 데이터
                    for (const o of data) {
                        const existObj = this.datasource.find(x => x.SUJU_SQ.toString() + x.BUSEO_SQ.toString() === o.SUJU_SQ.toString() + o.BONBU_ID.toString());
                        const model = existObj ?? new BunbaeModel();
                        model.BUNBAE_YEAR_NM = this.year;
                        model.SUJU_SQ = o.SUJU_SQ;
                        model.BUSEO_SQ = o.BONBU_ID;
                        model.PJ_NM = o.PJ_NM;
                        model.YONGYEOK_SIJAK_DT = o.YONGYEOK_SIJAK_DT;
                        model.YONGYEOK_MAGAM_DT = o.YONGYEOK_MAGAM_DT;
                        model.PJCODE_ID = o.PJCODE_ID;
                        model.BUNRYU_CD = o.BUNRYU_CD;
                        model.EOPMU_CD = o.EOPMU_CD;
                        model.BUSEO_COLOR_NM = o.BUSEO_COLOR_NM;

                        // Check if GYEYAK_GEUMAEK_AM is a valid number and format it
                        if (o.GYEYAK_GEUMAEK_AM && !isNaN(Number(o.GYEYAK_GEUMAEK_AM))) {
                            model.GYEYAK_GEUMAEK_AM = Number(o.GYEYAK_GEUMAEK_AM).toLocaleString();
                        } else {
                            model.GYEYAK_GEUMAEK_AM = '0'; // or handle as needed
                        }

                        model.GONGDONG = o.GONGDONG;
                        model.BONBU_NM = o.BONBU_NM;
                        for (const mk of this.monthKeys) {
                            if (m === '_S0') {
                                model.BUNBAE_MONTH_CN_OBJ[mk + m] = o[mk] ?? 0;
                            } else {
                                model.BUNBAE_MONTH_CN_OBJ[mk + m] = o[mk] ?? null;
                                if (model.BUNBAE_MONTH_CN_OBJ[mk + m] === 0)
                                    model.BUNBAE_MONTH_CN_OBJ[mk + m] = null;
                            }
                        }
                        model['B_TOTAL'] = o['TOTAL_SUM'];
                        model['B_YEAR'] = o['YEAR_SUM'];                        

                        const SUGEUM_DATA = data1.find(x => x.SUJU_SQ === o.SUJU_SQ)
                        model.SUGEUM_BONBU = SUGEUM_DATA.BONBU_NM;
                        for (let sg_month = 1; sg_month < 13; sg_month++) {
                            const sg_value = SUGEUM_DATA[`SUGEUM_MONTH_CN${sg_month}`];
                            if (s == '0') {
                                model[`SUGEUM_MONTH_CN${sg_month}`] = sg_value ?? 0
                            } else if (sg_value != null && sg_value != 0 && !isNaN(Number(sg_value))) {
                                model[`SUGEUM_MONTH_CN${sg_month}`] = `${sg_value}_1`
                            } else {
                                model[`SUGEUM_MONTH_CN${sg_month}`] = `${model[`SUGEUM_MONTH_CN${sg_month}`]}_0`
                            }
                        }
                        model['GYEYAK_GEUMAEK_AM'] = SUGEUM_DATA['GYEYAK_GEUMAEK_AM'];
                        model['GIJUN_GEUMAEK_AM'] = SUGEUM_DATA['GIJUN_GEUMAEK_AM'];
                        model['GONGDONG'] = SUGEUM_DATA['GONGDONG'];
                        model['S_TOTAL'] = SUGEUM_DATA['TOTAL_SUM'];
                        model['S_YEAR'] = SUGEUM_DATA['YEAR_SUM'];

                        const MAECHUL_DATA = data2.filter(x => x.SUJU_SQ === o.SUJU_SQ).find(x => Number(x.BUSEOMYEONG_NM) === o.BONBU_ID);
                        for (let mae_month = 1; mae_month < 13; mae_month++) {
                            let mae_value = 0;
                            const monthKey = `MAECHUL_M${String(mae_month).padStart(2, '0')}`
                            if (MAECHUL_DATA != undefined) {
                                mae_value = MAECHUL_DATA[monthKey]
                            }
                            model[monthKey] = mae_value
                        }
                        if(MAECHUL_DATA!=undefined)
                        {
                            model['M_TOTAL'] = MAECHUL_DATA['TOTAL_SUM'];
                            model['M_YEAR'] = MAECHUL_DATA['YEAR_SUM'];                        
                        }
                        else
                        {
                            model['M_TOTAL'] = 0;
                            model['M_YEAR'] = 0;
                        }
                        this.datasource.push(model)
                        this.datasource = [...new Set(this.datasource)]
                    }
                }
            }
            await fnc('0', '_S0');
            await fnc('1', '_S1');
            for (const data of this.datasource)
                data.setMonthData();
            this.rowDataList = this.datasource.filter((e, i) => {
                return this.datasource.findIndex(x => x.SUJU_SQ == e.SUJU_SQ) === i;
            })
            this.rowFilterDataList = [...this.rowDataList];
            if (this.type == 'info') {
                await this.setInfoStore();
                await this.getBuseoBaebunList();
            }
            this.getFilterList();
        },

        async getBuseoBaebunList()
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','BUSEOBUNBAE',[{
                'BUNBAE_YEAR_NM' : this.year,
                'SANGTAE_NM' : '1'
            }]);
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('주관부서별 배분 리스트 호출',[res],this.logUse)
                this.buseoBaebunList = [...res._data];
                for(const o of this.buseoBaebunList.filter(x=>x.P_NO === 0))
                {
                    o.CHILD_BBUSEO_NAME = o.PARENTS_BUSEO_NAME;
                    o.CHILD_BBUSEO_COLOR = o.PARENTS_BUSEO_COLOR;
                    for(let i = 1; i<13; i++)
                    {
                        if(this.buseoBaebunTotal[`MONTH${i}_AM`] == undefined)
                        {
                            this.buseoBaebunTotal[`MONTH${i}_AM`] = 0;
                        }
                    }
                    for(let i = 1; i<13; i++)
                    {
                        this.buseoBaebunTotal[`MONTH${i}_AM`] += o[`MONTH${i}_AM`];
                    }
                }
            }
        },
        async setInfoStore()
        {
            const res = await RestHelper.RestSend('USP_BUSEO','LIST',[{
                STARTYEAR : '0',
                ENDYEAR : DataHelper.getDate(),
                USE_YN : 'Y'
            }]);
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('부서리스트 호출',[res],this.logUse)
                this.bonbuInfoList = [...res._data.filter(x=>x.DEPT_LEVEL == 4)];
                this.bonbuInfoList.push({BUSEOMYEONG_NM : '합계'})
                const sum = (s,bonbu,month)=>{
                    const targets = this.datasource.filter(x=>x.BUSEO_SQ == bonbu.BUSEO_SQ)
                    if(targets.length < 1) return 0;
                    let sum = 0;
                    const props = Object.keys(targets[0].BUNBAE_MONTH_CN_OBJ).filter(x=>x.includes(`S${s}`));
                    const prop = props.find(x=>x.includes(month.replace(/[^0-9]/g,"")));
                    for(const o of targets)
                    {
                        let value =o.BUNBAE_MONTH_CN_OBJ[prop];
                        if(value == null || value == 0)
                        {
                            value = 0;
                        }
                        sum += value;
                    }
                    return sum;
                }
                const totalMap = new Map();
                for(const col of this.infoCols)
                {
                    for(const row of this.infoRows)
                    {
                        totalMap.set(col+row,0);
                    }
                }

                this.infoStore = [];
                for(const bonbu of this.bonbuInfoList)
                {
                    const cellDt = {};
                    cellDt.bonbu = bonbu.BUSEOMYEONG_NM;
                    for(const col of this.infoCols)
                    {
                        cellDt.month = col;
                        let total = 0;
                        for(const row of this.infoRows)
                        {
                            cellDt.sales = row;
                            if(row == '기수금금액')
                            {
                                cellDt.amount = cellDt.bonbu == '합계' ? totalMap.get(col+row) : sum(1,bonbu,col);
                                
                            }
                            else if(row == '미수금금액')
                            {
                                cellDt.amount = cellDt.bonbu == '합계' ? totalMap.get(col+row) : sum(0,bonbu,col) - sum(1,bonbu,col);
                            }
                            else
                            {
                                cellDt.amount = total;
                            }
                            total += cellDt.amount;
                            totalMap.set(col+row,totalMap.get(col+row)+cellDt.amount); 
                            this.infoStore.push({...cellDt})
                        }
                    }
                }
                this.setInfosouce();
                LogHelper.logWrite('배분현황 리스트 생성',[this.infoStore],this.logUse)
                const filterList = this.infoStore.filter(x=>x.sales == '총수금금액');
                const changeList = [];
                for(const bonbu of this.bonbuInfoList)
                {
                    const newObj = {
                        "BUSEOMYEONG_NM" : bonbu.BUSEOMYEONG_NM,
                        "BUSEO_COLOR_NM" : bonbu.BUSEO_COLOR_NM,
                    }
                    if(newObj.BUSEOMYEONG_NM == 'PCM지원실') continue;
                    if(newObj.BUSEOMYEONG_NM == '품질안전실') continue;
                    const bonbuDataList = filterList.filter(x=>x.bonbu == bonbu.BUSEOMYEONG_NM);
                    for(const o of bonbuDataList)
                    {
                        const num = o.month.replace(/[^0-9]/g,'');
                        newObj[`month${num}`] = o.amount;
                    }
                    changeList.push(newObj);
                }
                this.infoDataSource = [...changeList];
            }
        },
        setInfosouce()
        {
            this.infosource = new PivotGridDataSource({
                fields:[
                    {
                        caption: '본부',
                        dataField: 'bonbu',
                        width: 120,
                        area: 'row',
                        expanded : true,
                    },
                    {
                        caption: '수금분류',
                        dataField: 'sales',
                        width: 100,
                        area: 'row',
                        expanded : true,
                        selector(data) {
                            return `${data.sales}`;
                        },
                    },
                    {
                        dataField: 'month',
                        dataType: 'string',
                        area: 'column',
                    },
                    {
                        caption: 'amount',
                        dataField: 'amount',
                        dataType: 'number',
                        summaryType: 'sum',
                        area: 'data',
                    },
                ],
                store: this.infoStore,
            });
            this.$nextTick(()=>{
                setTimeout(() => {
                    const bonbuCells = document.getElementsByClassName('dx-pivotgrid-expanded');
                    
                    for(const o of bonbuCells)
                    {
                        const name = o.childNodes[1].innerText;
                        const bonbuObj = this.bonbuInfoList.find(x=>x.BUSEOMYEONG_NM == name);
                        if(bonbuObj?.BUSEO_COLOR_NM)
                        {
                            o.style.background = '#'+bonbuObj.BUSEO_COLOR_NM;
                        }
                    }
                }, 100);
            });
        },
        async showPopup(SUJU_SQ,month)
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','INFO',[{
                'BUNBAE_YEAR_NM' : this.year,
                'SUJU_SQ' : SUJU_SQ,
                'BUNBAE_MONTH_NM' : month,
                'SANGTAE_NM' : '',
                'BUSEO_SQ' : '',
            }])
            if(res._result[0].RESULTCD == '1')
            {
                const model = this.datasource.find(x=>x.SUJU_SQ == SUJU_SQ);
                this.eidtBonbuList = [...res._data];
                for(const o of this.eidtBonbuList)
                {
                    o.BUNBAE_WON_AM1 = o.BUNBAE_WON_AM1 ?? 0;
                    o.BUNBAE_WON_AM2 = o.BUNBAE_WON_AM2 ?? 0;
                    o.SUGEUM_WON_AM1 = DataHelper.StringNullorEmpty(o.SUGEUM_WON_AM1) ? '0' : o.SUGEUM_WON_AM1;
                    o.SUGEUM_WON_AM2 = DataHelper.StringNullorEmpty(o.SUGEUM_WON_AM2) ? '0' : o.SUGEUM_WON_AM2;
                }
                this.formModel.PJ_NM = model.PJ_NM + ` (${month}월)`;
                this.formModel.SUGEUM_WON_AM1 = this.eidtBonbuList[0].SUGEUM_WON_AM1;
                this.formModel.SUGEUM_WON_AM2 = this.eidtBonbuList[0].SUGEUM_WON_AM2;
                this.formModel.SUGEUM_DATE_DT1 = DataHelper.SetDtData(this.eidtBonbuList[0].SUGEUM_DATE_DT1);
                this.formModel.SUGEUM_DATE_DT2 = DataHelper.SetDtData(this.eidtBonbuList[0].SUGEUM_DATE_DT2);
                this.formModel.BIGO = this.eidtBonbuList[0].BIGO;
                this.formModel.BUNBAE_MONTH_NM = month;

                this.editMonth = month;
                this.popupVisible = true;
            }
        },
        async onClickEditSave()
        {
            const params = []
            for(let bonbu of this.eidtBonbuList)
            {
                const data = new BunbaeModel();
                data.BIGO = this.formModel.BIGO;
                data.SUGEUM_WON_AM1 = this.formModel.SUGEUM_WON_AM1;
                data.SUGEUM_WON_AM2 = this.formModel.SUGEUM_WON_AM2;
                data.SUGEUM_DATE_DT1 = this.formModel.SUGEUM_DATE_DT1?.replace(/-/g,'');
                data.SUGEUM_DATE_DT2 = this.formModel.SUGEUM_DATE_DT2?.replace(/-/g,'');
                data.BUNBAE_WON_AM1 = bonbu.BUNBAE_WON_AM1;
                data.BUNBAE_WON_AM2 = bonbu.BUNBAE_WON_AM2;
                data.SUJU_SQ = bonbu.SUJU_SQ;
                data.BUNBAE_YEAR_NM = bonbu.SUJU_YEAR_CN;
                data.BUSEO_SQ = bonbu.BONBU_ID;
                data.BUNBAE_MONTH_CN_OBJ = null;
                data.BUNBAE_MONTH_NM = this.formModel.BUNBAE_MONTH_NM;
                params.push(data)
            }
            const res = await RestHelper.RestSend('USP_BUNBAE','UPDATE',params);
            LogHelper.logWrite(`배분 편집 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                await this.getData();
                this.popupVisible = false;
            }
        },
        async onClickSave()
        {
            const params = [];
            for(let data of this.datasource)
            {
                data.SANGTAE_NM = '0';
                data.BUNBAE_MONTH_CN_OBJ = null;
                params.push({
                    BUNBAE_YEAR_NM : data.BUNBAE_YEAR_NM,
                    SANGTAE_NM : data.SANGTAE_NM,
                    SUJU_SQ : data.SUJU_SQ,
                    BUSEO_SQ : data.BUSEO_SQ,
                    BUNBAE_MONTH_NM : data.BUNBAE_MONTH_NM,
                    BUNBAE_MONTH1_CN : data.BUNBAE_MONTH1_CN,
                    BUNBAE_MONTH2_CN : data.BUNBAE_MONTH2_CN,
                    BUNBAE_MONTH3_CN : data.BUNBAE_MONTH3_CN,
                    BUNBAE_MONTH4_CN : data.BUNBAE_MONTH4_CN,
                    BUNBAE_MONTH5_CN : data.BUNBAE_MONTH5_CN,
                    BUNBAE_MONTH6_CN : data.BUNBAE_MONTH6_CN,
                    BUNBAE_MONTH7_CN : data.BUNBAE_MONTH7_CN,
                    BUNBAE_MONTH8_CN : data.BUNBAE_MONTH8_CN,
                    BUNBAE_MONTH9_CN : data.BUNBAE_MONTH9_CN,
                    BUNBAE_MONTH10_CN : data.BUNBAE_MONTH10_CN,
                    BUNBAE_MONTH11_CN : data.BUNBAE_MONTH11_CN,
                    BUNBAE_MONTH12_CN : data.BUNBAE_MONTH12_CN,
                })
            }
            const res = await RestHelper.RestSend('USP_BUNBAE','INSERT',params);
            LogHelper.logWrite(`배분 저장 요청`,[res,params],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                alert('저장')
                await this.getData();
            }
        },
        onCellClick(e)
        {
            const hList = document.getElementsByClassName('highlight')
            for(const elem of hList)
            {
                elem.classList.remove('highlight');
            }
            const target = e.target;
            target.classList.add('highlight');
        },
        getFilterList()
        {
            let tempList = [...this.rowDataList];
            if(this.month != '전체')
            {
                tempList = [...tempList.filter(x=>{
                    const curr = new Date(`${this.year}-${this.month.toString().padStart(2,'0')}-31`)
                    const st = new Date(DataHelper.SetDtData(x.YONGYEOK_SIJAK_DT));
                    const en = new Date(DataHelper.SetDtData(x.YONGYEOK_MAGAM_DT));
                    let ret = curr >= st && curr <= en;
                    return ret;
                })]
            }
            if(this.selBonbu != '전체')
            {
                const targets = this.datasource.filter(x=>x.SUJU_SQ === x.SUJU_SQ).filter(x=>x.BONBU_NM == this.selBonbu)
                tempList = [...tempList.filter(x=>targets.find(o=>o.PJCODE_ID == x.PJCODE_ID))]
            }
            if(!DataHelper.StringNullorEmpty(this.keyword))
            {
                const t1 = tempList.filter(x=>x.PJ_NM.includes(this.keyword));
                const t2 = tempList.filter(x=>x.PJCODE_ID.includes(this.keyword));
                tempList = [...t1.concat(t2)];
            }
            this.rowFilterDataList = tempList;
        },
        infoRowSum(rowData)
        {
            let ret= 0;
            for(let i =1; i<13; i++){
                ret+=rowData[`month${i}`]
            }
            return ret;
        },
        getListOpen()
        {
            const targets = this.datasource.filter(x=>x.isOpen);
            if(targets.length > 0)
            {
                return true
            }
            return false
        },
        setOpenList()
        {
            if(this.getListOpen())
            {
                for(const row of this.datasource)
                {
                    row.isOpen = false;
                }
            }
            else
            {
                for(const row of this.datasource)
                {
                    row.isOpen = true;
                }
            }
        },
        // 전체합계 ~ 월 데이터 천 단위마다 ,
        formatNumberWithCommas(number) {
            number = Number(number)
            if (!isNaN(number)) {
                return number.toLocaleString();
            } else {
                return 0;
            }
        },
        getB_Total_Sum(SUJU_SQ)
        {
            const targets = this.datasource.filter(x=>x.SUJU_SQ == SUJU_SQ);
            let sum = 0;
            for(const o of targets)
            {
                sum += o.B_TOTAL;
            }
            return sum;
        },
        // 본부별 배분 엑셀 다운로드
        async onClickExcelDown(event) {
            const fileName = this.viewType.value;
            const workbook = new Workbook();
            let worksheet, exportFunction, refComponent;

            if (this.viewType.value === '본부별 배분') 
            {
                worksheet = workbook.addWorksheet('본부별 배분');
                exportFunction = this.exportDataGrid;
                refComponent = this.$refs.dataGrid.instance;
            } else if (this.viewType.value === '주관부서별 배분') 
            {
                worksheet = workbook.addWorksheet('주관부서별 배분');
                exportFunction = this.exportTreeList;
                refComponent = this.$refs.treeList.instance;
            }

            if (exportFunction && refComponent) 
            {
                await exportFunction({
                    component: refComponent,
                    worksheet: worksheet,
                    autoFilterEnabled: false,
                });

                // "합계" col이 있을 경우에만 totalCoumn...
                const headerRow = worksheet.getRow(1);
                let totalColumnIndex = -1;

                headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                    if (cell.value === '합계') 
                    {
                        totalColumnIndex = colNumber;
                    }
                });

                if (totalColumnIndex !== -1) 
                {
                    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
                        if (rowNumber === 1) return;

                        let total = 0;
                        for (let colIndex = 2; colIndex <= totalColumnIndex - 1; colIndex++) {
                            const cellValue = row.getCell(colIndex).value;
                            if (typeof cellValue === 'number') {
                                total += cellValue;
                            }
                        }

                        row.getCell(totalColumnIndex).value = total;
                        row.getCell(totalColumnIndex).numFmt = '#,##0'; 
                    });

                    worksheet.getColumn(totalColumnIndex).width = 17;
                }
                const buffer = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
            }
        },
        async exportDataGrid({ component, worksheet }) {
            await dxExportDataGrid({
                component: component,
                worksheet: worksheet,
            });

            worksheet.eachRow({ includeEmpty:true }, (row, rowNumber) => {
                row.eachCell({ includeEmpty:true }, (cell, colNumber ) => {
                    if(typeof cell.value === 'number') {
                        cell.numFmt = '#,##0';
                    }
                })
            })
            
        },

        // 주관부서별 배분 엑셀 다운로드 (treelist)
        async exportTreeList({ component, worksheet, autoFilterEnabled }) {
        try {
            const dataSource = component.getDataSource();
            if (!dataSource) {
                return;
            }

            const items = dataSource.items();
            console.log('Raw Items:', items);

            const flattenItems = (items) => {
                let result = [];
                items.forEach(item => {
                    result.push(item);
                    if (item.hasChildren && item.children && item.children.length > 0) {
                        result = result.concat(flattenItems(item.children));
                    }
                });
                return result;
            };

            const flattenedItems = flattenItems(items);

            const data = flattenedItems.map(item => ({
                '부서': item.data.CHILD_BBUSEO_NAME || 'N/A',
                '본부': item.data.PARENTS_BUSEO_NAME || 'N/A', // 본부 데이터 추가
                '1월': item.data.MONTH1_AM || 0,
                '2월': item.data.MONTH2_AM || 0,
                '3월': item.data.MONTH3_AM || 0,
                '4월': item.data.MONTH4_AM || 0,
                '5월': item.data.MONTH5_AM || 0,
                '6월': item.data.MONTH6_AM || 0,
                '7월': item.data.MONTH7_AM || 0,
                '8월': item.data.MONTH8_AM || 0,
                '9월': item.data.MONTH9_AM || 0,
                '10월': item.data.MONTH10_AM || 0,
                '11월': item.data.MONTH11_AM || 0,
                '12월': item.data.MONTH12_AM || 0,
            }));

            console.log('Prepared Data:', data);
            const columns = [
                { caption: '본부', key: '본부' }, // 본부 열
                { caption: '부서', key: '부서' },
                { caption: '1월', key: '1월' },
                { caption: '2월', key: '2월' },
                { caption: '3월', key: '3월' },
                { caption: '4월', key: '4월' },
                { caption: '5월', key: '5월' },
                { caption: '6월', key: '6월' },
                { caption: '7월', key: '7월' },
                { caption: '8월', key: '8월' },
                { caption: '9월', key: '9월' },
                { caption: '10월', key: '10월' },
                { caption: '11월', key: '11월' },
                { caption: '12월', key: '12월' },
            ];

            worksheet.addRow(columns.map(col => col.caption));

            let currentRowIndex = 2; 
            let currentGroup = null; 
            let firstDepartmentInGroup = true; 

            data.forEach((row, index) => {
                if (row['본부'] !== currentGroup) {
                    currentGroup = row['본부'];
                    firstDepartmentInGroup = true; 
                }

                const rowValues = [
                    firstDepartmentInGroup ? row['본부'] : '', 
                    firstDepartmentInGroup ? '' : row['부서'], 
                    ...columns.slice(2).map(col => row[col.key])
                ];
                const newRow = worksheet.addRow(rowValues);

                newRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                    if (typeof cell.value === 'number') {
                        cell.numFmt = '#,##0';
                    }
                });

                firstDepartmentInGroup = false;
                currentRowIndex++;
            });

            const totalsRow = worksheet.addRow([
                '합계',
                '', 
                ...columns.slice(2).map(col =>  {
                    const key = col.key;
                    return data
                        .filter((row, index, array) => {
                            const firstInGroup = array.find(item => item['본부'] === row['본부']);
                            return row === firstInGroup;
                        })
                        .reduce((sum, row) => sum + (typeof row[key] === 'number' ? row[key] : 0), 0);
                }) 
            ]);

            totalsRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                if (typeof cell.value === 'number') {
                    cell.numFmt = '#,##0';
                }
            });

            if (autoFilterEnabled) {
                worksheet.autoFilter = {
                    from: worksheet.getCell(1, 1),
                    to: worksheet.getCell(1, columns.length)
                };
            }

            const fixedWidth = 17;
            columns.forEach((col, index) => {
                worksheet.getColumn(index + 1).width = fixedWidth;
            });

            } catch (error) {
                console.error('본부별 배분 다운로드 에러:', error);
            }
        },
        async onClickShowAuto(row) {
            const res = await RestHelper.RestSend('USP_BUNBAE', 'AUTO', [{
                BUNBAE_YEAR_NM:row.BUNBAE_YEAR_NM,
                SANGTAE_NM:row.SANGTAE_NM,
                SUJU_SQ:row.SUJU_SQ,
            }]);
            if(res._result[0].RESULTCD == '1')
            {
                const data = [...res._data] 
                const bonbuList =  this.datasource.filter(x=>x.SUJU_SQ === row.SUJU_SQ)
                for(const o of bonbuList)
                {
                    const bonbuData = data.find(x => x.BONBU_ID == o.BUSEO_SQ);
                    o.BUNBAE_MONTH1_CN = bonbuData.BUNBAE_MONTH1_CN;
                    for(let month = 1; month <= 12; month++)
                    {
                        o[`BUNBAE_MONTH${month}_CN`] = bonbuData[`BUNBAE_MONTH${month}_CN`]
                    }
                }
            }
        }
    }
}
</script>

<style>
#dataGrid td{
    border: 1px solid #e0e0e0!important;
}
.target{
    background: #FFF3C9;
}
td:has(input.target)
{
    background: #FFF3C9;
}
td:has(input.target) *
{
    background: #FFF3C9;
}
.highlight{
    background: rgb(204, 217, 238) !important;
}
.highlight *{
    background: rgb(204, 217, 238) !important;
}
td:has(input.highlight)
{
    background: rgb(204, 217, 238) !important;
}
input{
    outline: none;
}
.dx-pivotgrid table,
.dx-pivotgrid tbody,
.dx-pivotgrid td,
.dx-pivotgrid tfoot,
.dx-pivotgrid th,
.dx-pivotgrid thead,
.dx-pivotgrid tr {
    width: 122px;
}
.dx-widge {
    width: 100%;
}
.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-expanded > span {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}
.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-collapsed,
.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-expanded {
    pointer-events: none;
}
.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-expanded {
    height: 112px;
    padding: 0 !important;
}
#BUNBAE_view .dx-filter-header,
#BUNBAE_view .dx-data-header,
#BUNBAE_view  .dx-column-header
 {
    display: none;
}
#BUNBAE_view .dx-pivotgrid-vertical-headers {
    height: 25px;
    padding: 0;
}
#BUNBAE_view .dx-last-cell {
    padding: 0;
    height: 25px;
    vertical-align: middle;
}

#BUNBAE_view .dx-scrollable-content tr td {
    height: 47px;
    padding: 0 12px;
    vertical-align: middle;
    text-align: right;
    border:1px solid #d9d9d9;
}
#BUNBAE_view .dx-texteditor.dx-editor-filled {
    background: #fff;
}
#BUNBAE_view .dx-texteditor-container {
    align-items: center;
}
#BUNBAE_view .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border:1px solid #d9d9d9 !important;
}
#BUNBAE_view .dx-tabpanel .dx-tabs {
    border:2px solid #e0e0e0;
}

#BUNBAE_view .p-highlight {
    background:#fff !important;
    color:#787878;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
#BUNBAE_view .p-selectbutton .p-button {
    background:#f8f8f8;
}
/* #BUNBAE_view {
    overflow-x: auto;
} */

.hidden {
    display:none !important
}
.visible {
    display: flex !important;
}

#BUNBAE_view .dx-datagrid .dx-gridbase-container .dx-datagrid-borders {
    border: 2px solid red;
    min-width:1700px
}
#BUNBAE_view .dx-treelist-headers .dx-treelist-table .dx-row > td {
    border: 1px solid #d9d9d9;
}
#BUNBAE_view > .dx-popup-content-scrollable {
    overflow:auto !important
}
#BUNBAE_view > .dx-popup-content {
    padding-bottom:0 !important;
}
#BUNBAE_textarea {
    resize:none
}

td> span> input{
    width: 80px;
}
</style>