<template>
<div class="w-auto h-full bg-white px-6 py-0 overflow-auto" id="INWON_view">
    <div v-if="type != 'info'" class="flex flex-row justify-between mt-[.8rem] w-full">
        <div class="flex flex-col justify-between w-full">
            <div class="flex flex-row mb-[1rem]">
                <select class="w-96 border-[1px] text-left pl-[1.2rem]  h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                    <option v-for="y in yearList" :key="y">{{ y }}</option>
                </select>
                <select class="w-96 border-[1px] text-left pl-[1.2rem] ml-8 h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="month">
                    <option>선택없음</option>
                    <option v-for="m in 12" :key="m">{{ m }}월</option>
                </select>
            </div>
            <div class="flex flex-row justify-between w-full">
                <div class="flex flex-row">
                    <div class="flex flex-row items-center">
                        <div class="w-[1rem] h-[1rem] border-[1px] border-black bg-[#FFF3C7] flex flex-row"></div>
                        <div class="select-none mx-[0.8rem] text-[1.2rem] tracking-tighter">현재인원</div>
                    </div>
                    <div class="flex flex-row items-center">
                        <div class="w-[1rem] h-[1rem] border-[1px] border-black bg-[#fff] flex flex-row"></div>
                        <div class="select-none mx-[0.8rem] text-[1.2rem] tracking-tighter">경영계획 인원</div>
                    </div>
                </div>
                <div class="flex flex-row mt-5 justify-end">
                    <div v-if="type!='info'" @click="onClickExcelDown" class="text-[#787878] text-[1.2rem] cursor-pointer mr-[2rem]">엑셀 다운로드</div>
                    <input ref="excelInput" class="hidden" type="file" @input="onInputExcelLoad" accept=".xlsx, .xlsm, .xltx, .xltm, .xls, .xlt">
                    <div v-if="type!='info'" @click="onClickExcelLoad" class="text-[#787878] text-[1.2rem] cursor-pointer">엑셀 불러오기</div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="type != 'info'" id="tableWraper" class="w-full mt-[1rem] h-full"> <!-- 240325 수정 -->
        <INWON_Table :datasource="datasource" :buseoMap="buseoMap" :bonbuList="bonbuList" :month="month" class="h-full"/> <!-- 240325 수정 -->
        <div class="flex flex-row justify-end mt-[1rem]">
            <div v-if="type != 'info'" class="flex flex-row justify-end">
                <div @click="onClickSave" class="w-[13rem] h-16 text-center bg-[#fff] text-[#006EDD] border-2 border-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">
                    저장
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import RestHelper from '../Helpers/RestHelper'
import LogHelper from '../Helpers/LogHelper'
import INWON_Table from '../Controls/INWON_Table'
import * as ExcelJS from 'exceljs';
import moment from 'moment'

export default {
    components :{
        INWON_Table,
    },
    computed:{
        tableCols()
        {
            return ['1월','31','2월','28','3월','31','4월','30','5월','31','6월','30'
                    ,'7월','31','8월','31','9월','30','10월','31','11월','30','12월','31']
        },
        tdHeight()
        {
            return 2;
        }
    },
    props:{
        type : String,
    },
    watch:{
        year()
        {
            this.getDatasource();
        }
    },
    data(){
        return{
            yearList : [],
            year : null,
            month : '1월',
            datasource : [],
            bonbuList : [],
            bonbuObjList : [],
            buseoObjList : [],
            buseoMap : new Map(),
            mmDatasource : [],
        }
    },
    async created(){
        await this.getYears();
    },
    async mounted(){
    },
    methods:{
        async onClickExcelDown()
        {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("sheet name");
            const header = ['부서','','1월','31','2월','28','3월','31','4월','30','5월','31','6월','30','7월','31','8월','31'
            ,'9월','30','10월','31','11월','30','12월','31',`${this.year}년 평균`,'','비고']
            const headerRow = sheet.addRow(header)
            headerRow.height = 20;
            headerRow.eachCell((cell,colNum)=>{
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffebebeb" },
                };
                cell.border = {
                    bottom: { style: "thin", color: { argb: "-100000f" } },
                    right: { style: "thin", color: { argb: "-100000f" } },
                };
                cell.font = {
                    name: "Arial",
                    size: 11,
                    bold: true,
                    color: { argb: "ff252525" },
                };
                sheet.getColumn(colNum).alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
                sheet.getColumn(colNum).width = 16;
            })
            sheet.mergeCells('AA1:AB1');
            const header2 = ['','','평균','월말','평균','월말','평균','월말','평균','월말','평균','월말','평균','월말','평균','월말','평균','월말'
            ,'평균','월말','평균','월말','평균','월말','평균','월말',`평균기준`,'월말기준','']
            const header2Row = sheet.addRow(header2)
            header2Row.height = 20;
            header2Row.eachCell((cell,colNum)=>{
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffebebeb" },
                };
                cell.border = {
                    bottom: { style: "thin", color: { argb: "-100000f" } },
                    right: { style: "thin", color: { argb: "-100000f" } },
                };
                cell.font = {
                    name: "Arial",
                    size: 11,
                    bold: true,
                    color: { argb: "ff252525" },
                };
            })
            sheet.mergeCells('A1:B2');
            sheet.mergeCells('AC1:AC2');

            const bonbus = [...new Set(this.datasource.map(x=>x.BONBUMYEONG_NM))];

            let rowStart = 3;
            let rowIndex = 3;
            for(const bonbu of bonbus)
            {
                const buseos = this.datasource.filter(x=>x.BONBUMYEONG_NM === bonbu);
                const buseoCnt = buseos.length;
                const totalData = ['소계','']
                const inputCols = []
                const averCols =[]
                const lastCols =[]
                for(let i = 67; i<91; i++)
                {
                    inputCols.push(String.fromCharCode(i));
                    if(i%2 != 0)
                    {
                        averCols.push(String.fromCharCode(i))
                    }
                    else
                    {
                        lastCols.push(String.fromCharCode(i))
                    }
                }

                rowIndex = rowStart;
                for(const buseo of buseos)
                {
                    const data = [bonbu,buseo.BUSEOMYEONG_NM]
                    for(let i = 1; i<13; i++){
                        data.push(buseo[`PYEONGGYUN${i}_AM`])
                        data.push(buseo[`WOLMAL${i}_AM`])
                    }

                    let averFnc = "AVERAGE("
                    for(const col of averCols)
                    {
                        averFnc += `${col}${rowIndex},`
                    }
                    averFnc = averFnc.slice(0, -1);
                    averFnc += ")"
                    data.push({ formula: averFnc})

                    averFnc = "AVERAGE("
                    for(const col of lastCols)
                    {
                        averFnc += `${col}${rowIndex},`
                    }
                    averFnc = averFnc.slice(0, -1);
                    averFnc += ")"
                    data.push({ formula: averFnc})
                    data.push("")
                    const addRowData = sheet.addRow(data)
                    addRowData.eachCell((cell,colNum)=>{
                        cell.border = {
                            bottom: { style: "thin", color: { argb: "-100000f" } },
                            right: { style: "thin", color: { argb: "-100000f" } },
                        };
                        if(colNum >2 && colNum < 27)
                        {
                            cell.protection = {
                                locked: false,
                                hidden: false,
                            };
                        }
                    })
                    rowIndex++;
                }
                
                for(const col of inputCols)
                {
                    totalData.push({ formula: `SUM(${col}${rowStart}:${col}${rowStart + buseoCnt - 1})`})
                }
                let averFnc = "AVERAGE("
                for(const col of averCols)
                {
                    averFnc += `${col}${rowIndex},`
                }
                averFnc = averFnc.slice(0, -1);
                averFnc += ")"
                totalData.push({ formula: averFnc})

                averFnc = "AVERAGE("
                for(const col of lastCols)
                {
                    averFnc += `${col}${rowIndex},`
                }
                averFnc = averFnc.slice(0, -1);
                averFnc += ")"
                totalData.push({ formula: averFnc})
                totalData.push("")
                
                rowStart += buseos.length+1;
                const addTotalRow = sheet.addRow(totalData)
                addTotalRow.eachCell((cell,colNum)=>{
                    cell.border = {
                        bottom: { style: "thin", color: { argb: "-100000f" } },
                        right: { style: "thin", color: { argb: "-100000f" } },
                    };
                })
                sheet.mergeCells(`A${rowIndex}:B${rowIndex}`);
            }
            await sheet.protect('the-password', { selectLockedCells : true});

            const fileData = await workbook.xlsx.writeBuffer();
            const blob = new Blob([fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",});
            const objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = objectUrl;
            link.download = `인원관리_${moment().format("YYYYMMDD")}.xlsx`;
            link.click();
        },
        onClickExcelLoad()
        {
            this.$refs.excelInput.value = ""
            this.$refs.excelInput.click();
        },
        onInputExcelLoad(e)
        {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = async (readerEvent) => {
                const fileContent = readerEvent.target.result;
                const workbook = new ExcelJS.Workbook();
                
                // 파일 데이터를 직접 사용하여 엑셀 워크북으로 읽기
                await workbook.xlsx.load(fileContent);
                const worksheet = workbook.getWorksheet(1);
                for(let i = 2; i<worksheet.rowCount+1; i++)
                {
                    const idx = worksheet.getCell('B'+i).value;
                    const data = this.datasource.find(x=>x.BUSEOMYEONG_NM === idx);
                    if(data!=undefined)
                    {
                        for (let cnt = 1; cnt < 25; cnt++)
                        {
                            const cell = String.fromCharCode(66 + cnt) + i;
                            const month = (cnt + 1) / 2;
                            const cellValue = worksheet.getCell(cell).value;
                            if(cnt % 2 != 0){
                                data[`PYEONGGYUN${month}_AM`] = cellValue
                            }
                            else
                            {
                                data[`WOLMAL${month}_AM`] = cellValue
                            }
                        }
                    }
                }
            }
            reader.readAsArrayBuffer(file);
        },
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
            LogHelper.logWrite(`인원입력 연도 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear();
                })
            }
        },
        async getDatasource()
        {
            const res = await RestHelper.RestSend('USP_INWON_IPRYEOK','LIST',[{ INWON_IPRYEOK_YEAR_CN : this.year }])
            LogHelper.logWrite(`인원입력 리스트 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                this.datasource = [];
                this.datasource = [...res._data];
                this.bonbuList = [...new Set(this.datasource.map(x=>x.BONBUMYEONG_NM))]
                this.buseoMap = new Map();
                for(const bonbu of this.bonbuList)
                {
                    this.buseoMap.set(bonbu,this.datasource.filter(x=>x.BONBUMYEONG_NM == bonbu))
                }
                for(const o of this.datasource)
                {
                    for(let i =1; i<13; i++)
                    { 
                        if(o[`PYEONGGYUN${i}_AM`] == null) o[`PYEONGGYUN${i}_AM`] = 0;
                        if(o[`WOLMAL${i}_AM`] == null) o[`WOLMAL${i}_AM`] = 0;
                    }
                } 

                const month = this.datasource[0]?.SANGTAE_NM;
                if(month)
                {
                    this.month = `${month}월`;
                }
                else
                {
                    this.month = '선택없음';
                }
                await this.getBuseo();
            }
        },
        async getBuseo()
        {
            const today = new Date();
            const todayYear = today.getFullYear();
            let props = [{}]
            if(Number(this.year) < Number(todayYear))
            {
                props = [{
                    STARTYEAR : todayYear+'1230',
                    ENDYEAR : todayYear+'1231'
                }]
            }
            const res = await RestHelper.RestSend('USP_BUSEO','LIST',props);
            LogHelper.logWrite('UR현황 부서 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data;
                this.bonbuObjList = [...data.filter(x=>x.DEPT_LEVEL == 4)];
                this.buseoObjList = [...data.filter(x=>x.DEPT_LEVEL == 5)];
                await this.getMMList();
            }
        },
        async getMMList()
        {
            const res = await RestHelper.RestSend('USP_MAECHUL','LIST',[{
                KEYWORD : '',
                PROJECT_SQ : '',
                INWON_SQ : '',
                MAECHUL_YEAR_CN : this.year
            }]);
            LogHelper.logWrite('UR현황 매출 리스트 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data;
                this.mmDatasource = [];
                for(const bonbu of this.bonbuObjList)
                {
                    const newBonbuData = {
                        BUSEOMYEONG_NM : bonbu.BUSEOMYEONG_NM,
                        BUSEO_SQ : bonbu.BUSEO_SQ,
                        Month1 : 0,
                        Month2 : 0,
                        Month3 : 0,
                        Month4 : 0,
                        Month5 : 0,
                        Month6 : 0,
                        Month7 : 0,
                        Month8 : 0,
                        Month9 : 0,
                        Month10 : 0,
                        Month11 : 0,
                        Month12 : 0,
                        average : 0,
                        Head_ID : 0,
                    }
                    this.mmDatasource.push(newBonbuData);
                    const bonbuBuseo = this.buseoObjList.filter(x=>x.BONBUMYEONG_NM == bonbu.BUSEOMYEONG_NM);
                    for(const buseo of bonbuBuseo)
                    {
                        const buseoData = data.filter(x=>x.BUSEOMYEONG_NM == buseo.BUSEOMYEONG_NM);
                        const newBuseoData = {
                            BUSEOMYEONG_NM : buseo.BUSEOMYEONG_NM,
                            BUSEO_SQ : buseo.BUSEO_SQ,
                            Month1 : 0,
                            Month2 : 0,
                            Month3 : 0,
                            Month4 : 0,
                            Month5 : 0,
                            Month6 : 0,
                            Month7 : 0,
                            Month8 : 0,
                            Month9 : 0,
                            Month10 : 0,
                            Month11 : 0,
                            Month12 : 0,
                            average : 0,
                            Head_ID : bonbu.BUSEO_SQ,
                        }
                        for(const dt of buseoData)
                        {
                            for(let month=1; month<13; month++)
                            {
                                const value = Number(dt[`MAECHUL_MONTH${month}_CN`]);
                                if(!isNaN(value))
                                {
                                    newBuseoData[`Month${month}`] += value;
                                    newBuseoData.average += value;
                                    newBonbuData[`Month${month}`] += value;
                                    newBonbuData.average += value;
                                }
                            }
                        }
                        this.mmDatasource.push(newBuseoData);
                        newBuseoData.average = newBuseoData.average/12;
                    }
                    newBonbuData.average = newBonbuData.average/12;
                }
            }
        },
        async onClickSave()
        {
            for(const o of this.datasource)
            {
                o.SANGTAE_NM = this.month.replace(/[^0-9]/g,"");
            }
            const res = await RestHelper.RestSend('USP_INWON_IPRYEOK','INSERT',this.datasource);
            LogHelper.logWrite(`인원입력 리스트 저장 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                alert('저장');
            }
        }
    }
}
</script>

<style>
#INWON_view .dx-widget {
    height: max-content;
}
#INWON_view .dx-widget input {
    display:flex !important;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 !important;
    margin: 0 auto !important;
}
#INWON_view .dx-multiview-wrapper {
    overflow: visible;
    width: 100%;
    height:max-content;
    -ms-touch-action: pinch-zoom pan-y;
    touch-action: pinch-zoom pan-y;
}
#textColor:nth-child(3),
#textColor:nth-child(5),
#textColor:nth-child(7),
#textColor:nth-child(9),
#textColor:nth-child(11),
#textColor:nth-child(13),
#textColor:nth-child(15),
#textColor:nth-child(17),
#textColor:nth-child(19),
#textColor:nth-child(21),
#textColor:nth-child(23),
#textColor:nth-child(25) {
    color:#bebebe;
}

input[type=number]::-webkit-outer-spin-button{-webkit-appearance: none;margin: 0;}
input[type=number]::-webkit-inner-spin-button{-webkit-appearance: none;margin: 0;}
input[type=text]::-webkit-outer-spin-button{-webkit-appearance: none;margin: 0;}
input[type=text]::-webkit-inner-spin-button{-webkit-appearance: none;margin: 0;}

#INWON_view .dx-treelist-headers .dx-treelist-table .dx-row > td {
    padding: 0;
    height: 47px;
    background: #fafafa;
    font-size: 1.2rem;
    color:#2b3133;
    border: 1px solid #d9d9d9;
}
#INWON_view .dx-treelist .dx-row > td {
    padding: 0;
    height: 47px;
    font-size: 1.2rem;
    color:#2b3133;
    border: 1px solid #d9d9d9;
    padding: 14px 6px;
}
#INWON_view .dx-treelist-rowsview .dx-treelist-collapsed span::before,
#INWON_view .dx-treelist-rowsview .dx-treelist-expanded span::before {
    margin-left: 8px;
}
#INWON_view .dx-treelist-headers .dx-treelist-table .dx-row > td:first-child {
    text-align: center !important;
}
#INWON_view > .dx-treelist-cell-expandable > .dx-treelist-icon-container,
#INWON_view  > .dx-treelist-rowsview > .dx-treelist-empty-space,
#INWON_view > .dx-treelist-rowsview > .dx-treelist-table-fixed > .dx-treelist-icon-container{
    display: none;
}
#INWON_view .dx-treelist-rowsview.dx-treelist-nowrap .dx-treelist-table .dx-treelist-cell-expandable .dx-treelist-text-content {
    padding: 0;
}
#INWON_view .dx-treelist-rowsview .dx-treelist-empty-space {
    left: -8px;
}
</style>