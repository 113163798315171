<template>
    <div class="w-full h-full px-[1rem] py-[1rem] bg-white overflow-auto" id="MAECHUL_view">
        <!-- <div class="h-8"></div> -->
        <div v-if="type!='info'" class="flex flex-row justify-between items-center w-full mb-[1rem]">
        <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">{{ type=='info' ? '매출현황' : '매출관리' }}</p>
        <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">저장</div>
        <!-- 240325 위치 이동 -->
        <!-- <div @click="onClickSave" class="w-[13rem] h-[4.5rem] text-center text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">저장</div> -->
        </div>
        <div class="h-4 w-full"></div>
        <SelectButton v-if="type=='info'" v-model="tabType" :options="[{ value: '실적현황' },{ value: '본부별 매출' },{ value: 'PJ별 매출' },{ value: '매출/수금 비교' }]" class="flex text-center justify-around items-center w-[54.8rem] h-[4rem] bg-[#f8f8f8] rounded-lg p-[4px]" optionLabel="value" dataKey="value" aria-labelledby="custom">
            <template #option="slotProps">
                <p class="cursor-pointer select-none text-[1.1rem] rounded-lg w-[13.2rem] text-center py-[0.9rem] h-[3.2rem]">{{ slotProps.option.value }}</p>
            </template>
        </SelectButton>
        <div v-if="type=='info'" class="flex flex-row items-center justify-between border-b-2 mb-[1.6rem]">
            <div class="flex flex-row items-center">
                <p class="mr-[1rem] text-[#92959B] text-[1.3rem]">조회연도</p>
                <select class="w-[14rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] m-[1rem] rounded-lg border-[#C7C7C7]" v-model="year">
                    <option v-for="y in yearList" :key="y">{{ y }}</option>
                </select>
                <div class="flex flex-row items-center" v-if="tabType.value == 'PJ별 매출'">
                    <input type="text" v-model="keyword" placeholder="PJ Code, PJ명" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[50rem]">
                    <div @click="searchProjData" class="flex flex-row items-center justify-center ml-[2rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem]">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <p class="text-white">검색</p>
                    </div>
                </div>
            </div>
            <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
        <div class="mb-[1.6rem] w-full"></div>
        <div v-if="type!='info'" class="w-full flex flex-row items-center justify-center ">
            <div class="flex flex-row items-center justify-between px-4 py-[1.2rem] rounded-lg bg-[#F3F4F5] w-full">
                 <div class="flex flex-row items-center w-[60%]">
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem] ">
                             프로젝트 코드
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold w-[4rem] mr-[2rem]">
                             {{ selProj.PJCODE_ID }}
                         </div>
                     </div>
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem]">
                             PJ명
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold mr-[2rem]">
                             {{ selProj?.PJ_NM }}
                         </div>
                     </div>
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem]">
                             주관부서
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold mr-[2rem]">
                             {{ selProj.BUSEOMYEONG_NM }}
                         </div>
                     </div>
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem]]">
                             신규/변경
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold mr-[2rem]">
                             {{ selProj.GITA_CD }}
                         </div>
                     </div>
                 </div>
                <div class="flex flex-row items-center w-auto">
                    <div class="flex flex-row items-center">
                        <input type="text" v-model="keyword" placeholder="PJ코드, PJ명 검색" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[25rem]">
                        <div @click="projectSearch.show" class="flex flex-row items-center justify-center ml-[1rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                            <p class="text-white">검색</p>
                        </div>
                    </div>
                    <InputSwitch v-model="searchSwich" class="text-[1.4rem] ml-[2rem] mr-[1rem]" />
                    <p class="text-[1rem] font-semibold text-[#636363]">종료 프로젝트 검색</p>
                </div>
            </div>
        </div>
        <!-- <div class="h-[1.3rem]"></div> -->
        <!-- 240329 height 수치 수정 -->
        <div v-if="type!='info'" class="flex flex-row h-[calc(100%-14rem)]">
            <!-- 240329 height 수치 수정 -->
            <div class="flex flex-col h-full w-full">
                <div class="flex flex-row justify-between items-center text-lg mb-[0.1rem]">
                    <div class="text-[1.3rem] text-[#c7c7c7] tracking-tighter">* 아래 표에서 매출을 입력하고 저장버튼을 클릭하세요.</div>
                    <div class="flex flex-row gap-[1rem]">
                        <p @click="historySidebar.open()" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg px-[2rem] h-[2.6rem] py-0">변경 조회</p>
                        <p @click="()=>{popupVisible = true}" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg px-[2rem] h-[2.6rem] py-0">일괄입력</p>
                        <div @click="init" class="select-none cursor-pointer w-auto h-[2.95rem] border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100 flex">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div>
                    </div>
                </div>
                <!-- 240329 height 수치 조정 -->
                <div class="w-full h-[calc(100%-3rem)] flex flex-row mt-[1rem]">
                    <div class="w-full">
                        <DxDataGrid id="dataGrid" ref="dataGrid" height="100%" :data-source="dataSource" key-expr="index" :show-borders="true" class="MAECHUL_TABLE border-r-0"
                        @cellClick="onCellClick($event)" @cell-prepared="onMainCellPrepared"
                        @cell-hover-changed="onCellHoverChanged($event)">
                        <DxPaging :enabled="false"/>
                        <DxSelection mode="single"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxScrolling mode="'virtual" useNative="true" scrollbarmode="always"/> 
                        <DxLoadPanel :enabled="true"/>
                        <DxEditing startEditAction="dblClick" mode="cell" :allow-updating="true" :allow-adding="false" :allow-deleting="false"></DxEditing>
                        <DxKeyboardNavigation
                            :edit-on-key-press="true"
                            enter-key-action="column"
                            enter-key-direction="moveFocus"
                        />
                        <DxColumn caption="분류" fixed="true" :allowEditing='false' width="10rem" data-field="BUNRYU_NM" alignment="center" class="height"/>
                        <DxColumn caption="성명" fixed="true" :allowEditing='false' width="10rem"  data-field="SAWON_MYEONG_NM" alignment="center"/>
                        <DxColumn caption="소속부서" fixed="true" :allowEditing='false' width="10rem"  data-field="BUSEOMYEONG_NM" alignment="center"/>
                        <DxColumn caption="월기성" fixed="true" format="###,##0" width="10rem" data-field="WOLGISEONG_AM" alignment="right"/>
                        <DxColumn caption="투입개월" fixed="true" format="#0.0##" width="10rem"  data-field="TUIP_GAEWOL_CN" alignment="right"/>
                        <DxColumn caption="잔여MM" fixed="true" :allowEditing='false' format="#0.0##" width="10rem"  data-field="" :calculate-cell-value="calculateSalesAmount" alignment="right"/>
                        <DxColumn data-type="date" fixed="true" caption="투입일" format="yy-MM-dd" :allowEditing='true' width="10rem"  data-field="TUIP_Il_CN" alignment="center"/>
                        <DxColumn alignment="center" :caption="year" v-for="(year,index) in yearList" :key="index">
                            <DxColumn v-for="(index, monthIndex) in 12" :key="monthIndex" :caption="`${index}월`" :format="'#0.0##'" width="calc(53.5vw/12)" :data-field="`MAECHUL_MONTH${index}_CN_${year}`" alignment="right" :editing-params="{ allowEditing: true }" @value-changed="onCellValueChanged"/>
                        </DxColumn>
                        <DxSummary v-if="dataSource.length > 0">
                            <template v-for="(year,index) in yearList" :key="index">
                                <DxTotalItem v-for="(index, monthIndex) in 12" :key="monthIndex" display-format="{0}" value-format="###,##0" :column="`MAECHUL_MONTH${index}_CN_${year}`" summary-type="sum"/>
                            </template>
                        </DxSummary>  
                    </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full pb-[2rem] overflow-auto ">
            <div v-if=" type=='info' && tabType.value === '실적현황'" class="flex flex-row gap-[2.6rem] w-full h-auto min-w-[1700px] overflow-auto">
                <div class="flex flex-col h-[40rem] w-[70%] border-2 rounded-xl">
                <div class="flex flex-col gap-[1rem] h-[20rem] w-full p-[2rem]">
                    <p class="text-[1.2rem] text-[#ADAEB1]"><b class="text-[#2B3133] text-[1.6rem]">매출확보</b> (단위:억원)</p>
                    <div class="flex flex-row gap-[2rem] h-full">
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#EAEAEA] w-[21rem]">
                            <p class="text-[1.6rem]">합계</p>
                            <p class="font-semibold text-[2rem]">{{ getInfoBoradText('합계','m') }}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#E9F3FA] w-[21rem]">
                            <p class="text-[1.6rem]">CM본부</p>
                            <p class="font-semibold text-[2rem]">{{ getInfoBoradText('CM본부','m') }}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#F9E8E7] w-[21rem]">
                            <p class="text-[1.6rem]">PM본부</p>
                            <p class="font-semibold text-[2rem]">{{ getInfoBoradText('PM본부','m') }}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#FAF2E1] w-[21rem]">
                            <p class="text-[1.6rem]">ENG본부</p>
                            <p class="font-semibold text-[2rem]">{{ getInfoBoradText('엔지니어링본부','m') }}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#E8F1E3] w-[21rem]">
                            <p class="text-[1.6rem]">전략사업본부</p>
                            <p class="font-semibold text-[2rem]">{{ getInfoBoradText('전략사업본부','m') }}억</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-[1rem] h-[20rem] w-full p-[2rem]">
                    <p class="text-[1.2rem] text-[#ADAEB1]"><b class="text-[#2B3133] text-[1.6rem]">수금확보</b> (단위:억원)</p>
                    <div class="flex flex-row gap-[2rem] h-full">
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#EAEAEA] w-[21rem]">
                            <p class="text-[1.6rem]">합계</p>
                            <p class="font-semibold text-[2rem]">{{getInfoBoradText('합계','s')}}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#E9F3FA] w-[21rem]">
                            <p class="text-[1.6rem]">CM본부</p>
                            <p class="font-semibold text-[2rem]">{{getInfoBoradText('CM본부','s')}}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#F9E8E7] w-[21rem]">
                            <p class="text-[1.6rem]">PM본부</p>
                            <p class="font-semibold text-[2rem]">{{getInfoBoradText('PM본부','s')}}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#FAF2E1] w-[21rem]">
                            <p class="text-[1.6rem]">ENG본부</p>
                            <p class="font-semibold text-[2rem]">{{getInfoBoradText('ENG본부','s')}}억</p>
                        </div>
                        <div class="flex flex-col items-center justify-center gap-[1rem] rounded-2xl bg-[#E8F1E3] w-[21rem]">
                            <p class="text-[1.6rem]">전략사업본부</p>
                            <p class="font-semibold text-[2rem]">{{getInfoBoradText('전략사업본부','s')}}억</p>
                        </div>
                    </div>
                </div>
                </div>
                <MAECHUL_SG_Chart class="w-[48rem]" :year="year" />
            </div>
        </div>
        <!-- <DxDataGrid ref="bonbuGrid" v-if=" type=='info' && tabType.value === '본부별 매출'" key-expr="BUSEO_SQ" :data-source="infoBonbuDatasource">
            <DxColumn caption="부서명" data-field="BONBUMYEONG_NM" alignment="center"></DxColumn>
            <DxColumn format="###,##0" v-for="month in 12" :key="month" :caption="`${month}월`" :data-field="`MAECHUL_MONTH${month}_CN`"></DxColumn>
            <DxColumn format="###,##0" caption="합계" data-field="total"></DxColumn>
        </DxDataGrid> -->
        <div v-if=" type=='info' && tabType.value === '본부별 매출'" class="w-full h-[calc(100%-13rem)]">
            <DxTreeList height="90%" :data-source="infoBonbuDatasource" parent-id-expr="P_NO" key-expr="NO" class="border-2" :show-borders="true">
                <TreeColumn
                    data-field="CHILD_BBUSEO_NAME"
                    caption="부서"
                    cell-template="buseoCell"
                    alignment="center"
                    width="10%"
                />
                <TreeColumn v-for="month in 12" :key="month"
                    :data-field="`MONTH${month}_AM`"
                    :caption="`${month}월`"
                    alignment="right"
                    format="###,###,###,##0"
                />
                <template #buseoCell="{data : rowInfo}">
                    <div class="flex flex-row items-center justify-start w-full">
                        <div :style="{ background: '#'+rowInfo.data.CHILD_BBUSEO_COLOR }" :class="`w-[0.8rem] h-[1.4rem] rounded-lg mr-[0.4rem]`"></div>
                        <p class="overflow-hidden text-ellipsis">{{ rowInfo.data.CHILD_BBUSEO_NAME }}</p>
                    </div>
                </template>
            </DxTreeList>
            <div class="flex flex-row w-full h-[6rem] border-2">
                <div class="h-full w-[10.35%] border-r-[1px]"></div>
                <div v-for="month in 12" :key="month" class="flex items-center justify-center h-full w-[7.7%] border-r-[1px] text-center">{{ setUnit(infoBonbuDataMap[`MONTH${month}_AM`]) }}</div>
            </div>
        </div>
        <div v-if=" type=='info' && tabType.value === '매출/수금 비교'" class="h-[calc(100%-16rem)] w-full ">
            <DxDataGrid :show-borders="true" :data-source="msArr" height="100%">
                <template #headTemplate="{ data: rowInfo }">
                    <div class="flex flex-row ml-2 w-full">
                        <div class="rounded-lg w-[5px] h-[16px] mr-[1rem]" :style="{background : `#${rowInfo.data.BUSEO_COLOR_NM}`}"></div>
                        <div>{{ rowInfo.data.BUSEOMYEONG_NM }}</div>
                    </div>
                </template>
                <DxPaging :enabled="false"/>
                <DxSelection mode="single"/>

                <DxColumn cell-Template="headTemplate" caption="부서" width="8%" alignment="center" class="border-[1px] border-[#d9d9d9]"/>
                <DxColumn caption="PJ코드" width="90px" data-field="PJCODE_ID" alignment="center" class="border-[1px] border-[#d9d9d9]"/>
                <DxColumn caption="PJ명" width="60%" data-field="PROJECT_NM" alignment="left" class="border-[1px] border-[#d9d9d9]"/>
                <DxColumn caption="매출" width="12%" format="###,###,###,###,###,##0" data-field="MAECHUL" alignment="right" class="border-[1px] border-[#d9d9d9]"/>
                <DxColumn caption="수금" width="12%" format="###,###,###,###,###,##0" data-field="S_WON" alignment="right" class="border-[1px] border-[#d9d9d9]"/>
                <DxColumn caption="차이(수금 - 매출)" format="###,###,###,###,###,##0" data-field="RESULT" width="10%" alignment="right" class="border-[1px] border-[#d9d9d9]"/>
            </DxDataGrid>
        </div>
        <DxDataGrid ref="projGrid" @content-ready="projGridReady" v-if="type=='info' && tabType.value === 'PJ별 매출'" :data-source="infoProjDatasource" @cell-prepared="onCellPrepared"  class="w-full h-[calc(100%-16rem)] select-none ">
            <DxPaging :enabled="false"/>
            <GridSorting mode="none"/>
            <DxHeaderFilter :enabled="false"/>
            <DxColumn caption="부서" cell-template="buseoCell" data-field="BONBUMYEONG_NM" width="auto"></DxColumn>
            <DxColumn caption="PJ Code" data-field="PJCODE_ID" alignment="center" width="auto"></DxColumn>
            <DxColumn caption="PJ명" data-field="PJ_NM" width="170px"></DxColumn>
            <DxColumn caption="분야" data-field="BUNRYU_NM" width="100px"></DxColumn>
            <DxColumn caption="근무형태" data-field="HYEONGTAE_NM" width="85px"></DxColumn>
            <DxColumn caption="성명" data-field="SAWON_MYEONG_NM" alignment="center" width="auto"></DxColumn>
            <DxColumn caption="월기성" format="###,###,###,###,###,###" data-field="WOLGISEONG_AM" alignment="right" width="auto"></DxColumn>
            <DxColumn caption="2023" header-cell-template="headerTemplate">
                <DxColumn v-for="month in 12" :key="month" :caption="`${month}월`" :data-field="`MAECHUL_MONTH${month}_${infoProjSelType}`" alignment="right" width="100px" class="Col"></DxColumn>
                <!-- <template v-if="infoProjSelType === 'Cost'">
                    <DxColumn v-for="month in 12" :key="month" :caption="`${month}월`" :data-field="`MAECHUL_MONTH${month}_CN`"></DxColumn>
                </template> -->
            </DxColumn>
            <template #buseoCell="{data : rowInfo}">
                <div class="flex flex-row items-center justify-start overflow-hidden w-full">
                    <div :style="{ background: '#'+rowInfo.data.BUSEO_COLOR_NM }" :class="`w-[0.8rem] h-[1.4rem] rounded-lg mr-[0.4rem]`"></div>
                    <p class="overflow-hidden text-ellipsis">{{ rowInfo.data.BONBUMYEONG_NM }}</p>
                </div>
            </template>
            <template #headerTemplate>
                <div class="w-auto flex flex-row justify-center">
                    <div class="flex flex-row justify-center w-auto">
                        <ul class="flex flex-row w-full">
                            <li>
                                <input id="selectMM" name="colType" v-model="infoProjSelType" value="CN" type="radio" class="hidden peer/mm" required/>
                                <label for="selectMM" class="inline-flex items-center justify-center w-[30rem] py-2 px-5 text-center
                                text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer
                                peer-checked/mm:border-blue-600 peer-checked/mm:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                                    <div class="block">
                                        <div class="w-full text-lg font-semibold">MM</div>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <input id="selectCost" name="colType" v-model="infoProjSelType" value="COST" type="radio" class="hidden peer" required/>
                                <label for="selectCost" class="inline-flex items-center justify-center w-[30rem] py-2 px-5 text-center
                                text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer 
                                peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                                    <div class="block">
                                        <div class="w-full text-lg font-semibold">매출</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </DxDataGrid>
        <DxPopup title="일괄입력" :visible="popupVisible" width="480" height="450" class="font-bold">
            <div class="flex flex-col mb-[2.4rem]">
                <p class="font-semibold w-[4rem] leading-[2.4rem] text-[1.2rem]">대상</p>
                <input class="border-[1px] bg-slate-100 mr-4 text-[1.2rem] rounded-lg w-[20rem] h-[3rem] pl-[.8rem]" type="text" v-model="selData.SAWON_MYEONG_NM" readonly/>
            </div>
            <div class="flex flex-col mb-[2.4rem]">                    
                <p class="font-semibold w-[4rem] leading-[2.4rem] text-xl">기간</p>
                <div class="w-full flex flex-row justify-between">
                    <input ref="allEditStDt" :min="calYear(selProj.YONGYEOK_SIJAK_DT)" :max="calYear(selProj.YONGYEOK_MAGAM_DT,false)" class="border-[1px] rounded-lg w-1/2 text-center pr-[.8rem] h-[3rem] text-[#818181]" type="month"/>
                    <p class="font-semibold leading-[2.8rem] text-2xl px-[.8rem] text-[#818181]">~</p>
                    <input ref="allEditEnDt" :min="calYear(selProj.YONGYEOK_SIJAK_DT)" :max="calYear(selProj.YONGYEOK_MAGAM_DT,false)" class="border-[1px] rounded-lg w-1/2 text-center  pr-[.8rem] text-[#818181]" type="month"/>
                </div>

            </div>
            <div class="flex flex-row mb-[1.6rem]">
                <div class="w-1/2 pr-[.8rem]">
                    <p class="font-semibold w-[4rem] leading-[2.4rem] text-xl">간격</p>
                    <input ref="allEditGap" value="1" class="text-[1.2rem] border-[1px] mr-[2.4rem] leading-[2.6rem] rounded-lg w-full h-[3rem] pl-[.8rem]" type="number">
                </div>
                
                <div class="w-1/2 pl-[1.6rem]">
                    <p class="font-semibold w-[4rem] leading-[2.4rem] text-xl">값</p>
                    <input ref="allEditValue" class="text-[1.2rem] border-[1px] mr-2 leading-[2.6rem] rounded-lg w-full h-[3rem] pl-[.8rem]" type="number"/>
                </div>
            </div>
            <div class="flex flex-row mb-[1.6rem]">

            </div>
            <div class="flex flex-row justify-end absolute bottom-[2.4rem] right-[2.4rem]">
                <div @click="onClickAllEdit" class="text-[1.2rem] bg-[#006EDD] cursor-pointer rounded-lg border-[#c7c7c7] text-[#ffffff] px-[2.4rem] py-[1rem] tracking-tighter w-[10rem] text-center hover:bg-slate-300">입력</div>
                <div @click="()=>{popupVisible = false}" class="ml-[1.6rem] text-[1.2rem] bg-[#ffffff] border-[1px] cursor-pointer rounded-lg text-[#818181] py-[1rem] tracking-tighter w-[10rem] text-center hover:bg-slate-300">닫기</div>
            </div>
        </DxPopup>
        <ProjectSearchPopup ref="projectSearch" :search="keyword" :callBack="searchCallback">

        </ProjectSearchPopup>
        <HistorySidebar ref="historySidebar" menuName="C0060"/>
    </div>
</template>
    
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxHeaderFilter,
  DxScrolling,
  DxSelection,
  DxSorting as GridSorting,
  DxLoadPanel,DxSummary,DxTotalItem,
  DxKeyboardNavigation
  } from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import DxTabPanel from 'devextreme-vue/tab-panel';
import RestHelper from '../Helpers/RestHelper';
import DataHelper from '../Helpers/DataHelper';
import LogHelper from '../Helpers/LogHelper';
import ClipboardHelper from '../Helpers/ClipboardHelper';
import { DxSelectBox } from 'devextreme-vue/select-box';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import ProjectSearchPopup from '../Controls/ProjectSearchPopup.vue'
import sf from 'sf'
import {
  DxTreeList,DxSorting, DxColumn as TreeColumn, DxLookup
} from 'devextreme-vue/tree-list';
import MAECHUL_SG_Chart from './Charts/MAECHUL_SG_Chart.vue';
import HistorySidebar from '../Controls/HistorySidebar.vue';
import moment from 'moment';

export default {
    components :{
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxHeaderFilter,
        DxScrolling,GridSorting,
        DxSelection,HistorySidebar,
        DxLoadPanel,TreeColumn, DxLookup,
        DxTabPanel,SelectButton,
        DxKeyboardNavigation,
        DxSummary,DxTotalItem,
        DxSelectBox,ProjectSearchPopup,MAECHUL_SG_Chart,
        DxPopup,InputSwitch,DxTreeList, DxSorting
    },
    props:{
        type : String,
    },
    computed:{
        dataGrid()
        {
            return this.$refs.dataGrid.instance;
        },
        bonbuGrid()
        {
            return this.$refs.bonbuGrid?.instance;
        },
        infoCols()
        {
            const ret = [];
            for(let i = 1; i<13; i++)
                ret.push(`${i<10?' '+i : i}월`)
            ret.push('합계')
            return ret;
        },
        infoRows()
        {
            return ['  매출',' M/M',' 매출(외주포함)','M/M(외주포함)',];
        },
        projectSearch()
        {
            return this.$refs.projectSearch;
        },
        historySidebar()
        {
            return this.$refs.historySidebar;
        }
    },
    data(){
        return{
            keyword : '',
            searchText : '',
            dataSource : [],
            projList : [],
            selProj : {},
            yearList : [],
            year : null,
            yearIndex : 0,
            allDataList : [],
            selData : {},
            infoList : [],
            infosBonbu : [],
            infoBonbuDatasource : [],
            infoBonbuDataMap : new Map(),
            infoProjDatasource : [],
            infoProjSelType : 'CN',
            selInfo : "",
            doughnutSource : [],
            popupVisible : false,
            tabType : { value : '실적현황' },
            boardArr : [],
            cellMergeMap : new Map(),
            msArr : [],
            tempTUIP : null,
            scrollArr : [],
        }
    },
    watch:{
        yearIndex(n,b)
        {
            this.$nextTick(()=>{
                if(n<0)
                {
                    this.yearIndex = 0;
                }
                if(n > this.yearList.length - 1)
                {
                    this.yearIndex = b;
                }
                this.dataSource = [...this.allDataList.filter(x=>x.MAECHUL_YEAR_CN == this.yearList[this.yearIndex])];
            });
        },
        year()
        {
            if(this.type == 'info')
            {
                this.getInfos();
            }
            else
            {
                this.onClickSearch();
            }
        },
        selProj()
        {
            if(!this.selProj)
            {
                this.selProj={};
                this.selProj.PJ_NM="";
                return;
            }
            this.updateProj();
        },
        infoProjSelType()
        {
            this.$refs.projGrid?.instance.refresh();
        },
        tabType(n,o)
        {
            if(n == null)
            {
                this.tabType = o;
            }
        }
    },
    async mounted() {
        await this.getYears();
        // this.refreshDataSource();
    },
    methods:{
        async init()
        {
            this.year = null;
            await this.getYears();
        },
        onSubCellPrepared(e)
        {
            const data = e.data;
            if(data?.INDANGDANGA_SQ != null)
            {
                const targets = this.allDataList.filter(x=>x.INDANGDANGA_SQ == data.INDANGDANGA_SQ);
                for(const t of targets)
                {
                    for(let month = 1; month<13; month++)
                    {
                        t[`MAECHUL_MONTH${month}_CN`] = data[`MAECHUL_MONTH${month}_CN_${t.MAECHUL_YEAR_CN}`]
                    }
                }
            }
        },
        onMainCellPrepared(e)
        {
            const data = e.data;
            if(!DataHelper.StringNullorEmpty(data?.TUIP_Il_CN)&&e.column.dataField == 'TUIP_Il_CN' && this.tempTUIP != data.TUIP_Il_CN)
            {
                this.tempTUIP = data.TUIP_Il_CN;
                const sangju = this.allDataList.filter(x=>Number(x.MAECHUL_YEAR_CN) == this.allDataList[0].MAECHUL_YEAR_CN).filter(x=>x.HYEONGTAE_NM == '상주').map(x=>x.TUIP_GAEWOL_CN).reduce((curr,next)=>{return curr+next;},0);
                const besangju = this.allDataList.filter(x=>Number(x.MAECHUL_YEAR_CN) == this.allDataList[0].MAECHUL_YEAR_CN).filter(x=>x.HYEONGTAE_NM == '비상주').map(x=>x.TUIP_GAEWOL_CN).reduce((curr,next)=>{return curr+next;},0);
                const monthUR =  besangju/sangju;
                
                const targets = this.allDataList.filter(x=>x.INDANGDANGA_SQ == data.INDANGDANGA_SQ);
                if(!targets || targets?.length < 1)return;
                for(const o of targets)
                {
                    for(let i =1; i<13; i++)
                    {
                        o[`MAECHUL_MONTH${i}_CN`] = 0;
                    }
                }
                
                const type = targets[0].HYEONGTAE_NM;
                const date = new Date(data.TUIP_Il_CN);
                const period = Number(targets[0].TUIP_GAEWOL_CN);
                
                const stDate = moment(date).format('YYYY-MM-DD');
                const enDate = moment(date).add(period,'months').format('YYYY-MM-DD');
                
                if(type === '비상주')
                {
                    let dt = moment(stDate);
                    const firMM = this.getMM(dt) * monthUR;
                    let target = targets.find(x=>Number(x.MAECHUL_YEAR_CN) === dt.year());
                    let mm = target.TUIP_GAEWOL_CN - firMM;
                    target[`MAECHUL_MONTH${dt.month()+1}_CN`] = firMM;
                    
                    while(mm > monthUR)
                    {
                        dt = dt.add(1,'months');
                        mm -= monthUR;
                        target = targets.find(x=>Number(x.MAECHUL_YEAR_CN) === dt.year());
                        if(!target) continue;
                        target[`MAECHUL_MONTH${dt.month()+1}_CN`] = monthUR;
                    }

                    dt = dt.add(1,'months');
                    target = targets.find(x=>Number(x.MAECHUL_YEAR_CN) === dt.year());
                    if(!target) 
                    {
                        this.initDataSource();
                        return;
                    }
                    target[`MAECHUL_MONTH${dt.month()+1}_CN`] = mm;
                    this.initDataSource();
                    return;
                }

                let dt = moment(stDate);
                let target = targets.find(x=>Number(x.MAECHUL_YEAR_CN) === dt.year());
                target[`MAECHUL_MONTH${dt.month()+1}_CN`] = this.getMM(dt);
                while(dt.month()+1 != moment(enDate).month()+1)
                {
                    if(dt.month() === moment(stDate).month())
                    {
                        dt = dt.add(1,'months').subtract(moment(stDate).date()-1,'days');
                    }
                    else if(dt.month() === moment(enDate).month()-1)
                    {
                        dt = dt.add(1,'months').add(moment(stDate).date()-1,'days');
                        let target = targets.find(x=>Number(x.MAECHUL_YEAR_CN) === dt.year());
                        if(!target) continue;
                        target[`MAECHUL_MONTH${dt.month()+1}_CN`] = 1 -this.getMM(dt);
                        break;
                    }
                    else
                    {
                        dt = dt.add(1,'months');
                    }
                    let target = targets.find(x=>Number(x.MAECHUL_YEAR_CN) === dt.year());
                    if(!target)
                    {
                        this.initDataSource();
                        return;
                    }
                    target[`MAECHUL_MONTH${dt.month()+1}_CN`] = this.getMM(dt);
                }
                this.initDataSource();
            }
            if(data?.INDANGDANGA_SQ != null)
            {
                const targets = this.allDataList.filter(x=>x.INDANGDANGA_SQ == data.INDANGDANGA_SQ);
                for(const t of targets)
                {
                    for(let month = 1; month<13; month++)
                    {
                        t[`MAECHUL_MONTH${month}_CN`] = data[`MAECHUL_MONTH${month}_CN_${t.MAECHUL_YEAR_CN}`]
                    }
                }
            }
        },
        initDataSource()
        {
            this.dataSource = [];
            for(const year of this.yearList)
            {
                const yearData = this.allDataList.filter(x=>Number(x.MAECHUL_YEAR_CN) == year);
                for(const inputData of yearData)
                {
                    const data = {...inputData};
                    const targetData = this.dataSource.find(x=>x.INDANGDANGA_SQ === inputData.INDANGDANGA_SQ);
                    if(targetData)
                    {
                        for(const key of Object.keys(data))
                        {
                            if(key.includes('MAECHUL_MONTH'))
                            {
                                targetData[`${key}_${year}`] = data[key];
                            }
                        }
                    }
                    else
                    {
                        for(const key of Object.keys(data))
                        {
                            if(key.includes('MAECHUL_MONTH'))
                            {
                                data[`${key}_${year}`] = data[key];
                                delete data[key];
                            }
                        }
                        this.dataSource.push(data);
                    }
                }
            }
        },
        getMM(dt)
        {
            const date = new Date(dt);
            const lastDate = new Date(date.getFullYear(),date.getMonth()+1,0);
            const a = lastDate.getDate();
            const b = date.getDate();

            const c = (a-b+1)/a;
            return c;
        },
        async getMsArr()
        {
            const res = await RestHelper.RestSend('USP_MAECHUL','MAECHULSUGEUM',[{
                KEYWORD : '',
                PROJECT_SQ : '',
                INWON_SQ : '',
                MAECHUL_YEAR_CN : this.year
            }]);
            if(res._result[0].RESULTCD == '1')
            {
                this.msArr = [...res._data];
            }
        },
        onCellPrepared(e){
            if((e.columnIndex == 2 || e.columnIndex == 1) && e.rowType =='data')
            {
                if(DataHelper.StringNullorEmpty(e.displayValue))return;
                if(!this.cellMergeMap.has(e.displayValue))
                {
                    this.cellMergeMap.set(e.displayValue,{
                        element : e.cellElement,
                        count : 1
                    });
                }
                else
                {
                    if(e.displayValue != "" && this.cellMergeMap.has(""))
                    {
                        this.cellMergeMap.delete('');
                    }
                    this.cellMergeMap.get(e.displayValue).count++;
                    e.cellElement.style.display = 'none';
                    this.cellMergeMap.get(e.displayValue).element.rowSpan = this.cellMergeMap.get(e.displayValue).count;
                }
            }
        },
        projGridReady(e)
        {
            this.cellMergeMap = new Map();
        },
        setUnit(value)
        {
            if(DataHelper.StringNullorEmpty(value)) return '0';
            value = value?.toString().replaceAll(',','');
            return sf("{0:###,###,###,###,###,###,##0}",Number(value));
        },
        searchCallback(row)
        {
            this.$nextTick(()=>{
                this.selProj = row;
            })
        },
        onChangeInfoTab(e)
        {
            this.selInfo = e.addedItems[0];
        },
        async setInfoBonbuDataSource()
        {
            const res = await RestHelper.RestSend("USP_MAECHUL","BUSEOTOTAL",[{
                KEYWORD : '',
                PROJECT_SQ : '',
                INWON_SQ : '',
                MAECHUL_YEAR_CN : this.year,
            }]);
            if(res._result[0].RESULTCD == 1)
            {
                this.infoBonbuDatasource = [...res._data];
                for(const o of this.infoBonbuDatasource.filter(x=>DataHelper.StringNullorEmpty(x.CHILD_BBUSEO_NAME)))
                {
                    o.CHILD_BBUSEO_NAME = o.PARENTS_BUSEO_NAME; 
                    o.CHILD_BBUSEO_COLOR = o.PARENTS_BUSEO_COLOR;
                    for(let i = 1; i<13; i++)
                    {
                        if(this.infoBonbuDataMap[`MONTH${i}_AM`] == undefined)
                        {
                            this.infoBonbuDataMap[`MONTH${i}_AM`] = 0;
                        }
                    }
                    for(let i = 1; i<13; i++)
                    {
                        this.infoBonbuDataMap[`MONTH${i}_AM`] += o[`MONTH${i}_AM`];
                    }
                }
            }
        },
        async setInfoProjDataSource()
        {
            const res = await RestHelper.RestSend('USP_MAECHUL','INIT',[{}])
            if(res._result[0].RESULTCD == '1')
            {
                this.projList = [...res._data];
                for(const info of this.infoList)
                {
                    let wolgiseong = DataHelper.StringNullorEmpty(info.WOLGISEONG_AM?.toString())?0:Number(info.WOLGISEONG_AM)
                    wolgiseong = isNaN(wolgiseong) ? 0 : wolgiseong;
                    info.WOLGISEONG_AM = wolgiseong;
                    for(let month=1 ; month<13; month++)
                    {
                        const propNm = `MAECHUL_MONTH${month}_CN`;
                        const costNm = `MAECHUL_MONTH${month}_COST`;
                        let targetValue = DataHelper.StringNullorEmpty(info[propNm]?.toString())?0:Number(info[propNm]);
                        targetValue = isNaN(targetValue) ? 0 : targetValue;
                        info[propNm] = sf("{0:#,######.0##}", targetValue);
                        info[costNm] = sf("{0:#,###,##0}", targetValue * wolgiseong);
                    }
                }
                this.infoProjDatasource = [...this.infoList];
                for(const target of this.infoProjDatasource)
                {
                    const proj = this.projList.find(x=>x.PROJECT_SQ === target.PROJECT_SQ);
                    if(proj)
                    {
                        target.PJ_NM = proj.PJ_NM;
                        target.PJCODE_ID = proj.PJCODE_ID;
                        target.BUNRYU_NM = proj.BUNRYU_NM;
                    }
                }
                this.infoProjDatasource = this.infoProjDatasource.filter(x=>!DataHelper.StringNullorEmpty(x?.PJ_NM));
                this.infoProjDatasource.sort((a, b) => a.PJCODE_ID?.localeCompare(b.PJCODE_ID));
                this.tempInfoProjDatasource = [...this.infoProjDatasource];
            }
        },
        searchProjData()
        {
            if(DataHelper.StringNullorEmpty(this.keyword))
            {
                this.infoProjDatasource = [...this.tempInfoProjDatasource];
                return;
            }
            const ret1 = this.tempInfoProjDatasource.filter(x=>x.PJ_NM.includes(this.keyword));
            const ret2 = this.tempInfoProjDatasource.filter(x=>x.PJCODE_ID.includes(this.keyword));
            this.infoProjDatasource = [...new Set([...ret1,...ret2])];
            this.infoProjDatasource = this.infoProjDatasource.filter(x=>!DataHelper.StringNullorEmpty(x?.PJ_NM));
            this.infoProjDatasource.sort((a, b) => a.PJCODE_ID?.localeCompare(b.PJCODE_ID));
        },
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
            LogHelper.logWrite(`매출현황 연도 요청`,[res])
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                this.yearList = [];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear();
                })
            }
        },
        async getInfos()
        {
            const res = await RestHelper.RestSend('USP_MAECHUL','LIST',[{
                KEYWORD : '',
                PROJECT_SQ : '',
                //INWON_SQ : '',
                INWON_SQ:`INWON_SQ_${this.year}`,
                MAECHUL_YEAR_CN : this.year
            }]);                
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite(`매출현황 정보 요청`,[res])
                this.infoList = [...res._data];
                this.setInfoBonbuDataSource();
                this.setInfoProjDataSource();
                await this.getInfoBoard();
                await this.getMsArr();
            }
        },
        async getInfoBoard()
        {
            const sRes = await RestHelper.RestSend('USP_BORD','BORD1',[{ BORD_YEAR_CN : this.year }]);
            let sResRet = null;
            if(sRes._result[0].RESULTCD == '1')
            {
                sResRet = [...sRes._data06];
            }
            const mRes = await RestHelper.RestSend('USP_MAECHUL','TOTAL',[{ KEYWORD : '',PROJECT_SQ : '',INWON_SQ : '',MAECHUL_YEAR_CN : this.year}]);
            let mResRet = null;
            if(mRes._result[0].RESULTCD == '1')
            {
                mResRet = [...mRes._data];
            }
            if(mResRet != null && sResRet != null)
            {
                this.boardArr = [];
                let sTotal = 0;
                let mTotal = 0;
                for(const o of sResRet)
                {
                    const newData = {};
                    newData.name = o.BUSEOMYEONG_NM;
                    newData.sData = o.WON_AM;
                    const mo = mResRet.find(x=>x.BUSEOMYEONG_NM == o.BUSEOMYEONG_NM);
                    if(mo == undefined)
                    {
                        newData.mData = 0; 
                    }
                    else
                    {
                        newData.mData = mo.WOLGISEONG_TOTAL;
                    }
                    
                    sTotal += newData.sData;
                    mTotal += newData.mData;
                    this.boardArr.push(newData);
                }
                this.boardArr.push({name : '합계',sData : sTotal,mData : mTotal});
            }
        },
        getInfoBoradText(name,type)
        {
            const target = this.boardArr.find(x=>x.name == name);
            if(type == 's')
            {
                const value = (target?.mData ?? 0) / 100000000; // 단위 : 억원으로 표기
                return sf("{0:###,###,###,###,###,##0.0}", value); // 소숫점 첫 번째 자리까지 표기
            }
            else
            {
                const value = (target?.mData ?? 0) / 100000000; // 단위 : 억원으로 표기
                return sf("{0:###,###,###,###,###,##0.0}", value); // 소숫점 첫 번째 자리까지 표기
            }
        },
        async onClickSearch()
        {
            const res = await RestHelper.RestSend('USP_PJ_INDANGDANGA','SEARCH',[{KEYWORD : this.searchText}])
            if(res._result[0].RESULTCD == '1')
            {
                this.projList = [...res._data];
                if(this.projList.length > 0)
                {
                    this.selProj = this.projList[0];
                }
            }
        },
        async updateProj()
        {
            this.yearList = [];
            this.yearList.push(DataHelper.SetDtData(this.selProj.YONGYEOK_MAGAM_DT).split('-')[0]);
            this.yearList.push(DataHelper.SetDtData(this.selProj.YONGYEOK_SIJAK_DT).split('-')[0]);
            this.yearList = [...new Set(this.yearList.sort())];
            if(this.yearList.length > 1)
            {
                const fy = Number(this.yearList[0]);
                const by = Number(this.yearList[1]);
                this.yearList = [];
                for(let i = fy; i<=by; i++)
                {
                    this.yearList.push(i);
                }
            }
            this.yearIndex = this.yearList.length-1;
            this.allDataList = [];
            for(const y of this.yearList)
            {
                const yData = await this.getSalesList(y);
                for(const yd of yData)
                {
                    yd.MAECHUL_YEAR_CN = y;
                    for(let i = 1; i<13; i++)
                    {
                        const mData = yd[`MAECHUL_MONTH${i}_CN`];
                        if(DataHelper.StringNullorEmpty(mData))
                        {
                            yd[`MAECHUL_MONTH${i}_CN`] = 0;
                        }
                        else
                        {
                            yd[`MAECHUL_MONTH${i}_CN`] = Number(yd[`MAECHUL_MONTH${i}_CN`]);
                        }
                        yd[`TUIP_GAEWOL_CN`] = Number(yd[`TUIP_GAEWOL_CN`]);
                    }
                }
                this.allDataList = this.allDataList.concat(yData);
            }

            let i = 0;
            for(const o of this.allDataList)
            {
                o.index = i++;
            }
            
            this.dataSource = [];
            for(const year of this.yearList)
            {
                const yearData = this.allDataList.filter(x=>Number(x.MAECHUL_YEAR_CN) == year);
                for(const inputData of yearData)
                {
                    const data = {...inputData};
                    const targetData = this.dataSource.find(x=>x.INDANGDANGA_SQ === inputData.INDANGDANGA_SQ);
                    if(targetData)
                    {
                        for(const key of Object.keys(data))
                        {
                            if(key.includes('MAECHUL_MONTH'))
                            {
                                targetData[`${key}_${year}`] = data[key];
                            }
                        }
                    }
                    else
                    {
                        for(const key of Object.keys(data))
                        {
                            if(key.includes('MAECHUL_MONTH'))
                            {
                                data[`${key}_${year}`] = data[key];
                                delete data[key];
                            }
                        }
                        this.dataSource.push(data);
                    }
                }
            }
        },
        async getSalesList(year)
        {
            if(DataHelper.StringNullorEmpty(this.selProj.PJ_NM)) return[];
            const res = await RestHelper.RestSend('USP_MAECHUL','LIST',[{
                KEYWORD : '',
                PROJECT_SQ : this.selProj.PROJECT_SQ,
                INWON_SQ : '',
                MAECHUL_YEAR_CN : year
            }])
            LogHelper.logWrite('매출 관리 리스트 요청',[res],this.logUse);
            if(res._result[0].RESULTCD == '1')
            {
                return res._data;
            }
            return [];
        },
        async onClickSave() {
            // MM 잔여량 확인
            for (const o of this.allDataList) {
                if (this.calMM(o) < 0) {
                    alert('잔여 MM이 부족합니다.');
                    return;
                }
            }
            
            const params = this.allDataList.map(item => ({
                KEYWORD : '',
                PROJECT_SQ: item.PROJECT_SQ,
                INWON_SQ: item.INWON_SQ,
                MAECHUL_YEAR_CN: item.MAECHUL_YEAR_CN,
                TUIP_DT: '',
                MAECHUL_MONTH1_CN: item.MAECHUL_MONTH1_CN.toString(),
                MAECHUL_MONTH2_CN: item.MAECHUL_MONTH2_CN.toString(),
                MAECHUL_MONTH3_CN: item.MAECHUL_MONTH3_CN.toString(),
                MAECHUL_MONTH4_CN: item.MAECHUL_MONTH4_CN.toString(),
                MAECHUL_MONTH5_CN: item.MAECHUL_MONTH5_CN.toString(),
                MAECHUL_MONTH6_CN: item.MAECHUL_MONTH6_CN.toString(),
                MAECHUL_MONTH7_CN: item.MAECHUL_MONTH7_CN.toString(),
                MAECHUL_MONTH8_CN: item.MAECHUL_MONTH8_CN.toString(),
                MAECHUL_MONTH9_CN: item.MAECHUL_MONTH9_CN.toString(),
                MAECHUL_MONTH10_CN: item.MAECHUL_MONTH10_CN.toString(),
                MAECHUL_MONTH11_CN: item.MAECHUL_MONTH11_CN.toString(),
                MAECHUL_MONTH12_CN: item.MAECHUL_MONTH12_CN.toString(),
                INDANGDANGA_SQ: item.INDANGDANGA_SQ,
            }));

            const res = await RestHelper.RestSend('USP_MAECHUL', 'UPDATE', params);
            if (res._result[0].RESULTCD === '1') {
                alert('저장 성공');
            } else {
                alert('저장 실패');
            }
        },
        calYear(dt,isSt=true)
        {
            const obj = new Date(this.getDt(dt));
            const ret = isSt ? `${obj.getFullYear()}-01` : `${obj.getFullYear()}-12`;
            return ret;
        },
        calculateSalesAmount(rowData) {
            let ret = Number(rowData.TUIP_GAEWOL_CN);
            const stDt = new Date(this.getDt(this.selProj.YONGYEOK_SIJAK_DT));
            const enDt = new Date(this.getDt(this.selProj.YONGYEOK_MAGAM_DT));
            for(let y = stDt.getFullYear(); y<=enDt.getFullYear(); y++)
            {
                for(let i = 1; i<13; i++)
                {
                    ret -= rowData[`MAECHUL_MONTH${i}_CN_${y}`];
                }
            }
            if(ret == 0)
            {
                return 0;   
            }
            return ret;
        },
        calMM(o)
        {
            let ret = 0;
            for(let i = 1; i<13; i++)
            {
                const value = o[`MAECHUL_MONTH${i}_CN`];
                if(value != null && value != undefined)
                {
                    ret += Number(value);
                }
            }
            return  Number(o.TUIP_GAEWOL_CN) - ret;
        },
        getDt(dt,isMonth=false)
        {
            return DataHelper.SetDtData(dt,isMonth);
        },
        onCellClick(e)
        {
            if(!e.data)return;
            this.selData = e.data;
            ClipboardHelper.keyExpr = 'index';
            ClipboardHelper.onCellClick(e);
        },
        onCellHoverChanged(e)
        {
            ClipboardHelper.keyExpr = 'index';
            ClipboardHelper.onCellHoverChanged(e);
        },
        async onClickAllEdit() {
            const st = this.$refs.allEditStDt.value; // 수정된 부분
            const en = this.$refs.allEditEnDt.value; // 수정된 부분
            const gap = Number(this.$refs.allEditGap.value); // 수정된 부분
            const val = Number(this.$refs.allEditValue.value); // 수정된 부분
            if (isNaN(gap) || gap == 0) return;

            const isExist = !(DataHelper.StringNullorEmpty(st) || DataHelper.StringNullorEmpty(en) || isNaN(val));
            if (!this.selData) return;
            if (!isExist) return;

            const targets = this.allDataList.filter(x => (x.PROJECT_SQ === this.selData.PROJECT_SQ) && (x.INDANGDANGA_SQ === this.selData.INDANGDANGA_SQ));
            const stDt = new Date(st);
            const enDt = new Date(en);

            for (let y = stDt.getFullYear(); y <= enDt.getFullYear(); y++) {
                const target = targets.find(x => Number(x.MAECHUL_YEAR_CN) === y);
                if (!target) continue;
                const stMonth = stDt.getFullYear() == y ? stDt.getMonth() + 1 : 1;
                const enMonth = enDt.getFullYear() == y ? enDt.getMonth() + 1 : 12;
                for (let m = stMonth; m <= enMonth; m += gap) {
                    if (m > 12) continue;
                    target[`MAECHUL_MONTH${m}_CN`] = val;
                }
            }
            this.initDataSource();
            this.$nextTick(()=>{
                this.popupVisible = false;
            })
        },

        async initDataGrid() {
            await this.getYears();
            for(const year of this.yearList) {
                const keyExpr = `INWON_SQ_${year}`;
            }
        },
        onCellValueChanged(e) {
            // 수정된 값 가져오기
            const newValue = e.value;
            // 수정된 데이터의 년도와 월 가져오기RE
            const year = e.data.MAECHUL_YEAR_CN;
            const month = e.column.caption.split("월")[0];
            // 데이터 업데이트
            const dataIndex = this.allDataList.findIndex(data => data.MAECHUL_YEAR_CN === year);
            if (dataIndex !== -1) {
                this.dataSource[dataIndex][`MAECHUL_MONTH${month}_CN`] = newValue;
            }
        },
        // 데이터 소스 갱신
        refreshDataSource() {
            this.dataSource = this.allDataList.filter(data => data.MAECHUL_YEAR_CN === this.yearList[this.yearIndex]);
        },
    }
}
</script>

<style>
#MAECHUL_view #dataGrid {
    width: 100% !important;
    height: max-content;
}
#MAECHUL_view #dataGrid td{
    border: 1px solid #e0e0e0!important;
    font-weight: 500;
}

#MAECHUL_view .dx-datagrid .dx-datagrid-headers .dx-header-filter,
#MAECHUL_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    outline: 0;
    padding: 0;
    height: 47px;
    font-size: 1.3rem;
    color:#818181;
    font-weight: 500;
    vertical-align: middle;
    border: 1px solid #d9d9d9;
}
#MAECHUL_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    padding:0 8px;
    font-size: 1.3rem !important;
    background:#fafafa;
    border:1px solid #d9d9d9
}

#MAECHUL_view .cell-selected{
    background: rgb(204, 217, 238) !important;
}

#MAECHUL_view .dx-datagrid  > td {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dx-pivotgrid .dx-pivotgrid-area td.dx-pivotgrid-expanded > span {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}

#MAECHUL_view .dx-pivotgrid-vertical-headers {
    height: 25px;
    padding: 0;
    vertical-align: middle;
}
#MAECHUL_view .dx-pivotgrid.dx-row-lines .dx-pivotgrid-area-data tr td {
    padding:0 8px;
    vertical-align: middle;
}

#MAECHUL_view .dx-last-cell {
    padding: 0;
    height: 49px;
    vertical-align: middle;
}
#MAECHUL_view .dx-scrollable-content tr td,
#MAECHUL_view .dx-datagrid-rowsview tr td {
    height: 49px ;
    padding: 0 15px;
    vertical-align: middle;
    color:#111111;
    font-size: 1.25rem !important;
    white-space: break-spaces;
    word-break: keep-all;
}
#MAECHUL_view .dx-filter-header,
#MAECHUL_view .dx-data-header,
#MAECHUL_view  .dx-column-header
 {
    display: none;
}
#MAECHUL_view .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
#MAECHUL_view .dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
    margin:0;
    border: 0;
    display:none;
}

#MAECHUL_view .dx-texteditor-input{
    background: white !important;
    font-size: 1.4rem;
    padding:0 0 0 12px;
    border-radius: 5px;
}
#MAECHUL_view .dx-dropdowneditor-overlay .dx-list-group-header,
#MAECHUL_view .dx-dropdowneditor-overlay .dx-list-item-content {
    font-size: 1.4rem !important;
}
#MAECHUL_view  .dx-texteditor.dx-editor-filled {
    background-color:#fff;
    border-radius: 5px;
    border:1px solid #DDD;
}
#MAECHUL_view  .dx-texteditor.dx-editor-filled::after {
    border-bottom:0;
}
#MAECHUL_view .dx-dropdowneditor-icon::before {
    content: url('../../assets/icon/combobox.png');
    position: absolute;
    display: block;
    width: 12px;
    top: 50%;
    margin-top: -11px;
    left: 50%;
    margin-left: -12px;
}
#MAECHUL_view .dx-dropdowneditor-icon {
    width: 12px;
    font-size:12px
}
#MAECHUL_view .dx-placeholder {
    position: absolute;
    top: -5px;
    left: -5px;
    max-width: 100%;
    width: auto;
    height: 100%;
    text-align: left;
    cursor: text;
    pointer-events: none;
    color: rgba(148, 148, 148, .87);
    font-size: 14px;
    vertical-align: middle;
}
.empty-placeholder {
    height: 0;
    overflow: hidden;
}
#MAECHUL_view .dx-multiview-wrapper {
    overflow: visible;
    width: 100%;
    height:max-content;
    -ms-touch-action: pinch-zoom pan-y;
    touch-action: pinch-zoom pan-y;
}
#MAECHUL_view .dx-tap {
    overflow: visible;
    width: 100%;
    height:max-content;
    -ms-touch-action: pinch-zoom pan-y;
    touch-action: pinch-zoom pan-y;
}
#MAECHUL_view .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
    text-align: center !important;
}
#MAECHUL_view .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 60px;
}
#MAECHUL_view .dx-popup-title.dx-toolbar .dx-toolbar-before {
    padding-right: 0px;
    font-weight: bold; 
    padding: 0 20px;
}
#MAECHUL_view .dx-toolbar-label > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding: 0;
}
#MAECHUL_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td,
#MAECHUL_view .dx-datagrid-rowsview tr td {
    border: 1px solid #d9d9d9;
}
#MAECHUL_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    text-align: center !important;
}
#MAECHUL_view .dx-datagrid .dx-column-indicators {
    display: none;
}
#MAECHUL_view .dx-datagrid-text-content {
    width:100%;
}
.MAECHUL_TABLE .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    height: 94px !important;
}
#MAECHUL_view .dx-footer-row td{
    border: 1px #ccc solid !important;
    font-size: 1.2rem !important;
}
#MAECHUL_view .dx-scrollable-content tr td {
    height: 47px;
    padding: 0 8px;
    text-align: center;
    border:1px solid #d9d9d9;
    letter-spacing: -0.2px;
}

#MAECHUL_view .p-highlight {
    background:#fff !important;
    color:#787878;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
#MAECHUL_view .p-selectbutton .p-button {
    background:#f8f8f8;
}
#MAECHUL_view .dx-treelist-headers .dx-treelist-table .dx-row > td {
    border: 1px solid #d9d9d9;
}
.Col {
    word-spacing: -2px;
}
</style>