<template>
  <div class="container mx-auto px-2 py-2 no-scroll" style="width: 1400px; height: 200px; overflow: hidden;">
    <h1 class="text-[1.6rem] font-bold text-gray-800 my-4 truncate">
      PMS 정보 - {{ gridData[0]?.ProjectName }} ( {{ gridData[0]?.PJNo }} )
    </h1>
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <DxDataGrid ref="dataGridInstance"
        :data-source="gridData" 
        :columns="columns" 
        :height="100"
        :show-borders="true"
        :column-auto-width="false"
        :allow-column-resizing="true"
        :row-alternation-enabled="true"
      >
      <!-- <DxScrolling use-native="false" show-scrollbar="false" scroll-by-thumb="false"/> -->
      </DxDataGrid>
    </div>
    <div class="mt-3 flex flex-row items-center justify-end gap-2 text-[1.2rem]">
      <button @click="applyChanges" class="px-4 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 transition duration-300 ease-in-out border-[1px] border-gray-500">
        적용
      </button>
      <button @click="closeWindow" class="px-4 py-2 bg-gray-200 text-gray-800 font-semibold rounded hover:bg-gray-300 transition duration-300 ease-in-out border-[1px] border-gray-500">
        닫기
      </button>
    </div>
  </div>
</template>

<script setup>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import { ref, onMounted } from 'vue';
import RestHelper from '../Helpers/RestHelper';

const columns = [
  ...Array(12).fill().map((_, index) => ({
    dataField: `Month${String(index + 1).padStart(2, '0')}`,
    caption: `${index + 1}월`,
    dataType: 'number',
    format: { type: 'fixedPoint', precision: 0 },
    width: `${(100/12)}%`,
    alignment: 'right'
  }))
];

const gridData = ref([]);
const sgList = ref([]);
const query_Year = ref(0);
const dataGridInstance = ref(null);

onMounted(async () => {
  await get();
  const scrollable = dataGridInstance.value.instance.getScrollable();
  if (scrollable) {
    scrollable.scrollTo({ left: 0, top: 0 });
    scrollable.option("disabled", true); // 스크롤 비활성화
  }
});

async function get() {
  const query = new URLSearchParams(window.location.search);
  const year = query.get('year');
  const pjId = query.get('pjId');
  query_Year.value = year;
  
  sgList.value = await getSGList(year);
  
  const res = await RestHelper.RestSend('USP_PMS_INFO', 'INFO', [{
    PJCODE: pjId,
    YEAR: year,
  }]);

  if (res._result[0].RESULTCD === '1' && res._data && res._data.length > 0) {
    gridData.value = prepareData(res._data);
    gridData.value.length = 1;
  } else {
    console.warn('No data received or invalid response');
    gridData.value = [];
  }

  if(gridData.value.length > 0)
  {
    sgList.value = sgList.value.filter(x=>x.PJCODE_ID.toString() === gridData.value[0].PJNo.toString())
  }
}

async function getSGList(year) {
  const res = await RestHelper.RestSend('USP_SUGEUM', 'LIST', [{
    SUGEUM_YEAR_NM: year,
    SANGTAE_NM: 1,
  }]);

  if (res._result[0].RESULTCD === '1') {
    return res._data;
  }
  return [];
}

function prepareData(dataArray) {
  if (!dataArray || dataArray.length === 0) {
    console.warn('No data available for preparation');
    return [];
  }

  const sortedData = sortAndFillMonths(dataArray);
  return [{
    No: 1,
    PJNo: sortedData[0]?.CD_PCODE || '',
    ProjectName: sortedData[0]?.NM_PNAME || '',
    ...Object.fromEntries(sortedData.map((item, index) => 
      [`Month${String(index + 1).padStart(2, '0')}`, item.PCM_SUM || 0]
    ))
  }];
}

function sortAndFillMonths(array) {
  if (!array || array.length === 0) {
    console.warn('No data available for sorting and filling months');
    return [];
  }

  const year = array[0].CASH_YEAR || new Date().getFullYear().toString();
  let result = Array(12).fill().map((_, index) => ({
    AM_HAAN_SPLY: 0,
    CASH_MONTH: (index + 1).toString().padStart(2, '0'),
    CASH_YEAR: year,
    CD_PCODE: array[0].CD_PCODE || '',
    DT_CASH: `${year}-${(index + 1).toString().padStart(2, '0')}-15T00:00:00`,
    NM_PLAN: array[0].NM_PLAN || '',
    NM_PNAME: array[0].NM_PNAME || '',
    PCM_SUM: 0
  }));

  array.forEach(item => {
    if (item && item.CASH_MONTH) {
      const monthIndex = parseInt(item.CASH_MONTH) - 1;
      result[monthIndex] = { ...result[monthIndex], ...item };
    }
  });

  return result;
}

async function applyChanges() {
  if(confirm("적용 시 확정 값에 자동으로 입력이 됩니다.\r\n적용하시겠습니까?") == false)return;
  for (let i = 1; i <= 12; i++) {
      let monthKey = `SUGEUM_MONTH_CN${i}`; // BIGO_MONTH_CN01, BIGO_MONTH_CN02, ...
      let newMonthKey = `Month${i.toString().padStart(2, '0')}`; // Month01, Month02, ...
      if(gridData.value[0][newMonthKey] == undefined)continue;
      sgList.value[0][monthKey] = gridData.value[0][newMonthKey];
  }
  const o = sgList.value[0];
  const data = {
                  SUGEUM_YEAR_NM : query_Year.value,
                  SANGTAE_NM : '1',
                  SUJU_SQ : o.SUJU_SQ,
                  SUGEUM_MONTH_NM : '',
                  SUGEUM_MONTH1_CN : o.SUGEUM_MONTH_CN1,
                  SUGEUM_MONTH2_CN : o.SUGEUM_MONTH_CN2,
                  SUGEUM_MONTH3_CN : o.SUGEUM_MONTH_CN3,
                  SUGEUM_MONTH4_CN : o.SUGEUM_MONTH_CN4,
                  SUGEUM_MONTH5_CN : o.SUGEUM_MONTH_CN5,
                  SUGEUM_MONTH6_CN : o.SUGEUM_MONTH_CN6,
                  SUGEUM_MONTH7_CN : o.SUGEUM_MONTH_CN7,
                  SUGEUM_MONTH8_CN : o.SUGEUM_MONTH_CN8,
                  SUGEUM_MONTH9_CN : o.SUGEUM_MONTH_CN9,
                  SUGEUM_MONTH10_CN : o.SUGEUM_MONTH_CN10,
                  SUGEUM_MONTH11_CN : o.SUGEUM_MONTH_CN11,
                  SUGEUM_MONTH12_CN : o.SUGEUM_MONTH_CN12,
                  SUGEUM_WON_AM1 : '',
                  SUGEUM_WON_AM2 : '',
                  SUGEUM_DATE_DT1 : '',
                  SUGEUM_DATE_DT2 : '',
                  BIGO : '',
              };
  await RestHelper.RestSend('USP_SUGEUM', 'INSERT', [data]);
  closeWindow();
}

function closeWindow() {
  window.close();
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #f3f4f6;
}

.no-scroll .dx-scrollable {
  overflow: hidden !important;
}
</style>