<template>
    <DxPopup ref="instance" title="인원 검색" :visible="visible" :element-attr="{class:'overflow-hidden'}" width="80rem" height="70rem"  :show-close-button="true" @hidden="()=>{visible = false}">
        <div class="flex flex-row border-t-2 border-l-2 w-full text-[1rem]">
            <div class="flex flex-col">
                <div class="flex flex-row items-center w-full">
                    <div class="border-r-2 border-b-2 h-[3rem] text-center w-[8rem] leading-[3rem]">사원번호</div>
                    <input type="text" class="border-r-2 border-b-2 h-[3rem] text-center w-[10rem] leading-[3rem]" v-model="id"/>
                    <div class="border-r-2 border-b-2 h-[3rem] text-center w-[7rem] leading-[3rem]">성명</div>
                    <input type="text" class="border-r-2 border-b-2 h-[3rem] text-center w-[10rem] leading-[3rem]" v-model="name"/>
                    <div class="border-r-2 border-b-2 h-[3rem] text-center w-[7rem] leading-[3rem]">부서</div>
                    <input type="text" class="border-r-2 border-b-2 h-[3rem] text-center w-[22rem] leading-[3rem]" v-model="depart"/>
                </div>
                <div class="flex flex-row items-center w-full">
                    <div class="border-r-2 border-b-2 h-[3rem] text-center w-[8rem] leading-[3rem]">현장명</div>
                    <input type="text" class="border-r-2 border-b-2 h-[3rem] text-center w-[56rem] leading-[3rem]" v-model="workSite"/>
                </div>
            </div>
            <div @click="onClickSearch" class="flex flex-row items-center border-r-2 border-b-2 h-[6rem] text-center w-full select-none cursor-pointer hover:bg-slate-50">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-[6rem] h-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <p>검색</p>
            </div>
        </div>
        <div class="m-[2rem]" ></div>
        <DataTable v-model:selection="selectedRow" :value="personList" paginator :rows="10" size="none" tableStyle="width: 100%; height: 40rem;" selectionMode="single">
            <Column field="SAWON_BEONHO_NO" header="사번" style="padding: 0.7rem; width: 10%; border: 1px solid #c7c7c7;"></Column>
            <Column field="SAWON_MYEONG_NM" header="성명" style="padding: 0.7rem; width: 10%; border: 1px solid #c7c7c7;"></Column>
            <Column field="DEPT_NM" header="부서" style="padding: 0.7rem; width: 25%; border: 1px solid #c7c7c7;"></Column>
            <Column field="WORK_SITE_NM" header="현장명" style="padding: 0.7rem; width: 55%; border: 1px solid #c7c7c7;"></Column>
        </DataTable>
        <div @click="select" class="flex flex-row items-center border-2 h-[5rem] font-semibold select-none cursor-pointer text-center w-full justify-center mt-[2rem] hover:bg-slate-50">
            <p>등록</p>
        </div>
        <DxToast
        v-model:visible="toastVisible"
        v-model:message="toastMsg"
        v-model:type="toastType"
        :display-time="600"
        >
        <DxPosition my="top" at="top" offset="0 50"/>
        </DxToast>
    </DxPopup>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import { DxPopup } from 'devextreme-vue/popup';
import RestHelper from '../Helpers/RestHelper';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { DxToast, DxPosition } from 'devextreme-vue/toast';
export default {
    components:{
        DxPopup,IconField,InputIcon,DxToast, DxPosition,
        DataTable,Column,ColumnGroup,Row
    },
    props:{
        callBack : Object,
    },
    data(){
        return{
            visible : false,
            personAllList : [],
            personList : [],
            id : '',
            name : '',
            depart : '',
            workSite : '',
            selectedRow : {},
            toastVisible : false,
            toastMsg : "",
            toastType : "success",
        }},
    methods:{
        async show(){
            this.id = '';
            this.name = '';
            this.depart = '';
            this.workSite = '';
            const res = await RestHelper.RestSend('USP_INWON_NEW','LIST',[{}]);
            if(res._result[0].RESULTCD == 1)
            {
                this.personAllList = [...res._data]
                this.personList = [...this.personAllList]
            }
            this.visible = true;
        },
        onClickSearch()
        {
            this.personList = [...this.personAllList]
            if(this.id != '')this.personList = [...this.personList.filter(x=>x.SAWON_BEONHO_NO?.toString().includes(this.id))]
            if(this.name != '')this.personList = [...this.personList.filter(x=>x.SAWON_MYEONG_NM?.toString().includes(this.name))]
            if(this.depart != '')this.personList = [...this.personList.filter(x=>x.DEPT_NM?.toString().includes(this.depart))]
            if(this.workSite != '')this.personList = [...this.personList.filter(x=>x.WORK_SITE_NM?.toString().includes(this.workSite))]
            
            if(this.personList.length === 0)
            {
                for(let i = 0; i<10; i++)
                {
                    this.personList.push({})
                }
            }
            else if(this.personList.length%10 < 10)
            {
                for(let i = 0; i<this.personList.length%10; i++)
                {
                    this.personList.push({})
                }
            }
            this.toastMsg = '검색완료'
            this.toastType = 'success'
            this.toastVisible = true;
            this.selectedRow = {};
        },
        select()
        {
            if(this.selectedRow == null || this.selectedRow == {} || Object.keys(this.selectedRow).length === 0)
            {
                this.toastMsg = '인원을 선택해주세요'
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            this.visible = false;
            this.callBack(this.selectedRow)
        }
    },
    computed:{
        instance(){
            return this.$refs.instance
        }
    }
}
</script>

<style scoped>

</style>