<template>
    <div class="bg-white w-screen h-screen p-[2.4rem]">
        <div class="flex flex-row gap-[2rem] h-[3rem] mb-[2rem]">
            <Dropdown v-model="year" :options="years" class="w-full h-full border-[1px]">
                <template #value="slotProps">
                    <div class="text-[1.6rem] leading-[3rem] text-center w-full">{{ slotProps.value }}</div>
                </template>
                <template #option="slotProps">
                    <div class="text-[1.4rem] leading-[3rem] text-center w-full pr-[2rem]">{{ slotProps.option }}</div>
                </template>
            </Dropdown>
            <Dropdown v-model="month" :options="months" class="w-full h-full border-[1px]">
                <template #value="slotProps">
                    <div class="text-[1.6rem] leading-[3rem] text-center w-full">{{ slotProps.value }}</div>
                </template>
                <template #option="slotProps">
                    <div class="text-[1.4rem] leading-[3rem] text-center w-full pr-[2rem]">{{ slotProps.option }}</div>
                </template>
            </Dropdown>
        </div>
        <DataTable :value="dataSource" class="border-[1px] w-full h-[calc(100%-10rem)]" scrollHeight="calc(100%)"  scrollable >
            <Column field="BUSEOMYEONG_NM" header="부서" class="text-[1.6rem]" :style="tableRowStyle"></Column>
            <Column field="MM1" header="평균" class="text-[1.6rem]" :style="tableRowStyle"></Column>
            <Column field="MM2" header="월말" class="text-[1.6rem]" :style="tableRowStyle"></Column>
        </DataTable> 
        <div @click="apply" class="h-[4rem] mt-[2rem] w-full text-center border-[1px] text-[1.4rem] p-[1rem] select-none cursor-pointer hover:bg-slate-100">적용하기</div>
    </div>
</template>

<script setup>
import {ref, watch, onMounted} from 'vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import RestHelper from '../Helpers/RestHelper';
const tableRowStyle = 'padding: 0.7rem; border: 1px solid; border-color: #E5E7EB; min-width: 100px'
const query = new URLSearchParams(window.location.search);

const years = ref([]);
const year =ref(query.get('year'))
const months = ref(['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월']);
const month =ref('1월')
const dataSource = ref([]);

watch(year,async ()=>{
    await get();
})

watch(month,async ()=>{
    await get();
})

onMounted(async()=>{
    await getYears();
    await get();
})

async function get()
{
    const res = await RestHelper.RestSend('USP_INWON_IPRYEOK', 'AUTO', [{
        INWON_IPRYEOK_YEAR_CN: year.value,
        INWON_IPRYEOK_BUSEO_SQ : '',
        INWON_IPRYEOK_SANGTAE_NM : '',
        INWON_IPRYEOK_MONTH_CN: month.value.toString().replace('월',''),
    }]);
    if (res._result[0].RESULTCD === '1' && res._data && res._data.length > 0)
    {
        dataSource.value = [...res._data]
        for(const o of dataSource.value)
        {
            o.MM1 = Math.floor(Number(o.MM1)*100)/100  
            o.MM2 = Math.floor(Number(o.MM2)*100)/100  
        }
    }
}

async function getYears()
{
    const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
    if(res._result[0].RESULTCD == '1')
    {
        const data = res._data[0];
        for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
        {   
            years.value.push(i);
        }
    }
}

async function apply()
{
    if(confirm("적용하시겠습니까?") === false) return;

    const res = await RestHelper.RestSend("USP_INWON_IPRYEOK","AUTOINSERT",[{
        "INWON_IPRYEOK_YEAR_CN" : year.value,
        "INWON_IPRYEOK_BUSEO_SQ":"",
        "INWON_IPRYEOK_SANGTAE_NM":"",
        "INWON_IPRYEOK_MONTH_CN":month.value.toString().replace('월',''),
    }]);
    if(res._result[0].RESULTCD == '1')
    {
        window.close();
    }
}
</script>