<template>
    <div class="flex flex-col w-full h-full p-[1rem] rounded-[1.4rem] font-NotoSansKR" id="ChartWrapper">
        <div class="font-semibold text-[1.6rem] mb-[1rem]">{{ title }}</div>
        <div class="flex flex-row items-center text-[11px] text-[#62686A] mb-[1rem]">
            <template v-for="(item, index) in this.dataSource" :key="index">
                <div :style="{background : `#${item.BUSEO_COLOR}`}" :class="`border-[1px] w-[1rem] h-[1rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem]`"></div>
                <span class="mr-[1rem]">{{ item.BUSEO_NAME }}</span>
            </template>
        </div>
        <div class="w-full h-[calc(100%-4rem)]">
            <Bar :options="barChartOptionsObj" :data="barChartDataObj" :plugins="[ChartJSPluginDatalabels]"/>
        </div>
    </div>
</template>

<script>
import RestHelper from '../../Helpers/RestHelper'
import LogHelper from '../../Helpers/LogHelper'
import { Bar, getDatasetAtEvent } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement,LineElement } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import DataHelper from '@/components/Helpers/DataHelper'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    components:{
        Bar
    },
    props:{
        year : Number,
        title : String,
    },
    watch : {
        async year()
        {
            await this.get();
        }
    },
    data(){
        return{
            dataSource : [],
            barChartDataObj: {
                labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                datasets: [],
            },
            barChartOptionsObj: {
                responsive: true,
                maintainAspectRatio : false,
                borderRadius: 8,
                layout: {
                    autoPadding : false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: true,
                        max: 120,
                        stacked: true,
                    },
                    x:{
                        display: true,
                        stacked: true,
                        grid: {
                            display: false
                        },
                    },
                    y2: {
                        display: true,
                        max: 120,
                        position: 'right',
                        grid: {
                            display: false
                        },
                        ticks: {
                            callback: function(value, index, values) {
                                return value + '%'; 
                            }
                        }
                    },
                },
                plugins : {
                    legend : {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'%';
                        }
                    },
                }
            }
        }
    },
    async mounted()
    {
        await this.get();
    },
    methods : {
        async get()
        {
            const res = await RestHelper.RestSend('USP_BORD','BORD2', [{ BORD_YEAR_CN : this.year }]);
            if(res._result[0].RESULTCD == '1')
            {
                this.barChartDataObj.datasets = [];
                this.dataSource = [...res._data03];
                const obj1 = this.dataSource.find(x=>x.BUSEO_NAME === '본사');
                const obj2 = this.dataSource.find(x=>x.BUSEO_NAME === '현장');
                const obj3 = this.dataSource.find(x=>x.BUSEO_NAME === '본사인원비율');
                obj1.BUSEO_COLOR = '81A4D7'
                obj2.BUSEO_COLOR = 'E4A254'
                obj3.BUSEO_COLOR = '4A7BC3'
                for(const data of this.dataSource)
                {
                    const obj = {
                        data : [data.MONTH1,data.MONTH2,data.MONTH3,data.MONTH4,data.MONTH5,data.MONTH6,data.MONTH7,data.MONTH8,data.MONTH9,data.MONTH10,data.MONTH11,data.MONTH12],
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7,
                        yAxisID: 'y',
                        order: 2,
                    }
                    if(data.BUSEO_NAME == '본사인원비율')
                    {
                        obj.type = 'line';
                        obj.yAxisID = 'y2';
                        obj.order = 1;
                    }
                    if(!DataHelper.StringNullorEmpty(data.BUSEO_COLOR))
                    {
                        obj.backgroundColor = '#'+data.BUSEO_COLOR;
                        obj.borderColor = '#'+data.BUSEO_COLOR;
                    }
                    this.barChartDataObj.datasets.push(obj);
                }
                const maxArr = this.barChartDataObj.datasets.map(x=>Math.max(...x.data));
                const maxAvg = maxArr.reduce((a,b)=>a+b) / maxArr.length;
                this.barChartOptionsObj.scales.y.max = Math.round((maxAvg * 3)/100) * 100;

                const tempData = {...this.barChartDataObj};
                const tempOptions = {...this.barChartOptionsObj};
                tempOptions.scales.y.max = Math.max(...this.barChartDataObj.datasets.map(x=>Math.max(...x.data))) * 1.8;
                this.barChartDataObj = {};
                this.barChartOptionsObj = {};

                this.$nextTick(()=>{
                    this.barChartDataObj = {...tempData};
                    this.barChartOptionsObj = {...tempOptions};

                    const tempOption = {...this.barChartOptionsObj};
                    tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                    tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                    tempOption.scales.y.max = Math.max(...this.barChartDataObj.datasets.map(x => Math.max(...x.data))) * 1.8 ;
                    if(tempOption.scales.y.max == 0)
                    {
                        tempOption.scales.y.max = 10;
                    }
                    this.barChartOptionsObj = { ...tempOption };
                })
            }
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        }
    }
}
</script>

<style>
#ChartWrapper {
    box-shadow: 0px 2px 12px 0px rgba(2, 73, 172, 0.13);

}
</style>