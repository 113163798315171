<template>
   <div class="w-auto h-full bg-white px-[1rem] py-[1rem] overflow-auto font-NotoSansKR" id="Account_View">
    <div class="w-full h-full min-w-[1024px] min-h-[576px]">
        <p class="text-[2.2rem] font-bold text-[#111111] tracking-tighter select-none pb-[1rem]">권한관리</p>
        <!-- <div class="border-[1px] w-full"></div> -->
        <TabMenu id="AcountTab" v-model:activeIndex="active" :model="items" class="mt-[1.6rem] w-[27.6rem] h-[4rem] bg-[#f8f8f8] rounded-lg p-[4px] flex flex-row justify-around items-center">
            <template #item="{ item, props }">
                <a v-ripple v-bind="props.action" class="box-border bg-transparent flex flex-row items-center justify-center w-[13.2rem] h-[3.2rem] pb-[0.25rem] rounded-lg text-center text-[#787878] border-0">
                    <span class="text-[1.1rem] font-normal font-NotoSansKR">{{ item.label }}</span>
                </a>
            </template>
        </TabMenu>
        <div v-if="active == 0" class="grid grid-cols-2 grid-rows-1 w-full h-[calc(100%-12rem)] border-[1px] box-border mt-[1.6rem] mx-0 rounded-lg shadow-lg">
            <div class="flex flex-col col-start-1 gap-[1rem] p-[1rem] h-[calc(100%-0rem)] w-full">
                <div class="flex flex-row items-center justify-between">
                    <p class="font-semibold text-[1.6rem]" >프로그램 목록</p>
                    <Button @click="()=>{menuPopupVisible = true}" label="" class="border-[0px] h-[2.8rem] px-[1rem] font-NotoSansKR"/>
                    <Dialog header="프로그램 편집" v-model:visible="menuPopupVisible" class="p-[1rem] bg-white text-[1.6rem]" modal>
                        <div class="flex flex-col justify-center gap-[1.4rem] w-[40rem] pt-[2rem]">
                            <div class="flex flex-col gap-2">
                                <label class="text-[1.2rem]" for="mname">메뉴명</label>
                                <InputText id="mname" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem]" v-model="selMenuRow.MENU_NAME_NM"  />
                            </div>
                            <div class="flex flex-col gap-2">
                                <label class="text-[1.2rem]" for="morder">순서</label>
                                <InputText id="morder" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem]" v-model="selMenuRow.ORDER_CN"  />
                            </div>
                            <div class="flex flex-row items-center justify-end font-normal text-[1rem] pt-[5rem]">
                                <Button @click="onClickMenuUpdate" label="저장" class="w-[8rem] h-[3rem] bg-[#03A9F4] text-white border-[1px] hover:bg-[#0595d8] mr-[1rem]"/>
                                <Button @click="()=>{menuPopupVisible = false}" label="닫기" class="w-[8rem] h-[3rem] bg-[#fff] text-[#03A9F4] border-[#03A9F4] border-[1px] hover:bg-[#e9e8e8]"/>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <DataTable @vnode-before-mount="onMenuBefore" dataKey="MENU_SQ" :value="menuList" v-model:expandedRows="expandedMenuRows" @row-expand="onRowExpand($event,expandedMenuRows,selMenuRow,'MENU_SQ')" @row-collapse="onRowCollapse($event,expandedMenuRows,selMenuRow)" class="border-[1px] h-[calc(100%-4rem)]" scrollHeight="100%" selectionMode="single" scrollable stripedRows>
                    <Column expander style="width: 2rem; border: 1px solid; border-color: #E5E7EB;" />
                    <Column field="index" header="No" :style="tableRowStyle" class="w-[3rem]" ></Column>
                    <Column field="MENU_NAME_NM" header="메뉴명" :style="tableRowStyle"></Column>
                    <Column field="TOP_NAME" header="상위메뉴명" :style="tableRowStyle" ></Column>
                    <Column field="ORDER_CN" header="순서" :style="tableRowStyle"></Column>
                    <Column field="PARENTS_FG" header="탑메뉴구분" :style="tableRowStyle"></Column>
                    <template #expansion="">
                        <div class="flex flex-row items-center justify-center gap-[1rem] border-b-[1px] w-full h-[3.6rem] bg-[#FAFAFA]">
                            <Button label="편집" @click="()=>{menuPopupVisible = true}" class="text-[1rem] border-[1px] h-[2.4rem] px-[2rem] font-NotoSansKR hover:bg-slate-100"/>
                            <Button label="삭제" class="text-[1rem] border-[1px] h-[2.4rem] px-[2rem] font-NotoSansKR hover:bg-slate-100"/>
                        </div>
                    </template>
                </DataTable>
            </div>
            <div class="flex flex-col col-start-2 gap-[1rem] p-[1rem] h-[calc(100%-0rem)] w-full">
                <div class="flex flex-row items-center justify-between">
                    <p class="font-semibold text-[1.6rem]" >그룹 목록</p>
                    <Button @click="()=>{groupPopupVisible = true}" label="추가" class="border-[1px] h-[2.8rem] px-[1rem] font-NotoSansKR hover:bg-slate-100"/>
                    <Dialog header="그룹 추가 / 편집" v-model:visible="groupPopupVisible" class="p-[1rem] bg-white text-[1.6rem]" modal>
                        <div class="flex flex-col justify-center gap-[1.4rem] w-[40rem] pt-[2rem]">
                            <div class="flex flex-col gap-2">
                                <label class="text-[1.2rem]" for="mname">그룹코드</label>
                                <InputText id="mname" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem]" v-model="selGroupRow.GROUP_CODE_CD"  />
                            </div>
                            <div class="flex flex-col gap-2">
                                <label class="text-[1.2rem]" for="mname">그룹명</label>
                                <InputText id="mname" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem]" v-model="selGroupRow.GROUP_NAME_NM"  />
                            </div>
                            <div class="flex flex-col gap-2">
                                <label class="text-[1.2rem]" for="morder">비고</label>
                                <InputText id="morder" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem]" v-model="selGroupRow.GROUP_BIGO_NM"  />
                            </div>
                            <div class="flex flex-row items-center justify-end font-normal text-[1rem] pt-[5rem]">
                                <Button @click="onClickGroupUpdate" label="저장" class="w-[8rem] h-[3rem] bg-[#03A9F4] text-white border-[1px] hover:bg-[#0595d8] mr-[1rem]"/>
                                <Button @click="()=>{groupPopupVisible = false}" label="닫기" class="w-[8rem] h-[3rem] bg-[#fff] text-[#03A9F4] border-[#03A9F4] border-[1px] hover:bg-[#e9e8e8]"/>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <DataTable @vnode-before-mount="onGroupBefore" dataKey="GROUP_SQ" :value="groupList" v-model:expandedRows="expandedGroupRows" @row-expand="onRowExpand($event,expandedGroupRows,selGroupRow,'GROUP_SQ')" @row-collapse="onRowCollapse($event,expandedGroupRows,selGroupRow)" class="border-[1px] h-[calc(100%-4rem)]" scrollHeight="100%" selectionMode="single" scrollable stripedRows>
                    <Column expander style="width: 2rem; border: 1px solid; border-color: #E5E7EB;" />
                    <Column field="index" header="No" :style="tableRowStyle" class="w-[3rem]"></Column>
                    <Column field="GROUP_CODE_CD" header="그룹코드" :style="tableRowStyle"></Column>
                    <Column field="GROUP_NAME_NM" header="그룹명" :style="tableRowStyle"></Column>
                    <Column field="GROUP_BIGO_NM" header="비고" :style="tableRowStyle"></Column>
                    <template #expansion="">
                        <div class="flex flex-row items-center justify-center gap-[1rem] border-b-[1px] w-full h-[3.6rem] bg-[#FAFAFA]">
                            <Button label="편집" @click="()=>{groupPopupVisible = true}" class="text-[1rem] border-[1px] h-[2.4rem] px-[2rem] font-NotoSansKR hover:bg-slate-100"/>
                            <Button label="삭제" @click="onClickGroupDelete" class="text-[1rem] border-[1px] h-[2.4rem] px-[2rem] font-NotoSansKR hover:bg-slate-100"/>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
        <div v-if="active == 1" class="grid grid-cols-2 grid-rows-1 w-full h-[calc(100%-12rem)] border-[1px] box-border mt-[1.6rem] mx-0 rounded-lg shadow-lg">
            <div class="flex flex-col col-start-1 gap-[1rem] p-[1rem] h-[calc(100%-0rem)] w-full">
                <div class="flex flex-row items-center justify-between">
                    <p class="font-semibold text-[1.6rem]" >그룹별 접근권한</p>
                    <Button @click="onClickVisiblePopup" label="추가" class="border-[1px] h-[2.8rem] px-[1rem] font-NotoSansKR hover:bg-slate-100"/>
                    <Dialog header="그룹별 접근권한 추가 / 편집" v-model:visible="accPopupVisible" class="p-[1rem] bg-white text-[1.6rem]" modal>
                        <div class="flex flex-col justify-center gap-[1.4rem] w-[40rem] pt-[2rem]">
                            <div class="flex flex-row gap-[2rem] w-full">
                                <div class="flex flex-col gap-2 w-full">
                                    <label class="text-[1.2rem]" for="mname">그룹코드</label>
                                    <InputText id="mname" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem] w-full" v-model="selAccRow.selGroup.GROUP_CODE_CD" disabled/>
                                </div>
                                <div class="flex flex-col gap-2 w-full">
                                    <label class="text-[1.2rem]" for="morder">그룹명</label>
                                    <Dropdown id="acc_m_name" v-model="selAccRow.selGroup" :options="groupList" optionLabel="GROUP_NAME_NM" class="w-full border-[1px] h-[3rem] px-[0.8rem] flex flex-row items-center text-[1.4rem]"/>
                                </div>
                            </div>
                            <div class="flex flex-row gap-[2rem] w-full">
                                <div class="flex flex-col gap-2 w-full">
                                    <label class="text-[1.2rem]" for="mname">메뉴코드</label>
                                    <InputText id="mname" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem] w-full" v-model="selAccRow.selMenu.MENU_CODE_CD" disabled/>
                                </div>
                                <div class="flex flex-col gap-2 w-full">
                                    <label class="text-[1.2rem]" for="acc_m_name">메뉴명</label>
                                    <Dropdown id="acc_m_name" v-model="selAccRow.selMenu" :options="menuList.filter(x=>x.PARENTS_FG == 'N')" optionLabel="MENU_NAME_NM" class="w-full border-[1px] h-[3rem] px-[0.8rem] flex flex-row items-center"/>
                                </div>
                            </div>
                            <div class="flex flex-row justify-end gap-[1rem] mt-[5rem]">
                                <Button @click="onAccUpdate" label="저장" class="w-[8rem] h-[3rem] bg-[#03A9F4] text-white border-[1px] hover:bg-[#0595d8] text-[1rem]"/>
                                <Button @click="()=>{accPopupVisible = false}" label="닫기" class="text-[1rem] w-[8rem] h-[3rem] bg-[#fff] text-[#03A9F4] border-[#03A9F4] border-[1px] hover:bg-[#e9e8e8]"/>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <DataTable @vnode-before-mount="onAccBefore" dataKey="ACCESS_SQ" :value="accList" v-model:expandedRows="expandedAccRows" @row-expand="onRowExpand($event,expandedAccRows,selAccRow,'ACCESS_SQ')" @row-collapse="onRowCollapse($event,expandedAccRows,selAccRow)" class="border-[1px] h-[calc(100%-4rem)]" scrollHeight="100%" selectionMode="single" scrollable stripedRows>
                    <Column expander style="width: 2rem; border: 1px solid; border-color: #E5E7EB;" />
                    <Column field="index" header="No" :style="tableRowStyle" class="w-[3rem]" ></Column>
                    <Column field="GROUP_CODE" header="그룹코드" :style="tableRowStyle"></Column>
                    <Column field="GROUP_NAME" header="그룹명" :style="tableRowStyle"></Column>
                    <Column field="MENU_CODE" header="메뉴코드" :style="tableRowStyle"></Column>
                    <Column field="MENU_NAME" header="메뉴명" :style="tableRowStyle"></Column>
                    <template #expansion="">
                        <div class="flex flex-row items-center justify-center gap-[1rem] border-b-[1px] w-full h-[3.6rem] bg-[#FAFAFA]">
                            <Button label="편집" @click="()=>{accPopupVisible = true}" class="text-[1rem] border-[1px] h-[2.4rem] px-[2rem] font-NotoSansKR hover:bg-slate-100"/>
                            <Button label="삭제" @click="onAccDelete" class="text-[1rem] border-[1px] h-[2.4rem] px-[2rem] font-NotoSansKR hover:bg-slate-100"/>
                        </div>
                    </template>
                </DataTable>
            </div>
            <div class="flex flex-col col-start-2 gap-[1rem] p-[1rem] h-[calc(100%-0rem)] w-full">
                <div class="flex flex-row items-center justify-between">
                    <p class="font-semibold text-[1.6rem]" >그룹 목록</p>
                    <Button @click="onClickInwonPopupVisible" label="추가 / 편집" class="border-[1px] h-[2.8rem] px-[1rem] font-NotoSansKR hover:bg-slate-100"/>
                    <Dialog header="그룹별 사용자권한 추가 / 편집" v-model:visible="inwonPopupVisible" class="w-[80rem] p-[1rem] bg-white text-[1.6rem]" modal>
                        <div class="grid grid-cols-12 gap-[1.4rem] justify-start items-end overflow-hidden w-full h-[56rem] mt-[2rem] px-[1rem]">
                            <div class="flex flex-col gap-2 col-span-3">
                                    <label class="text-[1.2rem]" for="morder">그룹명</label>
                                <Dropdown id="acc_m_name" v-model="selGroupPopup" :options="groupList" optionLabel="GROUP_NAME_NM" class="border-[1px] h-[3rem] px-[0.8rem] flex flex-row items-center text-[1.4rem]"/>
                            </div>
                            <Button @click="onClickDeleteBtn" label="삭제" class="mt-[2rem] border-[1px] h-[3rem] font-NotoSansKR hover:bg-slate-100 text-[1.2rem]"/>
                            <DataTable v-model:selection="selDelInwon" dataKey="INWON_SQ" :value="inwonPopupList" class="col-span-full border-[1px] h-[20rem]" scrollHeight="100%" selectionMode="single" scrollable stripedRows>
                                <Column field="EMP_NO" header="사번" :style="tableRowStyle"></Column>
                                <Column field="USER_NM" header="사용자" :style="tableRowStyle"></Column>
                            </DataTable>
                            <div class="flex flex-col gap-2 col-span-3">
                                    <label class="text-[1.2rem]" for="iname">사원명</label>
                                    <InputText id="iname" class="border-[1px] h-[3rem] text-[1.4rem] px-[0.8rem]" v-model="inwonSearchName"  />
                            </div>
                            <div @click="onClickSearchBtn" class="col-span-2 border-[1px] h-[3rem] font-NotoSansKR hover:bg-slate-100 text-[1.2rem] cursor-pointer rounded-lg flex flex-row items-center justify-center gap-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#111" class="w-[1.6rem] h-[2rem]">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                                <p class="p-0 m-0 text-[1.2rem]" id="popupSeachBtn">검색</p>
                            </div>
                            <Button @click="onClickInsertBtn" label="추가" class="col-start-12 col-span-1 mt-[2rem] border-[1px] h-[3rem] font-NotoSansKR hover:bg-slate-100 text-[1.2rem]"/>
                            <DataTable v-model:selection="selInwonPopup" paginator :rows="3" selectionMode="single" dataKey="INWON_SQ" :value="inwonSearchList" class="col-span-full border-[1px] h-[15rem] bg-white flex flex-col justify-between" tableStyle="min-width: 50rem" scrollHeight="100%" scrollable stripedRows>
                                <Column field="SAWON_BEONHO_NO" header="사번" :style="tableRowStyle"></Column>
                                <Column field="BUSEOMYEONG_NM" header="부서" :style="tableRowStyle"></Column>
                                <Column field="SAWON_MYEONG_NM" header="사원명" :style="tableRowStyle"></Column>
                            </DataTable>
                            <div class="col-span-full flex flex-row justify-end gap-[1rem] mt-[1rem]">
                                <Button @click="()=>{inwonPopupVisible = false}" label="닫기" class="text-[1.2rem] w-[8rem] h-[3rem] bg-[#fff] text-[#03A9F4] border-[#03A9F4] border-[1px] hover:bg-[#e9e8e8]"/>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <DataTable @vnode-before-mount="onInwonBefore" v-model:selection="selInwonRow" rowGroupMode="rowspan" :groupRowsBy="['index','GROUP_CODE_CD','GROUP_NAME_NM']" dataKey="GROUP_INWON_SQ" :value="inwonList" class="groupList border-[1px] h-[calc(100%-4rem)]" scrollHeight="100%" selectionMode="multiple" scrollable>
                    <Column field="GROUP_CODE_CD" header="그룹코드" :style="tableRowStyle"></Column>
                    <Column field="GROUP_NAME_NM" header="그룹명" :style="tableRowStyle"></Column>
                    <Column field="EMP_NO" header="사번" :style="tableRowStyle"></Column>
                    <Column field="USER_NM" header="사원명" :style="tableRowStyle"></Column>
                </DataTable>
            </div>
        </div>
    </div>
    <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMsg"
      v-model:type="toastType"
      :display-time="600"
    >
    <DxPosition my="top" at="top" offset="0 50"/>
    </DxToast>
   </div>
</template>

<script setup>
import TabMenu from 'primevue/tabmenu';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import vm from '../Models/Acount_ViewModel'
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { ref ,watch } from "vue";
import { DxToast, DxPosition } from 'devextreme-vue/toast';
const tableRowStyle = 'padding-left: 0.8rem; border: 1px solid; border-color: #E5E7EB; font-size:1.4rem; height:25px'

//COMMONS
const toastVisible = ref(false);
const toastMsg = ref("");
const toastType = ref("error");

//TAB
const active = ref(0);
const items = ref([
    { label: '프로그램/그룹 목록' },
    { label: '사용자/권한 목록' },
]);

//MENU Props
const menuList =ref([])
const selMenuRow = ref({})
const menuPopupVisible = ref(false)
const expandedMenuRows = ref({})

//Group Props
const groupList =ref([])
const selGroupRow = ref({})
const groupPopupVisible = ref(false)
const expandedGroupRows = ref({})

watch(groupPopupVisible,()=>{
    if(!groupPopupVisible.value)
    {
        selGroupRow.value = {}
    }
})

//Access Props
const accList = ref([])
const selAccRow = ref({})
const accPopupVisible = ref(false)
const expandedAccRows = ref({})

//Group Inwon Props
const inwonList = ref([])
const selInwonRow = ref([])
const inwonPopupVisible = ref(false)
const inwonPopupList = ref([])
const inwonStoreList = ref([])
const inwonSearchList = ref([])
const inwonSearchName = ref('')
const selInwonPopup = ref({});
const selGroupPopup = ref({});
const selDelInwon = ref({})

watch(selGroupPopup,async ()=>{
    inwonPopupList.value = await vm.USP_GROUP_INWON_INFO(selGroupPopup.value.GROUP_SQ);
})

watch(selInwonRow,(n,o)=>{
    if(n[n.length-1]?.GROUP_SQ === o[n.length-1]?.GROUP_SQ)
    {
        if(n.length > 0 && o.length >0)
        {
            if(n.length<o.length)
            {
                selInwonRow.value = [];
            }   
        }
        return;
    }

    let target_sq = 0;
    if(n.length > 0)
    {
        target_sq = n[n.length-1].GROUP_SQ;
    }

    selInwonRow.value = [...inwonList.value.filter(x=>x.GROUP_SQ === target_sq)];
})


//MENU EVENT
const onMenuBefore = async()=>{
    menuList.value = await vm.USP_MENU_LIST();
}

const onRowExpand = (e,list,target,id) => {
    const expandedRowId = e.data[id];
    const data = e.data;
    Object.keys(list).forEach((key)=>{delete list[key]})
    list[expandedRowId] = true;
    for(const key of Object.keys(data))
    {
        target[key] = data[key];
    }
    if(id == 'ACCESS_SQ')
    {
        target['selGroup'] = groupList.value.find(x=>x.GROUP_SQ == data.GROUP_SQ);
        target['selMenu'] = menuList.value.find(x=>x.MENU_SQ == data.MENU_SQ);
    }
};

const onRowCollapse = (e,list,target) => {
    list = {};
    target = {};
};

const onClickMenuUpdate = async ()=>{
    await vm.USP_MENU_UPDATE(selMenuRow.value);
    menuList.value = await vm.USP_MENU_LIST();
    menuPopupVisible.value = false
}

//Group Event
const onGroupBefore = async()=>{
    groupList.value = await vm.USP_GROUP_LIST();
}

const onClickGroupUpdate = async ()=>{
    await vm.USP_GROUP_UPDATE(selGroupRow.value);
    groupList.value = await vm.USP_GROUP_LIST();
    groupPopupVisible.value = false
}

const onClickGroupDelete = async ()=>{
    await vm.USP_GROUP_DELETE(selGroupRow.value.GROUP_SQ)
    groupList.value = await vm.USP_GROUP_LIST();
}

//Access Event
const onAccBefore = async()=>{
    accList.value = await vm.USP_ACCESS_LIST();
    menuList.value = await vm.USP_MENU_LIST();
    groupList.value = await vm.USP_GROUP_LIST();
}

const onClickVisiblePopup = ()=>{
    selAccRow.value.selGroup = {}
    selAccRow.value.selMenu = {}
    accPopupVisible.value = true
}

const onAccUpdate = async()=>{
    await vm.USP_ACCESS_UPDATE(
        selAccRow.value.ACCESS_SQ,
        selAccRow.value.selGroup?.GROUP_SQ,
        selAccRow.value.selMenu?.MENU_SQ);
    accList.value = await vm.USP_ACCESS_LIST();
    accPopupVisible.value = false
}

const onAccDelete = async ()=>{
    await vm.USP_ACCESS_DELETE(selAccRow.value.ACCESS_SQ);
    accList.value = await vm.USP_ACCESS_LIST();
}

//Group Inwon Event
const onInwonBefore = async()=>{
    inwonList.value = await vm.USP_GROUP_INWON_LIST();
    inwonStoreList.value = await vm.USP_INWON_NEW_LIST();
}

const onClickSearchBtn = ()=>{
    inwonSearchList.value = [...inwonStoreList.value.filter(x=>x.SAWON_MYEONG_NM.includes(inwonSearchName.value) || x.SAWON_BEONHO_NO.includes(inwonSearchName.value))]
}

const onClickInsertBtn = async () =>{
    const res = await vm.USP_GROUP_INWON_INSERT(selGroupPopup.value.GROUP_SQ,selInwonPopup.value?.INWON_SQ);
    if(res._result[0].RESULTCD == '1'){
        inwonPopupList.value = await vm.USP_GROUP_INWON_INFO(selGroupPopup.value.GROUP_SQ);
        inwonList.value = await vm.USP_GROUP_INWON_LIST();
        toastVisible.value = true;
        toastMsg.value = '추가 완료'
        toastType.value = 'success'
    }
    else
    {
        toastVisible.value = true;
        toastMsg.value = res._result[0].DETAILMSG
        toastType.value = 'error'
    }
}

const onClickDeleteBtn = async ()=>{
    await vm.USP_GROUP_INWON_DELETE(selGroupPopup.value.GROUP_SQ,selDelInwon.value.INWON_SQ);
    inwonPopupList.value = await vm.USP_GROUP_INWON_INFO(selGroupPopup.value.GROUP_SQ);
    inwonList.value = await vm.USP_GROUP_INWON_LIST();
}

const onClickInwonPopupVisible = async ()=>{
    selGroupPopup.value = selInwonRow.value.length > 0 ? groupList.value.find(x=>x.GROUP_SQ === selInwonRow.value[0].GROUP_SQ) : groupList.value[0];
    inwonPopupVisible.value = true;
}
</script>

<style>
#AcountTab .p-highlight{
    background: #FFFFFF !important;;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem !important;
}
.p-tabmenuitem{
    background: #F8F8F8;
}
.groupList .p-datatable-tbody > tr:not(.p-highlight):hover{
    background : transparent !important;
}
.p-dialog-title {
    font-size: 1.6rem;
}
#acc_m_name_list li span {
    font-size: 1.4rem;
    padding: 0 8px;
}
#acc_m_name > .p-dropdown-label {
    font-size: 1.4rem;
}
#Account_View .p-button-label {
    padding: 0 !important;
}
</style>